
:root {
  --color-primary: #001b50;
  --color-primary-yellow: #f9d14b;
  --color-secondary: #001b50;
  --color-secondary-gray: #7b7b7b;
  --color-secondary-white: #f4f5f9;
  --color-secondary-light-blue: #92aaed;
  --color-secondary2: #173570;
  --color-white: #fff;
  --color-black: #111116;
  --color-title: #001b50;
  --color-flink: #7c8fb4;
  --border-color: #dbdbdb;
  --border-color-secondary: #cfcfcf;
  --color-faq-btn: #a8a8a8;
  --card-margin: 4vw;
  --card-top-offset: 1em;
  --numcards: 4;
  --outline-width: 0px;
  --color-secondary-border: rgba(123, 123, 123, 0.5);
  --color-light-gray: #c9c9c9;
}
@font-face {
  font-family: 'Poppins';
  src: url('../font/Poppins-ExtraLight.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Poppins';
  src: url('../font/Poppins-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;

}
@font-face {
  font-family: 'Poppins';
  src: url('../font/Poppins-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;

}
@font-face {
  font-family: 'Poppins';
  src: url('../font/Poppins-SemiBold.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;

}
@font-face {
  font-family: 'Poppins';
  src: url('../font/Poppins-Bold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
  font-display: swap;

}


body {
  font-family: "Poppins", sans-serif;
  /* font-weight: 400; */
}


.ss-title,
h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin-bottom: 0;
}
a,
a:hover {
  color: unset;
}
.delivering-info h2,
.technology-box-main h3 {
  color: var(--color-secondary);
}
.about_stikcy_position,
.after_add_com_logo,
.blog-slider,
.blog_menegment_section img,
.btn-wrapper,
.close-btn-banner,
.cmp-event-round,
.comman_form,
.comman_tab_description,
.company-event-box,
.creativity-content-main .slick-slider,
.creativity-main,
.creativity-main .slick-slide,
.custom-file,
.cutting-img,
.enpowerment-img,
.faq-accordion,
.faq-accordion .accordion,
.idea-and-enhance,
.main_portfolio_cell,
.main_wrapper_of_blog,
.nurture-creativity-title,
.our-vision-img img,
.privacy-main,
.quality-img,
.requiremnt-img,
.search-box-main,
.shape-container,
.software-solution,
.ss-link.nav-link,
.ss-video,
.ss-vision-img,
.team-bg-img,
.team-img-box,
.technology-next-main,
.testimonials-box-main,
.testimonials-section,
.testimonials-video,
.vision-img-main,
.we-are-img,
.we-are-img img,
.website-development-img,
.website-development-img-main,
.workshopimg-main,
.yearchange-container,
footer {
  position: relative;
}
a,
button.learn-more {
  text-decoration: none;
}
button.learn-more .button-text,
button.learn-more .circle,
button.learn-more .circle .icon,
button.learn-more .circle .icon.arrow {
  transition: 0.45s cubic-bezier(0.65, 0, 0.076, 1);
}
.delivering-info h2 {
  font-size: 18px;
  line-height: 28px;
}
.delivering-info h3,
.enhance-content p,
.technology-box-main h3,
.testimonials-section .light-heading,
h6 {
  line-height: 24px;
  font-size: 16px;
}
.animate-charcter,
.certification-title h5,
.countless-title .our-vision-text h5,
.technology-box-main h3,
.technology-box-main > h6,
.web-build-box2 p {
  text-transform: uppercase;
}
.vision-label1,
.vision-label2 {
  animation: 3s infinite alternate both scale_up_down;
}
.mt-5 {
  margin-top: 5px !important;
}
.mt-10 {
  margin-top: 10px;
}
.mt-15 {
  margin-top: 15px;
}
.mt-20,
.testimonials-box-main .slick-dots {
  margin-top: 20px;
}
.mt-25 {
  margin-top: 25px;
}
.casestudy_portfolio_description .col-lg-4,
.mt-30 {
  margin-top: 30px;
}
.mt-35 {
  margin-top: 35px;
}
.mt-40 {
  margin-top: 40px;
}
.mt-45 {
  margin-top: 45px;
}
#content,
.mt-50 {
  margin-top: 50px;
}
.mt-55 {
  margin-top: 55px;
}
.mt-60 {
  margin-top: 60px;
}
.mt-65 {
  margin-top: 65px;
}
.mt-70 {
  margin-top: 70px;
}
.mt-75 {
  margin-top: 75px;
}
.mt-80 {
  margin-top: 80px;
}
.mt-85 {
  margin-top: 85px;
}
.mt-90 {
  margin-top: 90px;
}
.mt-95 {
  margin-top: 95px;
}
.mt-100 {
  margin-top: 100px;
}
.mt-105 {
  margin-top: 105px;
}
.mt-110 {
  margin-top: 110px;
}
.mt-115 {
  margin-top: 115px;
}
.mt-120 {
  margin-top: 120px;
}
.mt-125 {
  margin-top: 125px;
}
.mt-130 {
  margin-top: 130px;
}
.mt-135 {
  margin-top: 135px;
}
.mt-140 {
  margin-top: 140px;
}
.mt-145 {
  margin-top: 145px;
}
.mt-150 {
  margin-top: 150px;
}
.mt-155 {
  margin-top: 155px;
}
.mt-160 {
  margin-top: 160px;
}
.mt-165 {
  margin-top: 165px;
}
.mt-170 {
  margin-top: 170px;
}
.mt-175 {
  margin-top: 175px;
}
.mt-180 {
  margin-top: 180px;
}
.mt-185 {
  margin-top: 185px;
}
.mt-190 {
  margin-top: 190px;
}
.mt-195 {
  margin-top: 195px;
}
.mt-200 {
  margin-top: 200px;
}
.mb-5 {
  margin-bottom: 5px !important;
}
.blog-text h6,
.mb-10,
.ss_sens_about_comdese p {
  margin-bottom: 10px;
}
.footer-links li,
.mb-15 {
  margin-bottom: 15px;
}
.blog_wrapper_alldet p,
.mb-20,
.specific_blog_page .blog-box-main {
  margin-bottom: 20px;
}
.mb-25,
.pricing-list-main .price-number {
  margin-bottom: 25px;
}
.creativity-content-main .slick-dotted.slick-slider,
.mb-30 {
  margin-bottom: 30px;
}
.mb-35 {
  margin-bottom: 35px;
}
.mb-40 {
  margin-bottom: 40px;
}
.mb-45 {
  margin-bottom: 45px;
}
.mb-50,
.portfolio-slider .owl-carousel .owl-stage-outer {
  margin-bottom: 50px;
}
.mb-55 {
  margin-bottom: 55px;
}
.mb-60 {
  margin-bottom: 60px;
}
.mb-65 {
  margin-bottom: 65px;
}
.mb-70 {
  margin-bottom: 70px;
}
.mb-75 {
  margin-bottom: 75px;
}
.mb-80 {
  margin-bottom: 80px;
}
.mb-85 {
  margin-bottom: 85px;
}
.mb-90 {
  margin-bottom: 90px;
}
.mb-95 {
  margin-bottom: 95px;
}
.mb-100 {
  margin-bottom: 100px;
}
.mb-105 {
  margin-bottom: 105px;
}
.mb-110 {
  margin-bottom: 110px;
}
.mb-115 {
  margin-bottom: 115px;
}
.mb-120 {
  margin-bottom: 120px;
}
.mb-125 {
  margin-bottom: 125px;
}
.mb-130 {
  margin-bottom: 130px;
}
.mb-135 {
  margin-bottom: 135px;
}
.mb-140 {
  margin-bottom: 140px;
}
.mb-145 {
  margin-bottom: 145px;
}
.mb-150 {
  margin-bottom: 150px;
}
.mb-155 {
  margin-bottom: 155px;
}
.mb-160 {
  margin-bottom: 160px;
}
.mb-165 {
  margin-bottom: 165px;
}
.mb-170 {
  margin-bottom: 170px;
}
.mb-175 {
  margin-bottom: 175px;
}
.mb-180 {
  margin-bottom: 180px;
}
.mb-185 {
  margin-bottom: 185px;
}
.mb-190 {
  margin-bottom: 190px;
}
.mb-195 {
  margin-bottom: 195px;
}
.mb-200 {
  margin-bottom: 200px;
}
.ml-5 {
  margin-left: 5px;
}
.ml-10,
.pricing-availability-text h6,
.ss-author-name {
  margin-left: 10px;
}
.pricing-availability-text p {
  font-size: 16px;
  line-height: 22px;
  font-weight: 600;
  margin-left: 10px;
}
.inquires_of_pricing h3 {
  color: #001b50;
  font-size: 18px;
  line-height: 28px;
  font-weight: 500;
}
.pakages_of_price h3,
.pakages_of_price h4 {
  text-transform: capitalize;
  padding-bottom: 6px;
  font-weight: 600;
  color: #001b50;
  font-size: 18px;
  line-height: 28px;
}
.certificate-text,
.core-value-text,
.focused-text,
.ml-15,
.our-vision-text {
  margin-left: 15px;
}
.core-value-text h2,
.form-title h3 {
  color: var(--color-secondary);
  font-size: 24px;
  line-height: 30px;
  font-weight: 500;
}
.ceo-text h4,
.certificate-text h3 {
  color: var(--color-secondary);
  font-size: 16px;
  line-height: 24px;
}
.client-name,
.contact-detail-text,
.ml-20,
button.learn-more.arrow-btn-gray .circle .icon.arrow img {
  margin-left: 20px;
}
.ml-25,
button.learn-more .circle .icon.arrow img {
  margin-left: 25px;
}
.ml-30 {
  margin-left: 30px;
}
.ml-35 {
  margin-left: 35px;
}
.ml-40 {
  margin-left: 40px;
}
.ml-45 {
  margin-left: 45px;
}
.ml-50 {
  margin-left: 50px;
}
.ml-55 {
  margin-left: 55px;
}
.ml-60 {
  margin-left: 60px;
}
.ml-65 {
  margin-left: 65px;
}
.ml-70 {
  margin-left: 70px;
}
.ml-75 {
  margin-left: 75px;
}
.ml-80 {
  margin-left: 80px;
}
.ml-85 {
  margin-left: 85px;
}
.ml-90 {
  margin-left: 90px;
}
.ml-95 {
  margin-left: 95px;
}
.ml-100 {
  margin-left: 100px;
}
.ml-105 {
  margin-left: 105px;
}
.ml-110 {
  margin-left: 110px;
}
.ml-115 {
  margin-left: 115px;
}
.ml-120 {
  margin-left: 120px;
}
.ml-125 {
  margin-left: 125px;
}
.ml-130 {
  margin-left: 130px;
}
.ml-135 {
  margin-left: 135px;
}
.ml-140 {
  margin-left: 140px;
}
.ml-145 {
  margin-left: 145px;
}
.ml-150 {
  margin-left: 150px;
}
.ml-155 {
  margin-left: 155px;
}
.ml-160 {
  margin-left: 160px;
}
.ml-165 {
  margin-left: 165px;
}
.ml-170 {
  margin-left: 170px;
}
.ml-175 {
  margin-left: 175px;
}
.ml-180 {
  margin-left: 180px;
}
.ml-185 {
  margin-left: 185px;
}
.ml-190 {
  margin-left: 190px;
}
.ml-195 {
  margin-left: 195px;
}
.ml-200 {
  margin-left: 200px;
}
.blog_social_icon .social_apply_icon a i,
.mr-5 {
  margin-right: 5px;
}
.job_description_com .tab-content > .tab-pane,
.mr-10,
.total_blog_of_section {
  margin-right: 10px;
}
.mr-15,
.social li i {
  margin-right: 15px;
}
.mr-20 {
  margin-right: 20px;
}
.mr-25 {
  margin-right: 25px;
}
.mr-30 {
  margin-right: 30px;
}
.mr-35 {
  margin-right: 35px;
}
.mr-40 {
  margin-right: 40px;
}
.mr-45 {
  margin-right: 45px;
}
.mr-50 {
  margin-right: 50px;
}
.mr-55 {
  margin-right: 55px;
}
.mr-60 {
  margin-right: 60px;
}
.mr-65 {
  margin-right: 65px;
}
.mr-70 {
  margin-right: 70px;
}
.mr-75 {
  margin-right: 75px;
}
.mr-80 {
  margin-right: 80px;
}
.mr-85 {
  margin-right: 85px;
}
.mr-90 {
  margin-right: 90px;
}
.mr-95 {
  margin-right: 95px;
}
.mr-100 {
  margin-right: 100px;
}
.mr-105 {
  margin-right: 105px;
}
.mr-110 {
  margin-right: 110px;
}
.mr-115 {
  margin-right: 115px;
}
.mr-120 {
  margin-right: 120px;
}
.mr-125 {
  margin-right: 125px;
}
.mr-130 {
  margin-right: 130px;
}
.mr-135 {
  margin-right: 135px;
}
.mr-140 {
  margin-right: 140px;
}
.mr-145 {
  margin-right: 145px;
}
.mr-150 {
  margin-right: 150px;
}
.mr-155 {
  margin-right: 155px;
}
.mr-160 {
  margin-right: 160px;
}
.mr-165 {
  margin-right: 165px;
}
.mr-170 {
  margin-right: 170px;
}
.mr-175 {
  margin-right: 175px;
}
.mr-180 {
  margin-right: 180px;
}
.mr-185 {
  margin-right: 185px;
}
.mr-190 {
  margin-right: 190px;
}
.mr-195 {
  margin-right: 195px;
}
.mr-200 {
  margin-right: 200px;
}
.pt-5 {
  padding-top: 5px !important;
}
.fridayevent2-slider,
.pricing-list-detail,
.pt-10 {
  padding-top: 10px;
}
.pt-15 {
  padding-top: 15px;
}
.pt-20 {
  padding-top: 20px;
}
.pt-25 {
  padding-top: 25px;
}
.pt-30 {
  padding-top: 30px;
}
.pt-35 {
  padding-top: 35px;
}
.pt-40 {
  padding-top: 40px;
}
.pt-45 {
  padding-top: 45px;
}
.pt-50 {
  padding-top: 50px;
}
.pt-55 {
  padding-top: 55px;
}
.pt-60 {
  padding-top: 60px;
}
.pt-65 {
  padding-top: 65px;
}
.pt-70 {
  padding-top: 70px;
}
.pt-75 {
  padding-top: 75px;
}
.pt-80 {
  padding-top: 80px;
}
.pt-85 {
  padding-top: 85px;
}
.header-90,
.pt-90 {
  padding-top: 90px;
}
.pt-95 {
  padding-top: 95px;
}
.pt-100 {
  padding-top: 100px;
}
.pt-105 {
  padding-top: 105px;
}
.pt-110 {
  padding-top: 110px;
}
.pt-115 {
  padding-top: 115px;
}
.pt-120 {
  padding-top: 120px;
}
.pt-125 {
  padding-top: 125px;
}
.pt-130 {
  padding-top: 130px;
}
.pt-135 {
  padding-top: 135px;
}
.pt-140 {
  padding-top: 140px;
}
.pt-145 {
  padding-top: 145px;
}
.pt-150 {
  padding-top: 150px;
}
.pt-155 {
  padding-top: 155px;
}
.pt-160 {
  padding-top: 160px;
}
.pt-165 {
  padding-top: 165px;
}
.pt-170 {
  padding-top: 170px;
}
.pt-175 {
  padding-top: 175px;
}
.pt-180 {
  padding-top: 180px;
}
.pt-185 {
  padding-top: 185px;
}
.pt-190 {
  padding-top: 190px;
}
.pt-195 {
  padding-top: 195px;
}
.pt-200 {
  padding-top: 200px;
}
.pb-5 {
  padding-bottom: 5px !important;
}
.innnovation-section-main .sans-culture-mem img,
.pb-10,
.timeline ul li:last-child {
  padding-bottom: 10px;
}
.pb-15 {
  padding-bottom: 15px;
}
.pb-20 {
  padding-bottom: 20px;
}
.pb-25 {
  padding-bottom: 25px;
}
.pb-30 {
  padding-bottom: 30px;
}
.pb-35 {
  padding-bottom: 35px;
}
.pb-40 {
  padding-bottom: 40px;
}
.pb-45 {
  padding-bottom: 45px;
}
.pb-50 {
  padding-bottom: 50px;
}
.pb-55 {
  padding-bottom: 55px;
}
.pb-60 {
  padding-bottom: 60px;
}
.pb-65 {
  padding-bottom: 65px;
}
.pb-70 {
  padding-bottom: 70px;
}
.pb-75 {
  padding-bottom: 75px;
}
.pb-80 {
  padding-bottom: 80px;
}
.pb-85 {
  padding-bottom: 85px;
}
.pb-90 {
  padding-bottom: 90px;
}
.pb-95 {
  padding-bottom: 95px;
}
.build-box-content,
.pb-100 {
  padding-bottom: 100px;
}
.pb-105 {
  padding-bottom: 105px;
}
.pb-110 {
  padding-bottom: 110px;
}
.pb-115 {
  padding-bottom: 115px;
}
.pb-120 {
  padding-bottom: 120px;
}
.pb-125 {
  padding-bottom: 125px;
}
.pb-130 {
  padding-bottom: 130px;
}
.pb-135 {
  padding-bottom: 135px;
}
.pb-140 {
  padding-bottom: 140px;
}
.pb-145 {
  padding-bottom: 145px;
}
.pb-150 {
  padding-bottom: 150px;
}
.pb-155 {
  padding-bottom: 155px;
}
.pb-160 {
  padding-bottom: 160px;
}
.pb-165 {
  padding-bottom: 165px;
}
.pb-170 {
  padding-bottom: 170px;
}
.pb-175 {
  padding-bottom: 175px;
}
.pb-180 {
  padding-bottom: 180px;
}
.pb-185 {
  padding-bottom: 185px;
}
.pb-190 {
  padding-bottom: 190px;
}
.pb-195 {
  padding-bottom: 195px;
}
.pb-200 {
  padding-bottom: 200px;
}
.pl-5 {
  padding-left: 5px;
}
.pl-10 {
  padding-left: 10px;
}
.pl-15 {
  padding-left: 15px;
}
.pl-20 {
  padding-left: 20px;
}
.pl-25 {
  padding-left: 25px;
}
.pl-30 {
  padding-left: 30px;
}
.pl-35 {
  padding-left: 35px;
}
.pl-40 {
  padding-left: 40px;
}
.pl-45 {
  padding-left: 45px;
}
.pl-50 {
  padding-left: 50px;
}
.pl-55 {
  padding-left: 55px;
}
.pl-60 {
  padding-left: 60px;
}
.pl-65 {
  padding-left: 65px;
}
.pl-70 {
  padding-left: 70px;
}
.pl-75 {
  padding-left: 75px;
}
.pl-80 {
  padding-left: 80px;
}
.pl-85 {
  padding-left: 85px;
}
.pl-90 {
  padding-left: 90px;
}
.pl-95 {
  padding-left: 95px;
}
.pl-100 {
  padding-left: 100px;
}
.pl-105 {
  padding-left: 105px;
}
.pl-110 {
  padding-left: 110px;
}
.pl-115 {
  padding-left: 115px;
}
.pl-120 {
  padding-left: 120px;
}
.pl-125 {
  padding-left: 125px;
}
.pl-130 {
  padding-left: 130px;
}
.pl-135 {
  padding-left: 135px;
}
.pl-140 {
  padding-left: 140px;
}
.pl-145 {
  padding-left: 145px;
}
.pl-150 {
  padding-left: 150px;
}
.pl-155 {
  padding-left: 155px;
}
.pl-160 {
  padding-left: 160px;
}
.pl-165 {
  padding-left: 165px;
}
.pl-170 {
  padding-left: 170px;
}
.pl-175 {
  padding-left: 175px;
}
.pl-180 {
  padding-left: 180px;
}
.pl-185 {
  padding-left: 185px;
}
.pl-190 {
  padding-left: 190px;
}
.pl-195 {
  padding-left: 195px;
}
.pl-200 {
  padding-left: 200px;
}
.pr-5 {
  padding-right: 5px;
}
.blog_wrapper_alldet ul li img,
.pr-10,
.technology-border {
  padding-right: 10px;
}
.pr-15 {
  padding-right: 15px;
}
.pr-20 {
  padding-right: 20px;
}
.pr-25 {
  padding-right: 25px;
}
.pr-30 {
  padding-right: 30px;
}
.pr-35 {
  padding-right: 35px;
}
.pr-40 {
  padding-right: 40px;
}
.pr-45 {
  padding-right: 45px;
}
.pr-50 {
  padding-right: 50px;
}
.pr-55 {
  padding-right: 55px;
}
.pr-60 {
  padding-right: 60px;
}
.pr-65 {
  padding-right: 65px;
}
.pr-70 {
  padding-right: 70px;
}
.pr-75 {
  padding-right: 75px;
}
.pr-80 {
  padding-right: 80px;
}
.pr-85 {
  padding-right: 85px;
}
.pr-90 {
  padding-right: 90px;
}
.pr-95 {
  padding-right: 95px;
}
.pr-100 {
  padding-right: 100px;
}
.pr-105 {
  padding-right: 105px;
}
.pr-110 {
  padding-right: 110px;
}
.pr-115 {
  padding-right: 115px;
}
.pr-120 {
  padding-right: 120px;
}
.pr-125 {
  padding-right: 125px;
}
.pr-130 {
  padding-right: 130px;
}
.pr-135 {
  padding-right: 135px;
}
.pr-140 {
  padding-right: 140px;
}
.pr-145 {
  padding-right: 145px;
}
.pr-150 {
  padding-right: 150px;
}
.pr-155 {
  padding-right: 155px;
}
.pr-160 {
  padding-right: 160px;
}
.pr-165 {
  padding-right: 165px;
}
.pr-170 {
  padding-right: 170px;
}
.pr-175 {
  padding-right: 175px;
}
.pr-180 {
  padding-right: 180px;
}
.pr-185 {
  padding-right: 185px;
}
.pr-190 {
  padding-right: 190px;
}
.pr-195 {
  padding-right: 195px;
}
.pr-200 {
  padding-right: 200px;
}
.ptb-10 {
  padding-bottom: 10px;
  padding-top: 10px;
}
.ptb-15 {
  padding-bottom: 15px;
  padding-top: 15px;
}
.ptb-20 {
  padding-bottom: 20px;
  padding-top: 20px;
}
.ptb-30 {
  padding-bottom: 30px;
  padding-top: 30px;
}
.ptb-40 {
  padding-bottom: 40px;
  padding-top: 40px;
}
.ptb-50 {
  padding-bottom: 50px;
  padding-top: 50px;
}
.ptb-60 {
  padding-bottom: 60px;
  padding-top: 60px;
}
.ptb-70 {
  padding-bottom: 70px;
  padding-top: 70px;
}
.ptb-80 {
  padding-bottom: 80px;
  padding-top: 80px;
}
.ptb-90 {
  padding-bottom: 90px;
  padding-top: 90px;
}
.ptb-100 {
  padding-bottom: 100px;
  padding-top: 100px;
}
.ptb-120 {
  padding-bottom: 120px;
  padding-top: 120px;
}
.ptb-150 {
  padding-bottom: 150px;
  padding-top: 150px;
}
.mtb-10 {
  margin-bottom: 10px;
  margin-top: 10px;
}
.mtb-20 {
  margin-bottom: 20px;
  margin-top: 20px;
}
.mtb-30 {
  margin-bottom: 30px;
  margin-top: 30px;
}
.mtb-40 {
  margin-bottom: 40px;
  margin-top: 40px;
}
.mtb-50 {
  margin-bottom: 50px;
  margin-top: 50px;
}
.mtb-60 {
  margin-bottom: 60px;
  margin-top: 60px;
}
.mtb-70 {
  margin-bottom: 70px;
  margin-top: 70px;
}
.mtb-80 {
  margin-bottom: 80px;
  margin-top: 80px;
}
.mtb-90 {
  margin-bottom: 90px;
  margin-top: 90px;
}
.mtb-100 {
  margin-bottom: 100px;
  margin-top: 100px;
}
.mtb-120 {
  margin-bottom: 120px;
  margin-top: 120px;
}
@media (min-width: 320px) and (max-width: 480px) {
  .pt-10 {
    padding-top: 10px;
  }
  .pt-20,
  .pt-40 {
    padding-top: 20px;
  }
  .pt-30 {
    padding-top: 15px;
  }
  .pt-50 {
    padding-top: 25px;
  }
  .pt-60 {
    padding-top: 30px;
  }
  .pt-70 {
    padding-top: 35px;
  }
  .pt-80 {
    padding-top: 40px;
  }
  .pt-90 {
    padding-top: 45px;
  }
  .pt-100,
  .pt-120 {
    padding-top: 50px;
  }
  .pb-10 {
    padding-bottom: 10px;
  }
  .pb-20,
  .pb-40 {
    padding-bottom: 20px;
  }
  .pb-30 {
    padding-bottom: 15px;
  }
  .pb-50 {
    padding-bottom: 25px;
  }
  .pb-60 {
    padding-bottom: 30px;
  }
  .pb-70 {
    padding-bottom: 35px;
  }
  .pb-80 {
    padding-bottom: 40px;
  }
  .pb-90 {
    padding-bottom: 45px;
  }
  .pb-100,
  .pb-120 {
    padding-bottom: 50px;
  }
  .ptb-10 {
    padding-bottom: 10px;
    padding-top: 10px;
  }
  .ptb-20,
  .ptb-40 {
    padding-bottom: 20px;
    padding-top: 20px;
  }
  .ptb-30 {
    padding-bottom: 15px;
    padding-top: 15px;
  }
  .ptb-50 {
    padding-bottom: 25px;
    padding-top: 25px;
  }
  .ptb-60 {
    padding-bottom: 30px;
    padding-top: 30px;
  }
  .ptb-70 {
    padding-bottom: 35px;
    padding-top: 35px;
  }
  .ptb-80 {
    padding-bottom: 40px;
    padding-top: 40px;
  }
  .ptb-90 {
    padding-bottom: 45px;
    padding-top: 45px;
  }
  .ptb-100,
  .ptb-120 {
    padding-bottom: 50px;
    padding-top: 50px;
  }
  .ptb-150 {
    padding-bottom: 60px;
    padding-top: 60px;
  }
  .mt-10 {
    margin-top: 10px;
  }
  .mt-20,
  .mt-40 {
    margin-top: 20px;
  }
  .mt-30,
  .mt-sm-15 {
    margin-top: 15px;
  }
  .mt-50 {
    margin-top: 25px;
  }
  .mt-60 {
    margin-top: 30px;
  }
  .mt-70 {
    margin-top: 35px;
  }
  .mt-80 {
    margin-top: 40px;
  }
  .mt-90 {
    margin-top: 45px;
  }
  .mt-100,
  .mt-120 {
    margin-top: 50px;
  }
  .mb-10 {
    margin-bottom: 10px;
  }
  .mb-20,
  .mb-40,
  .mb-md-20 {
    margin-bottom: 20px;
  }
  .mb-30 {
    margin-bottom: 15px;
  }
  .mb-50 {
    margin-bottom: 25px;
  }
  .mb-60 {
    margin-bottom: 30px;
  }
  .mb-70 {
    margin-bottom: 35px;
  }
  .mb-80 {
    margin-bottom: 40px;
  }
  .mb-90 {
    margin-bottom: 45px;
  }
  .mb-100,
  .mb-120 {
    margin-bottom: 50px;
  }
  .mtb-10 {
    margin-bottom: 10px;
    margin-top: 10px;
  }
  .mtb-20,
  .mtb-40 {
    margin-bottom: 20px;
    margin-top: 20px;
  }
  .mtb-30 {
    margin-bottom: 15px;
    margin-top: 15px;
  }
  .mtb-50 {
    margin-bottom: 25px;
    margin-top: 25px;
  }
  .mtb-60 {
    margin-bottom: 30px;
    margin-top: 30px;
  }
  .mtb-70 {
    margin-bottom: 35px;
    margin-top: 35px;
  }
  .mtb-80 {
    margin-bottom: 40px;
    margin-top: 40px;
  }
  .mtb-90 {
    margin-bottom: 45px;
    margin-top: 45px;
  }
  .mtb-100,
  .mtb-120 {
    margin-bottom: 50px;
    margin-top: 50px;
  }
  .header-90 {
    padding-top: 68px;
  }
  .plr-50 {
    padding: 0 20px;
  }
}
@media (min-width: 481px) and (max-width: 768px) {
  .pt-10 {
    padding-top: 10px;
  }
  .pt-15 {
    padding-top: 15px;
  }
  .pt-20 {
    padding-top: 20px;
  }
  .pt-30,
  .pt-40 {
    padding-top: 30px;
  }
  .pt-50 {
    padding-top: 40px;
  }
  .pt-60 {
    padding-top: 50px;
  }
  .pt-70 {
    padding-top: 60px;
  }
  .pt-80 {
    padding-top: 65px;
  }
  .pt-90 {
    padding-top: 70px;
  }
  .pt-100 {
    padding-top: 75px;
  }
  .pt-120 {
    padding-top: 80px;
  }
  .pb-10 {
    padding-bottom: 10px;
  }
  .pb-15 {
    padding-bottom: 15px;
  }
  .pb-20 {
    padding-bottom: 20px;
  }
  .pb-30,
  .pb-40 {
    padding-bottom: 30px;
  }
  .pb-50 {
    padding-bottom: 40px;
  }
  .pb-60 {
    padding-bottom: 50px;
  }
  .pb-70 {
    padding-bottom: 60px;
  }
  .pb-80 {
    padding-bottom: 65px;
  }
  .pb-90 {
    padding-bottom: 70px;
  }
  .pb-100 {
    padding-bottom: 75px;
  }
  .pb-120 {
    padding-bottom: 80px;
  }
  .ptb-10 {
    padding-bottom: 10px;
    padding-top: 10px;
  }
  .ptb-15 {
    padding-bottom: 15px;
    padding-top: 15px;
  }
  .ptb-20 {
    padding-bottom: 20px;
    padding-top: 20px;
  }
  .ptb-30,
  .ptb-40 {
    padding-bottom: 30px;
    padding-top: 30px;
  }
  .ptb-50 {
    padding-bottom: 40px;
    padding-top: 40px;
  }
  .ptb-60 {
    padding-bottom: 50px;
    padding-top: 50px;
  }
  .ptb-70 {
    padding-bottom: 60px;
    padding-top: 60px;
  }
  .ptb-80 {
    padding-bottom: 65px;
    padding-top: 65px;
  }
  .ptb-90 {
    padding-bottom: 70px;
    padding-top: 70px;
  }
  .ptb-100 {
    padding-bottom: 75px;
    padding-top: 75px;
  }
  .ptb-120 {
    padding-bottom: 80px;
    padding-top: 80px;
  }
  .mt-10 {
    margin-top: 10px;
  }
  .mt-20 {
    margin-top: 20px;
  }
  .mt-30,
  .mt-40 {
    margin-top: 30px;
  }
  .mt-50 {
    margin-top: 40px;
  }
  .mt-60 {
    margin-top: 50px;
  }
  .mt-70 {
    margin-top: 60px;
  }
  .mt-80 {
    margin-top: 65px;
  }
  .mt-90 {
    margin-top: 70px;
  }
  .mt-100 {
    margin-top: 75px;
  }
  .mt-120 {
    margin-top: 80px;
  }
  .mb-10 {
    margin-bottom: 10px;
  }
  .mb-20 {
    margin-bottom: 20px;
  }
  .mb-30,
  .mb-40 {
    margin-bottom: 30px;
  }
  .mb-50 {
    margin-bottom: 40px;
  }
  .mb-60 {
    margin-bottom: 50px;
  }
  .mb-70 {
    margin-bottom: 60px;
  }
  .mb-80 {
    margin-bottom: 65px;
  }
  .mb-90 {
    margin-bottom: 70px;
  }
  .mb-100 {
    margin-bottom: 75px;
  }
  .mb-120 {
    margin-bottom: 80px;
  }
  .mtb-10 {
    margin-bottom: 10px;
    margin-top: 10px;
  }
  .mtb-20 {
    margin-bottom: 20px;
    margin-top: 20px;
  }
  .mtb-30,
  .mtb-40 {
    margin-bottom: 30px;
    margin-top: 30px;
  }
  .mtb-50 {
    margin-bottom: 40px;
    margin-top: 40px;
  }
  .mtb-60 {
    margin-bottom: 50px;
    margin-top: 50px;
  }
  .mtb-70 {
    margin-bottom: 60px;
    margin-top: 60px;
  }
  .mtb-80 {
    margin-bottom: 65px;
    margin-top: 65px;
  }
  .mtb-90 {
    margin-bottom: 70px;
    margin-top: 70px;
  }
  .mtb-100 {
    margin-bottom: 75px;
    margin-top: 75px;
  }
  .mtb-120 {
    margin-bottom: 80px;
    margin-top: 80px;
  }
  .header-90 {
    padding-top: 90px;
  }
  .pb-200 {
    padding-bottom: 200px;
  }
}
.pb-250 {
  padding-bottom: 250px;
}

h1,
h2 {
  font-size: 48px;
  line-height: 65px;
  font-weight: 500;
}
.footer-links .hiring span,.ss-title{
  font-weight: 500;
}
.btn-wrapper,
.joinee-position p span,

.sm-text-semibold,

h3,
h6,
p {
  font-weight: 600;
}
.cuture-heading.ss-heading h2,
.ss-title {
  font-size: 35px;
  line-height: 50px;
}
.cookie-info p,
.glimpses-box .maintanance-box .maintance-text h5,
.innvoation_ideas_about .accordion-header h4 span,

.light-heading,
.our-prrocess-blog .custom-carousel .item h4,
.ss-heading h1 span,
.ss-heading h2 span,.our-vision-heading h2 span,
.text-regular {
  font-weight: 400;
}
.lg-text-light{
  font-weight: 400;
}
.blog_wrapper_alldet p strong,
.service-portfolio-text h3,
.sucessfully-content p,
h4,
h5 {
  font-weight: 400;
}
h3 {
  font-size: 30px;
  line-height: 40px;
}
h4 {
  font-size: 24px;
  line-height: 30px;
}
h5 {
  font-size: 18px;
  line-height: 28px;
}
.content_job_style p,
.custom-carousel .item-desc p,
p {
  font-size: 14px;
  line-height: 22px;
}
.sm-text-semibold {
  font-size: 12px;
  line-height: 18px;
}
.btn-wrapper,
button.learn-more .button-text {
  line-height: 40px;
  text-transform: uppercase;
  text-align: center;
}
.blog_sub_mainhead,
.build-box-main:hover,
.fixed-header,
.main_panal_component,
.ss-bg-primary,
.team-bg-img .web-build-box.team-box2 .like-round,
.team-member-hire .team-member-img,
button.scrollToUp,
input[type="checkbox"].toggle:checked + label,
input[type="checkbox"].toggle:focus:checked + label {
  background-color: var(--color-secondary);
}
.countless-talents, .website-development,
.elevating-software{
  background-color: var(--color-secondary);
}
.btn-wrapper:hover,
.footer-links .hiring span {
  background-color: var(--color-primary-yellow);
  color: var(--color-secondary);
}
.btn-wrapper.btn-wrapper-blue img {
  margin-right: 17px;
  margin-top: -2px;
}
.btn-wrapper {
  transition: 0.2s ease-in;
  display: inline-block;
  height: 40px;
  border: 1.3px solid var(--color-white);
  padding: 0 7px;
  min-width: 150px;
  background: 0 0;
  border-radius: 49px;
  font-size: 14px;
  color: #fff;
}
.blog .owl-carousel.owl-drag .owl-item,
.creativity-main .slick-track,
.main_class1,
.ss_sens_culture_main,
.tecnology_plus_slider .owl-carousel.owl-drag .owl-item,
.testimonials-box-main .owl-carousel .owl-stage {
  display: flex;
}
.btn-wrapper-medium {
  min-width: 70px;
}
.btn-wrapper:hover {
  border: 1.3px solid transparent;
  transition: 0.5s;
}
.award-section,
.delivering-box.border::before,
.footer-main,
.main-functinality-main,
.proof-of-number,
.send-btn,
.send-btn:focus,
.send-btn:hover {
  background-color: var(--color-secondary);
}
.btn-wrapper-blue {
  border: 1.3px solid var(--color-secondary);
  color: var(--color-secondary);
}
button.learn-more.btn-large {
  width: 300px !important;
}
button.learn-more.btn-large .button-text,
button.learn-more.btn-medium .button-text {
  margin-left: 50px !important;
}
button.learn-more.btn-medium {
  width: 215px !important;
}
.ptb-200 {
  padding: 200px 0;
}
.footer-border,
.footer-contact {
  border: 1px solid var(--color-secondary2);
}
.footer-links-main h5 {
  color: var(--color-secondary-white);
  margin-bottom: 15px;
}
.footer-links {
  margin: 0;
  list-style-type: none;
  padding: 0;
}
.contact-text h3,
.contact-text h6,
.copyright-text,
.phn-img {
  padding: 10px 18px;
}
.contact-text h3 {
  color: var(--color-white);
  font-size: 16px;
  line-height: 24px;
}
.footer-links a,
.footer-text p {
  color: var(--color-flink);
  font-size: 16px;
  line-height: 24px;
}
.portfolio-text h2,
.service-portfolio-text h3,
.workshops-point-box h4 {
  color: var(--color-white);
  font-size: 18px;
  line-height: 28px;
}
.footer-links .hiring span {
  border-radius: 100px;
  padding: 0 10px;
  margin-left: 8px;
}
.footer-custom.container-fluid {
  padding: 0 40px;
}
.Blog_heading_section .ss-heading.proof-text h1,
.blog-text h5,
.blog-text h6,
.build-box-content h3,
.build-box-content p,
.careere-main-images:hover p,
.contact-detail-text h5,
.contact-text h6,
.contact-us-detail p,
.countless-title .our-vision-text h5,
.culture-main-page .ss-heading h1,
.cutting-text .ss-heading h1,
.details_of_software,
.functionality-content-main h6,
.learningbox-main .learning-text h4,
.learningbox-main .learning-text p,
.location-text h6,
.navbar-nav .nav-link.active,
.navbar-nav .show > .nav-link,
.portfolio-content h4,
.portfolio-hover-box h5,
.portfolio-text h5,
.portfolio-text h6,
.portfolio-text p,
.seachbox.form-control,
.service-portfolio-text h5,
.service-portfolio-text p,
.ss-heading.proof-text h2,
.ss-mission-img .focused-text h5,
.ss-mission-img .focused-text p,
.vision-label3.vision-box h5,
.vision-label3.vision-box p,
.website-development-text .ss-heading h1,
.workshops-point-box h5,
button.learn-more:hover .button-text {
  color: var(--color-white);
}
.portfolio-text h2 {
  color: #fff !important;
  font-weight: 500;
}
.portfolio-text h3 {
  color: #fff !important;
  font-size: 13px !important;
  font-weight: 400;
}
.desciption_of_compnaygoal h2 {
  font-size: 30px;
  line-height: 40px;
  color: #001b50;
  font-weight: 400;
  margin-bottom: 10px;
}
.learning-text h2,
.our-vision-text h2,
.portfolio-content h2 {
  color: var(--color-white);
  font-size: 24px;
  line-height: 30px;
}
.copyright-text h5,
.copyright-text p,
.footer-text h6 {
  color: var(--color-flink);
}
.contact-line {
  border-left: 1px solid var(--color-secondary2);
}
.copyright-text p {
  font-size: 18px;
  line-height: 28px;
  font-weight: 400;
}
.seachbox.form-control {
  border-radius: 25px;
  height: 53px;
  background-color: var(--color-secondary2);
  font-weight: 400;
  font-size: 14px;
  padding: 16px 15px 16px 29px;
  border: none;
}
.form-control:focus {
  border: none;
  box-shadow: none;
}
::placeholder {
  color: var(--color-white) !important;
}
.seachbox::placeholder {
  color: var(--color-white) !important;
}
.send-btn-main {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translate(-20%, -50%);
}
.send-btn {
  width: 39px;
  height: 39px;
  border: none;
  border-radius: 100%;
}
.send-btn:active {
  background-color: var(--color-secondary) !important;
}
.footer-social .footer-contact {
  border-top: unset !important;
}
.location-text p {
  color: var(--color-white);
  font-size: 14px;
  line-height: 24px;
}
.footer-links-main h2 {
  font-size: 18px;
  line-height: 28px;
  color: var(--color-secondary-white);
  margin-bottom: 15px;
}
.build-text p,
.ceo-text h6,
.ceo-text p,
.certificate-text h6,
.certificate-text p,
.client-name h5,
.commna_font_style h4,
.contact-us-title p,
.core-value-text,
.core-value-text h4,
.delivering-info h5,
.dream-project-form .form-title h4,
.dream-project-form .form-title h6,
.dream-project-text h2,
.dream-project-text p,
.enhance-content h3,
.enhance-content h6,
.enhance-content p,
.experience p,
.form-title p,
.heading_of_casestudy .ss-heading.proof-text h2,
.look-forward-box h5,
.modal-heading p,
.newsletter-imgtext h5,
.our-vision-heading h2,
.our-vision-heading p,
.our-vision-text h5,
.our_mainbanar_of_part .custom-carousel .item:last-child h4,
.quality-title h6,
.right-text h6,
.right-text p,
.role_of_software p.sm-text-semibold span,
.solution-title p,
.ss-blog-author,
.ss-blog-box-title > p,
.ss-mission-img .vision-label2 p,
.ss-portfolio-text p,
.ss-process-content p,
.story-box p,
.technology-box-main > h3,
.vision-box h5,
.vision-box p {
  color: var(--color-secondary);
}

.phn-img-main {
  padding: 25px;
}
.social-link .phn-img {
  height: 24px;
  width: 24px;
  padding: 0;
  text-align: center;
}
.company-event-tab .sans-culture-mem img,
.footer-copyright .phn-img img,
.functionality-images img,
.journey-bg img,
.location-img img,
.social-link .phn-img img,
.social-profiles .profiles-link img,
.ss-phn-img img,
.technology-img img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}
.footer-bg-parent {
  filter: drop-shadow(0px 7px 4px rgba(0, 0, 0, 0.1));
}
.my-element-to-clip {
  background-color: #003adc;
  clip-path: url(#myCurve);
  border-radius: 20px;
  padding: 32px 32px 50px;
}
.location-img {
  height: auto;
  width: 24px;
}
.location-text {
  margin-left: 12px;
}
.ss-phn-img {
  height: 24px;
  width: 24px;
}
.contact-info-box {
  margin-bottom: -140px;
}
.footer-location-border {
  border-right: 1px solid var(--color-secondary-light-blue);
  height: 100%;
}
.footer-copyright .phn-img-main {
  padding: 10px 10px;
  height: 100%;
  background: #001B50;
}
.footer-copyright .phn-img {
  height: 50px;
  width: 36px;
  padding: 0;
}
.phn-img-main:hover {
  background-color: var(--color-secondary2);
  transition: 0.5s;
}
.phn-img-main:hover .phn-img a img,
.technology-circle-main:hover .ss-technology-img img {
  filter: brightness(0) invert(1);
}
.ai-technology-img img {
  width: 40px;
}
.ss-heading h1,
.ss-heading h2 {
  color: var(--color-secondary);
  position: relative;
  display: inline-block;
  font-weight: 400;
}
.ss-heading .bg-hover h2::before {
  content: "";
  inset: 0;
  height: 100%;
  width: 60%;
  position: absolute;
  background: linear-gradient(270deg, #001b50 30%, rgba(0, 58, 220, 0) 100%);
  animation: 2s roll-out;
  z-index: -1;
  left: 0;
  padding: 12px;
  border-radius: 0 50px 50px 0;
  opacity: 0;
}
@keyframes roll-out {
  0% {
    opacity: 0.4;
    transform: translateX(0) rotate(0);
  }
  100% {
    opacity: 0;
    transform: translateX(80%) rotate(0);
  }
}
.delivering-box.border {
  border: 1px solid var(--border-color) !important;
  position: relative;
}
.delivering-section .col-lg-4:first-child .delivering-box.border,
.delivering-section .col-lg-4:nth-child(2) .delivering-box.border {
  border-right: 0px !important;
  border-bottom: 0 !important;
}
.delivering-section .col-lg-4:nth-child(3) .delivering-box.border,
.functionalities-main.ss_benififts_section
  .col-lg-4:nth-of-type(n + 4):nth-of-type(-n + 6) {
  border-bottom: 0 !important;
}
.delivering-section .col-lg-4:nth-child(4) .delivering-box.border,
.delivering-section .col-lg-4:nth-child(5) .delivering-box.border {
  border-right: 0px !important;
}
.delivering-box.border::before {
  position: absolute;
  top: 50%;
  right: -1px;
  width: 2px;
  height: 24px;
  content: "";
  transform: translate(-50%, -50%);
  opacity: 0;
}
.delivering-box {
  background: 0 0;
  transition: 0.3s ease-in;
  height: 100%;
}
.delivering-box:hover {
  background: linear-gradient(
    180deg,
    rgba(0, 58, 220, 0) 0,
    rgba(0, 58, 220, 0.1) 75.47%
  );
  transition: 0.3s ease-in;
}
.delivering-box:hover.border::before {
  opacity: 1;
}
.delivering-box:hover .delivering-ic img {
  transform: scaleX(-1);
}
.delivering-ic img {
  transition: transform 0.5s;
}
.right-text p {
  font-size: 16px;
  line-height: 24px;
}
.quality-box h3,
.quality-title p {
  font-size: 16px;
  line-height: 24px;
  color: #5a7184;
}
.client-name h2,
.story-box h2 {
  color: var(--color-secondary);
  font-size: 18px;
  line-height: 28px;
}
.story-box h2 {
  font-weight: 500;
}
.main_class1 {
  color: #fff;
  margin: 0;
  padding: 0;
  list-style-type: none;
  width: 100%;
  justify-content: space-between;
}
.main_class1 li h2 {
  font-size: 170px;
  -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color: #012383;
  font-family: Aldrich, sans-serif;
  color: #001b50;
  line-height: 125px;
  margin-bottom: 0;
  font-weight: 500;
}
.attractive-text h3,
.experince-text h6,
.main-functionality-content li h6,
.maintanance-hover h4,
.maintanance-hover h5,
.maintanance-hover h6,
.portfolio-hover-box p,
.proof-box p,
.proof-text p,
.ss-link.shedule-text,
.ss-link:active,
.web-build-box2 p {
  color: var(--color-secondary-white);
}
.character-effects {
  display: flex;
  align-items: flex-end;
  width: 100%;
  background-color: #001b50;
  position: relative;
  overflow: hidden;
  margin-bottom: 0 !important;
}
.character-effects .letter-effect ul li:hover {
  animation: 2s linear forwards shadow;
  background-color: #001b50;
  background-image: linear-gradient(
    91.73deg,
    rgba(2, 34, 123, 0) -24.89%,
    #02227b 94.18%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-background-clip: text;
  -moz-text-fill-color: transparent;
  cursor: default;
}
@keyframes shadow {
  0% {
    opacity: 1;
    filter: blur(1px);
  }
  100% {
    opacity: 1;
    filter: blur(5px);
  }
}
.ss-heading.proof-text .bg-hover h2::before {
  background: linear-gradient(270deg, #fff 0, rgba(255, 255, 255, 0) 100%);
  z-index: 99;
}
.maintanance-hover h4 {
  font-weight: 400;
  margin-top: 10px;
  font-size: 16px;
  line-height: 24px;
}
.portfolio-hover-box p {
  display: -webkit-box !important;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.cutting-text,
.proof-box {
  position: relative;
  z-index: 99;
}
.footer-links li a:hover,
.proof-box h2,
h6.yellow_text {
  color: var(--color-primary-yellow);
}
button.learn-more {
  position: relative;
  display: inline-block;
  cursor: pointer;
  outline: 0;
  border: 0;
  vertical-align: middle;
  background: 0 0;
  padding: 0;
  font-size: inherit;
  font-family: inherit;
  width: 190px;
  height: 40px;
}
button.learn-more .circle {
  position: relative;
  display: block;
  margin: 0;
  width: 40px;
  height: 40px;
  background: 0 0;
  border: 1.3px solid var(--color-white);
  border-radius: 100px;
}
button.learn-more .circle .icon {
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  margin: auto;
  background: var(--color-white);
}
button.learn-more .circle .icon.arrow {
  left: 0;
  background: 0 0;
}
button.learn-more .button-text {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin-left: 45px;
  color: var(--color-white);
  font-weight: 400;
  font-size: 16px;
}
.attach_file_btn .file-drop-area,
.certification-img img,
.cmp-event-img1 img,
.creativy-img,
.culture-title,
.enhance-content,
.innovation-img img,
.our_mainbanar_of_part .custom-carousel .item.active .item-desc,
.portfolio-img,
.ss-process-img .core-value-img,
.we-are-img img,
button.learn-more.arrow-btn-gray:hover .circle,
button.learn-more:hover .circle {
  width: 100%;
}
button.learn-more:hover .circle .icon.arrow {
  transform: translate(5px, -50%);
}
.learn-more.arrow-btn {
  width: 90px !important;
}
.arrow-btn-secondary .circle,
.casestudy-next-prve button.learn-more .circle {
  border: 1.5px solid var(--color-primary) !important;
}
.arrow-btn-secondary .button-text {
  color: var(--color-primary) !important;
}
.arrow-btn-secondary2 .circle {
  border: 1.5px solid var(--color-secondary) !important;
}
.arrow-btn-secondary2 .button-text,
.client-info p {
  color: var(--color-secondary) !important;
}
.testimonials {
  background-image: url(../images/bg-multi.png);
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
}
.testimonials-box {
  background: rgba(255, 255, 255, 0.2);
  border: 1px solid var(--border-color);
  backdrop-filter: blur(25px);
  border-radius: 20px;
  padding: 25px;
  height: 100%;
}
.client-img {
  height: 62px;
  width: 62px;
}
.client-img img {
  border-radius: 100px;
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.client-name p,
.content_job_style p,
.innovation-text-main p,
.innvoation_ideas_about .accordion-header h6,
.innvoation_ideas_about .accordion-header h6 span,
.joinee-position p,
.our-hiring-text-dese p,
.our-hiring-text-dese_s1 p,
.tecnology_hirededicate h6,
.text_tecnology_hd p {
  color: var(--color-secondary);
  font-weight: 400;
}
.our-vision-text p {
  color: var(--color-secondary);
  font-size: 18px;
  line-height: 28px;
  text-transform: capitalize;
  font-weight: 500;
}
.client-info p,
.empowerment-text p {
  color: var(--color-secondary-gray);
  font-weight: 400;
}
.one-team-main .slick-slide > div,
.testimonials-box-main .slick-initialized .slick-slide {
  margin: 0 5px;
}
.testimonials-box-main .owl-carousel .owl-nav {
  position: absolute;
  top: -125px;
  right: 0;
}
.testimonials-box-main .owl-nav .owl-next,
.testimonials-box-main .owl-nav .owl-prev {
  height: 54px;
  width: 54px;
  border: 1px solid var(--border-color) !important;
  border-radius: 100px !important;
}
.feature:hover:after,
.glimpses-box .maintanance-box:hover .maintance-text,
.owl-theme .owl-nav .disabled {
  opacity: 1;
}
.testimonials-box-main .owl-theme .owl-nav [class*="owl-"]:hover {
  background-color: var(--color-primary);
  border-radius: 50px !important;
  color: #fff !important;
}
.casestudy_portfolio_description .portfolio-text h5,
.testimonials-box-main .owl-theme .owl-nav [class*="owl-"]:hover i {
  color: #fff !important;
}
.owl-carousel .owl-item,
.testimonials-box-main .owl-carousel {
  position: unset;
}
.ss-blog-box {
  position: relative;
  transition: 0.9s;
}
.blog-img-main {
  height: 100%;
  position: relative;
  overflow: hidden;
  transition: 0.7s;
  border-radius: 15px;
}
.ss-blog-box:hover .blog-img-main::before {
  background: linear-gradient(
    180deg,
    rgba(0, 58, 220, 0.4) 0,
    rgba(0, 58, 220, 0.8) 100%
  );
  transition: 0.9s;
}
.blog-img-main::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  background: linear-gradient(
    180deg,
    rgba(0, 27, 80, 0) 0,
    rgba(0, 27, 80, 0.8) 100%
  );
  background-size: cover;
  border-radius: 15px;
}
.blog-slider::after,
.blog-slider::before {
  position: absolute;
  content: "";
  height: 100px;
  width: 100px;
  background-repeat: no-repeat;
}
.blog-img-main img,
.blog-section .owl-carousel .owl-item img {
  width: 100% !important;
}
.blog-text {
  position: absolute;
  bottom: 20px;
  width: 100%;
  padding: 20px;
}
.blog-slider::after {
  background-image: url(../images/dot1.png);
  right: 0;
  top: 0;
}
.blog-slider::before {
  background-image: url(../images/dot2.png);
  left: -53px;
  bottom: 0;
}
.ss-case-study .nav-pills .nav-link {
  background: #fff;
  border: 1px solid #e0e0e0;
  border-radius: 37px;
  margin-right: 15px;
  margin-bottom: 20px;
  color: var(--color-secondary);
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  padding: 10px 25px;
  transition: 0.5s;
}
.nav-pills .show > .nav-link,
.ss-case-study .nav-pills .nav-link.active {
  background-color: var(--color-secondary);
  color: var(--color-white) !important;
  border: 1px solid transparent;
}
.specifiy_protfolio_page .ss-case-study .nav-pills .nav-link {
  margin-right: 0 !important;
}
.btns_of_comman,
.nav-section1,
.social_all_links,
.specifiy_protfolio_page .ss-case-study .nav-pills,
.submit_cancel_btn {
  column-gap: 10px;
}
.owl-carousel .owl-item img {
  width: auto;
}
.portfolio-box-main {
  background-image: url(../images/gridimg.png);
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  height: 100%;
  padding: 20px 20px 0;
  z-index: 99;
  border-radius: 15px;
}
.portfolio-img img {
  margin: 0 auto;
}
.cutting-edge,
.portfolio-box-main .portfolio-img,
.ss_hover_sub {
  overflow: hidden;
  position: relative;
}
.portfolio-box-main .portfolio-img img {
  height: 100% !important;
  object-fit: inherit;
}
.portfolio-hover-main {
  bottom: 0;
  height: 0;
  left: 0;
  position: absolute;
  right: 0;
  transition: 0.9s;
  width: 100%;
  backdrop-filter: blur(10px);
  background: rgba(0, 0, 0, 0.4);
  opacity: 0;
}
.solution-img:hover,
.ss-solution-img {
  transition: 4s cubic-bezier(0.2, 0.8, 0.2, 1) !important;
}
.portfolio-box-main:hover .portfolio-hover-main {
  height: 100%;
  opacity: 1;
}
.portfolio-hover-box {
  padding: 20px;
}
.certification,
.com_culture_memories,
.countless-talents,
.portfolio-box-main,
.ss-case-study,
.talents-img,
.technology-we-used {
  overflow: hidden;
}
.portfolio-slider.testimonials-box-main .owl-carousel .owl-nav.disabled {
  top: unset !important;
  bottom: 0;
  left: 0;
  right: 0;
  max-width: 0 auto;
}
.ss-creativity {
  position: relative;
  overflow: hidden;
}
.creativy-video {
  width: 100%;
  height: auto;
}
.creativity-main {
  height: 100%;
  overflow: hidden;
}
.creativity-content-main .slick-dots {
  display: block;
  position: absolute;
  bottom: 20px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
}
.creativity-content-main .slick-dots li {
  height: 8px !important;
  position: relative;
  display: inline-block;
  padding: 0;
  cursor: pointer;
  width: 22%;
  margin: 0 2px;
  transition: width 0.3s ease-in-out;
}
@keyframes loading {
  from {
    width: 0%;
  }
  to {
    width: 100%;
  }
}
.ft-slick__dots--custom {
  height: 5px;
  width: auto;
  background-color: rgba(255, 255, 255, 0.2);
  position: relative;
}
.creativity-content-main .slick-dots .slick-active {
  transition: width 0.3s ease-in-out;
}
.slick-dots .slick-active .ft-slick__dots--custom {
  top: 0;
  overflow: hidden;
}
.slick-dots .slick-active .ft-slick__dots--custom .loading {
  height: 8px;
  animation: 5s ease-in loading;
  background-color: var(--color-primary-yellow);
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
}
.creativity-content-main {
  position: absolute;
  display: table;
  height: 100%;
  left: 0;
  right: 0;
  z-index: 99;
  margin: 0 auto;
  bottom: 100px;
  display: flex;
  justify-content: flex-end;
  align-items: self-end;
}
.creativity-main::after {
  position: absolute;
  content: "";
  background-color: rgba(0, 0, 0, 0.4) !important;
  height: 100%;
  width: 100%;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 9;
}
.creativity-content-main .slick-dots li::after {
  position: absolute;
  content: "";
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: var(--color-secondary-white);
  left: 0;
  text-transform: capitalize;
  margin-top: 5px;
}
.creativity-content-main .slick-dots li::before {
  position: absolute;
  content: "";
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: var(--color-secondary-white);
  left: 0;
  top: 33px;
}
.creativity-content-main .slick-dots li:first-child::after {
  content: "Nurture Creativity";
}
.creativity-content-main .slick-dots li:first-child::before {
  content: "01";
}
.creativity-content-main .slick-dots li:nth-child(2)::after {
  content: "partner for your  journey";
}
.creativity-content-main .slick-dots li:nth-child(2)::before {
  content: "02";
}
.creativity-content-main .slick-dots li:nth-child(3)::after {
  content: "success stories from scratch";
}
.creativity-content-main .slick-dots li:nth-child(3)::before {
  content: "03";
}
.creativity-content-main .slick-dots li:nth-child(4)::after {
  content: "Innovation Meets Excellence";
}
.creativity-content-main .slick-dots li:nth-child(4)::before {
  content: "04";
}
.about-bg {
  background-color: var(--color-secondary-white);
  position: relative;
}
.our-vision-img::before,
.ss-vision-img::before,
.vision-label,
.vision-label1,
.vision-label2,
.vision-label3,
.vision-label4 {
  position: absolute;
}
.our-vision-img::before {
  content: "";
  width: 25px;
  height: 25px;
  background-color: var(--color-primary-yellow);
  border-radius: 50px;
  left: 11px;
  right: 0;
  top: 1px;
}
.ss-vision-img img {
  border-radius: 18px;
  position: relative;
}
.vision-box {
  background: #fff;
  box-shadow: 0 4px 17px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  padding: 15px 20px;
}
.vision-label1 {
  right: 96px;
  top: -20px;
}
.vision-label2 {
  right: 96px;
  top: 50px;
}
@keyframes scale_up_down {
  0% {
    webkit-transform: scale(0.9);
    transform: scale(0.9);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
.vision-label3 {
  right: 96px;
  bottom: 80px;
}
.vision-label3.vision-box {
  background-color: var(--color-primary);
  border-radius: 70px;
}
.vision-label4 {
  left: 79px;
  bottom: -32px;
}
.vision-label4.vision-box {
  border-radius: 65px;
  background-color: var(--color-primary-yellow);
}
.ss-vision-img::before {
  content: "";
  height: 134px;
  width: 134px;
  background-color: var(--color-primary);
  border-radius: 100px;
  top: -47px;
  left: 86px;
}
.ss-mission-img .vision-label4.vision-box {
  background-color: var(--color-primary);
  position: absolute;
  left: unset;
  right: 96px;
  top: -20px;
  bottom: unset;
}
.ss-vision-img.ss-mission-img::before {
  left: unset;
  right: 83px;
  bottom: -59px;
  top: unset;
  background-color: var(--color-primary-yellow);
}
.ss-mission-img .vision-label3 {
  left: 73px;
  right: unset;
  bottom: 120px;
}
.ss-mission-img .vision-label2 {
  top: unset;
  bottom: 24px;
  left: 73px;
  right: unset;
}
.core-value-box {
  height: 100%;
  background: var(--color-white);
  border: 1px solid var(--border-color-secondary);
  padding: 24px 20px;
}
.timeline ul li:nth-child(2n):before,
.timeline ul li:nth-child(odd):before {
  position: absolute;
  width: 8px;
  height: 8px;
  background: var(--color-secondary);
  border-radius: 50%;
  box-shadow: 0 0 0 3px #d9d9d9;
  top: 50%;
  content: "";
}
.core-value-img {
  position: relative;
  height: 50px;
  width: 56px;
}
.core-value-img img {
  position: relative;
  height: 100%;
  object-fit: cover;
}
.timeline {
  position: relative;
  box-sizing: border-box;
  height: 100%;
  scroll-behavior: smooth;
}
.timeline:before {
  content: "";
  position: absolute;
  left: 50%;
  width: 2px;
  height: 100%;
  border-left: 1px dashed var(--color-secondary);
}
.pricing-list-main .pricing-card ul,
.timeline ul {
  padding: 0;
  margin: 0;
}
.timeline ul li {
  list-style: none;
  position: relative;
  width: 50%;
  box-sizing: border-box;
}
.timeline ul li:nth-child(odd) {
  float: left;
  text-align: left;
  clear: both;
  padding: 100px 40px 40px 0;
  counter-reset: my-sec-counter 3;
}
.timeline ul li:nth-child(2n) {
  float: right;
  text-align: left;
  clear: both;
  padding: 100px 0 100px 80px;
  counter-reset: my-sec-counter 4;
}
.timeline ul li:nth-child(odd):before {
  right: -5px;
}
.timeline ul li:nth-child(2n):before {
  left: -3px;
}
.timeline ul li p {
  margin: 10px 0 0;
  padding: 0;
}
@media (max-width: 1000px) {
  .timeline {
    width: 100%;
  }
}
@media (max-width: 767px) {
  .timeline {
    width: 100%;
    padding-bottom: 0;
  }
  h1 {
    font-size: 40px;
    text-align: center;
  }
  .timeline:before {
    left: 20px;
    height: 100%;
  }
  .timeline ul li:nth-child(2n),
  .timeline ul li:nth-child(odd) {
    width: 100%;
    text-align: left;
    padding-left: 60px;
    padding-bottom: 50px;
  }
  .timeline ul li:nth-child(2n):before,
  .timeline ul li:nth-child(odd):before {
    top: 50%;
    left: 16px;
  }
  .timeline ul li:nth-child(2n) .time,
  .timeline ul li:nth-child(odd) .time {
    top: 50%;
    left: 50px;
    right: inherit;
  }
}
.experience_of_work h4,
.glimpses-text,
.innovation-text-main h4,
.innvoation_ideas_about .accordion-header h5,
.story-box h5 {
  color: var(--color-secondary);
  font-weight: 500;
}
.innvoation_ideas_about .accordion-header h3 {
  color: var(--color-secondary);
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
}
.innvoation_ideas_about .accordion-header h4,
.tecnology_hirededicate p {
  color: var(--color-secondary);
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}
.ss-story span {
  font-size: 90px;
  font-weight: 250;
  color: var(--color-secondary);
  padding-left: 25px;
}
.ss-story.container-fluid {
  overflow: hidden;
  display: flex;
  white-space: nowrap;
}
.certificate-img,
.main_subblog_details .ss-author-img {
  height: 42px;
  width: 42px;
}
.certificate-img img {
  object-fit: cover;
  height: 100%;
  width: 100%;
}
.ss-border {
  border-left: 1px solid var(--color-secondary-gray);
  margin: 0 10px;
}
.ss-solution-img {
  position: relative;
  text-align: -webkit-right;
  margin: 0 -10px;
}
.solution-img2 {
  margin-left: 20px;
  transform: translate(0, 33px);
}
.experince-text {
  background-color: var(--color-primary);
  padding: 15px 7px;
  position: absolute;
  right: 132px;
  bottom: 154px;
}
.solution-img:hover {
  transform: translate(-20px, -30px);
}
.bg-logo {
  position: relative !important;
}
.website-development.bg-logo::after {
  position: absolute;
  content: "";
  background-image: url("../images/logo-bg.svg");
  height: 280px;
  width: 200px;
  left: 18%;
  background-repeat: no-repeat;
  top: 10%;
}
.meeting-img img,
.website-development-img img {
  border-radius: 15px;
}
.web-build-box {
  position: absolute;
  text-align: center;
  background: var(--color-white);
  box-shadow: 3.4px 10.2px 34px rgba(0, 0, 0, 0.1);
  border-radius: 13.6px;
  padding: 30px;
  left: 42px;
  bottom: 60px;
  animation: 1s infinite alternate tpupdown;
}
.like-round {
  height: 33px;
  width: 33px;
  text-align: center;
  line-height: 32px;
  background: var(--color-primary-yellow);
  box-shadow: 1.36px 5.44px 13.6px rgba(251, 212, 93, 0.5);
  position: absolute;
  top: -18px;
  margin: 0 auto;
  left: 0;
  right: 0;
  border-radius: 18px;
}
@keyframes tpupdown {
  0% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
    -webkit-transform: translateY(-10px);
    -moz-transform: translateY(-10px);
    -ms-transform: translateY(-10px);
    -o-transform: translateY(-10px);
    transform: translateY(-10px);
  }
}
.like-round i {
  color: var(--color-white);
  left: -32px;
  bottom: 61px;
  font-size: 18px;
}
.build-text,
.build-text h5,
.certification-title h3,
.certification-title h5,
.modal-common .modal-title-common h4,
.modal-heading h4 {
  color: var(--color-black);
}
.certification-title h3 {
  font-size: 18px;
  line-height: 28px;
  text-transform: uppercase;
  font-weight: 500;
}

.build-text h5,
.pagination-container .pagination li.active {
  font-weight: 700;
}
.web-build-box2 {
  position: absolute;
  top: 50px;
  background: rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(11px);
  padding: 10px 15px;
  border-radius: 7.98365px;
  right: 0;
  text-align: start;
  animation: 3s infinite alternate both scale_up_down;
}
.attractive-main,
.dream-project-box {
  position: relative;
  background-repeat: no-repeat;
}
@keyframes scale_up_down {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  100% {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }
}
.attractive-main {
  background-color: var(--color-primary);
  background-image: url("../images/wave-bg.png");
  background-size: cover;
  background-position: top center;
}
.faq-main {
  background-color: var(--color-secondary);
  height: 100%;
  width: 100%;
  border-radius: 0 0 40px 40px;
  padding-bottom: 400px !important;
  min-height: 1100px;
}
.faq-accordion .accordion-button:not(.collapsed) {
  color: var(--color-secondary-white);
  box-shadow: unset;
  background-color: transparent;
  border-bottom: unset;
}
.faq-accordion .accordion-item {
  background-color: transparent;
  color: var(--color-faq-btn);
  border-bottom: 1px solid #1e335c !important;
}
.faq-accordion .accordion-button {
  color: #afb7c7;
  background-color: transparent;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  position: relative;
  padding: 20px 0 !important;
}
.faq-accordion .accordion-button:focus {
  box-shadow: unset;
  border-bottom: unset;
}
.faq-accordion .faq-content p {
  color: #f4f5f9;
  font-weight: 400;
  font-size: 12px;
}
.faq-accordion .accordion-button:not(.collapsed)::after {
  position: absolute;
  content: "";
  background-image: url("../images/minus.svg") !important;
  border-radius: 50px;
  border: 1px solid var(--color-secondary-white);
  height: 25px;
  width: 25px;
  left: 0;
  right: 0;
  transform: unset;
  background-position: center;
}
.faq-accordion .accordion-button::after {
  position: absolute;
  background: url("../images/plus.svg") center no-repeat;
  content: "";
  border-radius: 50px;
  left: 0;
  right: 0;
  border: 1px solid #5f6c85;
  height: 25px;
  width: 25px;
  line-height: 25px;
}
.faq-accordion .accordion-body {
  padding: 0 70px 15px 0;
}
.faq-accordion .accordion-body p {
  color: #f4f5f9;
  font-weight: 400;
  font-size: 14px;
}
.faq-accordion .accordion::after {
  position: absolute;
  height: 100%;
  width: 2px;
  content: "";
  background-color: rgba(146, 170, 237, 0.1);
  left: -45px;
  top: 0;
}
.faq-accordion .accordion-button:not(.collapsed)::before {
  position: absolute;
  content: "";
  left: -44px;
  height: 50%;
  width: 2px;
  background-color: var(--color-primary-yellow);
  top: 50%;
  transform: translate(-50%, -50%);
}
.dream-project-box {
  background-color: var(--color-secondary-white);
  padding: 40px 90px;
  border-radius: 20px;
  min-height: 570px;
  display: table;
  width: 100%;
  margin-top: -300px;
  background-image: url(../images/wave-bg2.png);
  background-position: bottom center;
}
.dream-project-middle,
.main_work_culture_panal {
  display: table-cell;
  vertical-align: middle;
}
.dream-project-border {
  border-right: 1px solid rgba(0, 27, 80, 0.1);
  margin: 0 25px;
}
.custom-input .form-label {
  color: var(--color-secondary);
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
}
.custom-input .form-control {
  background: #fff;
  border: 1px solid #e3e3e3;
  border-radius: 10px;
  height: 55px;
  color: var(--color-secondary);
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
}
.apply_job_modal .custom-select .css-t3ipsp-control:focus,
.apply_job_modal .custom-select .css-t3ipsp-control:hover,
.custom-input .form-control:focus {
  border: 1px solid var(--color-secondary);
}
.dream-project-box .hstack {
  align-items: unset;
  justify-content: space-between;
}
.custom-input textarea {
  min-height: 125px !important;
  resize: unset !important;
}
.custom-file-upload {
  display: inline-block;
  padding: 10px 20px;
  background-color: #f2f2f2;
  color: #333;
  border: 1px solid #ccc;
  cursor: pointer;
}
.custom-file-upload:hover {
  background-color: #e0e0e0;
}
.custom-file .form-control[type="file"],
.file-input {
  position: absolute;
  cursor: pointer;
  opacity: 0;
  right: 0;
  top: 0;
  height: 100%;
  width: 100%;
}
.file-upload {
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  color: var(--color-secondary);
}
.custom-file p {
  display: inline-block;
  width: 100%;
  text-align: end;
}
.custom-select .css-13cymwt-control {
  background-color: var(--color-white);
  height: 55px;
  border-radius: 10px;
}
.css-1fdsijx-ValueContainer {
  height: 55px;
}
.custom-select .css-t3ipsp-control:hover,
.job_details_btn button.learn-more .circle {
  border-color: var(--color-secondary);
}
.custom-select .css-t3ipsp-control {
  box-shadow: unset !important;
  border-radius: 10px !important;
}
.custom-select .custom-select .css-13cymwt-control {
  box-shadow: unset !important;
  border-radius: 10px;
}
#tecnology_scroll,
.add_responsive_img,
.change_vision_img,
.commna_font_style.have_clear_vision_mission > p,
.core-value-section .slick-next:before,
.core-value-section .slick-prev:before,
.creativity-content h6,
.custom-carousel .item.active h4,
.custom-select .css-1u9des2-indicatorSeparator,
.footer-social-mobile.footer-social,
.meeting-img-mobile,
.middle input[type="radio"],
.our_com_casestudy .website-development.bg-logo::after,
.our_mainbanar_of_part .custom-carousel .item:after,
.our_mainbanar_of_part .custom-carousel .learn-more,
.responsive_header_h2,
.team-img2,
.tecnology_plus_slider.tecnology_plus_slider_nav .owl-carousel .owl-nav,
.website-development-img-mb,
input[type="checkbox"].toggle + label .on,
input[type="checkbox"].toggle:checked + label .off {
  display: none;
}
.about_sub_job_desc,
.achivement-text,
.contact-us-form-main,
.friday-evenings,
.look-forward,
.main-challenges,
.our_process_mainblog,
.protfolio-detail-bg,
.services-portfolio-main,
.specific_blog_page {
  background-color: var(--color-secondary-white);
}
.ss-portfolio-box-main {
  height: 100% !important;
  padding: 70px 0 90px 70px;
}
.ss-portfolio-box {
  align-items: start;
}
.service-portfolio-slider .owl-carousel .owl-nav {
  bottom: 0;
  top: unset;
  left: 0;
  right: 0;
  margin: 20px auto 0;
  position: unset;
}
.service-multistep .dream-project-box {
  margin-top: 0 !important;
}
.creativity-content {
  position: relative;
  z-index: 9999;
}
.bg-service {
  position: relative;
  background: var(--color-secondary-white);
}
.bg-service::after {
  position: absolute;
  background: linear-gradient(
    180deg,
    rgba(0, 58, 220, 0) 31.9%,
    #001b50 171.98%
  );
  content: "";
  left: 0;
  bottom: 0;
  height: 200px;
  width: 100%;
  opacity: 0.1;
}
.services-in-action-main::after {
  position: absolute;
  content: "";
  bottom: 0;
  right: 0;
  height: 100%;
  width: 100%;
  background-image: url(../images/plan.svg);
  background-repeat: no-repeat;
  background-position: right 25px bottom;
  z-index: -9;
}
.services-in-action-main {
  position: relative;
  display: table-cell;
  vertical-align: middle;
}
.services-in-action-main::before {
  position: absolute;
  content: "";
  top: 0;
  height: 100%;
  width: 100%;
  background-image: url(../images/shadow.png);
  background-repeat: no-repeat;
  background-position: right 0 top -13%;
  z-index: -9;
}
.our-vision-img.overview-img::before {
  left: 5px !important;
}
.contact-us-title h2,
.enhance-content h4 {
  color: var(--color-title);
}
.contact-us-title h2{
  font-weight: 400;
  font-size: 25px;
  line-height: 35px;
}
.right-img {
  height: 25px;
  width: 25px;
  margin-right: 13px;
}
.bubble-img img,
.portfolio-grid-img img,
.right-img img {
  height: 100%;
  object-fit: cover;
}
.ideation-services {
  height: 100vh;
  position: sticky;
  top: 20px;
  display: table;
  width: 100%;
}
.enhance-content-main {
  width: 100% !important;
  box-shadow: 0 10px 40px rgba(0, 0, 128, 0.05);
  border-radius: 40px !important;
  padding: 30px;
  margin-bottom: 20px;
  position: relative;
  background-color: #fff;
}
.technology-box {
  border-top: 1px solid #b6b6b6;
  border-bottom: 1px solid #b6b6b6;
}
.technology-img {
  padding: 10px;
  height: 50px;
  width: 50px;
}
.technology-text h6 {
  color: var(--color-faq-btn);
}
.technology-border {
  border-right: 1px solid #b6b6b6;
  padding-left: 20px;
}
.bg-multi {
  background-image: url(../images/bg-multi2.png);
  background-repeat: no-repeat;
  background-size: cover;
}
.experience-box {
  padding: 25px 0;
  background: rgba(255, 255, 255, 0.4);
  border: 1px solid #fff;
  border-radius: 20px;
  width: 170px;
  transition: 0.5s;
}

.healthcare-box {
  padding: 25px 18px !important;
  width: 240px;
}

.experience-box:hover {
  background: #fff;
  border: 1px solid transparent;
  transform: translate(0, -12px);
}
.experience-box-main.d-flex {
  column-gap: 15px;
}

.experience-box-main img{
  height: 68px;
  width: 68px;
}

.our-journey-main,
.process-main {
  background: var(--color-secondary-white);
}
.ss-process-tab .nav-pills .nav-link {
  display: flex;
  padding: 15px 12px;
  border-radius: 0;
}
.ss-process-tab .nav-pills .nav-link span {
  margin-left: auto;
}
.ss-process-tab .nav-pills .nav-link span i {
  color: var(--color-secondary);
  font-size: 20px;
}
.ss-process-tab .nav-link {
  font-weight: 500;
  font-size: 18px;
  position: relative;
  line-height: 28px;
  color: var(--color-secondary);
}
.ss-blog-date,
.technology-circle-main .technology-title {
  font-size: 12px;
  color: var(--color-secondary);
}
.ss-process-tab .nav-pills .nav-link.active {
  color: var(--color-primary);
  background: linear-gradient(90deg, #dfe5f7 0, rgba(223, 229, 247, 0) 100%);
}
.ss-blog-box-title .ss-blog-title,
.ss-process-tab .nav-pills .nav-link.active span i {
  color: var(--color-primary);
}
.ss-process-tab .nav-pills .nav-link.active::after {
  position: absolute;
  content: "";
  height: 100%;
  width: 3px;
  background-color: var(--color-primary);
  left: 0;
  top: 0;
  opacity: 1;
}
.ss-process-img.d-flex,
.ss-technology-box {
  column-gap: 30px;
}
.designing-image-main {
  column-gap: 20px;
}
.web-build-box.maitanance-box {
  bottom: unset;
  top: 45px;
}
.web-build-box2.maitanance-box2 {
  top: unset;
  bottom: 90px;
}
.maintanance-box {
  position: relative;
  border-radius: 15px;
  min-height: 253px;
  overflow: hidden;
  transition: 0.9s;
  width: 100%;
}
.maintance-img {
  position: relative;
  height: 253px;
  width: 100%;
}
.maintance-img::before {
  position: absolute;
  content: "";
  height: 100%;
  width: 100%;
  background: linear-gradient(
    180deg,
    rgba(0, 27, 80, 0.5) 34.18%,
    rgba(0, 27, 80, 0.8) 100%
  );
}
.cutting-edge::after,
.cutting-edge::before {
  background: linear-gradient(270deg, #001745 0, rgba(0, 23, 69, 0) 100%);
  width: 50%;
  position: absolute;
  top: 0;
  content: "";
  z-index: 99;
}
.maintance-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.maintanance-hover h3,
.maintance-text h3 {
  color: var(--color-white);
  font-size: 16px;
  line-height: 26px;
  font-weight: 500;
}
.maintance-text {
  position: absolute;
  bottom: 0;
  padding: 26px 25px;
  width: 100%;
}
.maintanance-hover {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 26px 25px;
  opacity: 0;
  height: 0;
  transition: 0.9s;
}
.maintanance-box:hover .maintanance-hover {
  opacity: 1;
  height: 100%;
  background-color: rgba(0, 27, 80, 0.8);
  transition: 0.9s;
}
.maintanance-box:hover .maintance-text {
  opacity: 0;
  transition-delay: 0.9s all;
}
.quality-box {
  position: absolute;
  right: 20%;
  bottom: 5%;
  margin: 0 auto;
  background: #fff;
  box-shadow: 0 6px 55px 2px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 25px;
}
.quality-box h6 {
  color: #5a7184;
}
.technology-box-main {
  border-right: 1px solid #efefef;
  transition: 0.5s;
  height: 100%;
}
.technology-info .col-lg-3:last-child .technology-box-main,
.technology-info .col-lg-4:last-child .technology-box-main,
.technology-info .col-lg-6:last-child .technology-box-main {
  border: unset;
}
.technology-circle {
  height: 74px;
  width: 74px;
  text-align: center;
  border-radius: 50px;
  line-height: 74px;
  margin: 0 auto;
  border: 1px solid #e0e0e0;
  transition: 0.9s;
}
.technology-circle-main:hover .technology-circle {
  background-color: var(--color-secondary);
  border: 1px solid var(--color-secondary);
  transition: 0.9s;
}
.technology-circle-main:hover .technology-title {
  color: var(--color-secondary);
  transition: 0.9s;
}
.technology-next-main {
  background-color: #011645;
}
.cutting-img .ss-story span {
  font-size: unset;
}
.ss-certified .ss-border,
.technology-img1 img {
  margin: 0 15px;
}
.cutting-edge::after {
  height: 100%;
  left: 0;
  transform: rotate(-180deg);
}
.cutting-edge::before {
  height: 100%;
  right: 0;
}
.cutting-img .ss-story {
  padding-top: 8px;
}
.ss-blog-box-main {
  background-color: #eff1f5;
  height: 100%;
}
.ss-blog-box-main:hover {
  background-color: var(--color-white);
}
.ss-blog-box-img {
  height: 210px;
  width: 100%;
  overflow: hidden;
}
.ss-blog-box-img img {
  width: 100%;
  object-fit: cover;
  height: 100% !important;
  border-radius: 5px 5px 0 0;
}
.ss-blog-box-title {
  padding: 20px 16px 30px;
}
.ss-blog-box-title .blog-title-info {
  color: var(--color-secondary);
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  text-transform: capitalize;
}
.ss-author-img {
  height: 36px;
  width: 36px;
}
.ss-author-img img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 50px;
}
.ss-blog-date {
  line-height: 20px;
}
.ss-blog-author-main {
  padding: 0 16px 30px;
}
.portfolio-img-main {
  margin-top: -200px;
}
.portfolio-img,
.portfolio-mockup {
  margin: 0 auto;
  text-align: center;
}
.portfolio-grid {
  background-image: url(../images/grid2.svg);
  padding: 100px 50px;
}
.blog .testimonials-box-main .owl-carousel .owl-stage,
.child,
.hiring_dedication_section .ideation-services,
.logo_design_portfolio > div,
.mega_menu_links,
.middle,
.portfolio-grid img,
.same_pricing_class,
.testimonials-box-main .slick-slide > div,
.testimonials-page-box {
  height: 100%;
}
.tap-to-scan {
  background-color: var(--color-secondary);
  position: relative;
  z-index: 99;
}
.tap-to-scan::after {
  position: absolute;
  content: "";
  top: 0;
  background-image: url(../../assets/images/portfolio-images/portfolio-bg.jpg);
  right: 0;
  height: 100%;
  width: 65%;
  background-size: cover;
  background-position: center;
  z-index: -9;
}
.portfolio-tab .nav-link {
  position: relative;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #7c8fb4;
  margin-bottom: 20px;
}
.portfolio-tab .nav-link.active {
  color: var(--color-primary-yellow);
  background-color: transparent;
}
.portfolio-tab .nav-link.active::after {
  position: absolute;
  content: "";
  left: 0;
  height: 100%;
  width: 2px;
  background-color: var(--color-primary-yellow);
  top: 0;
  opacity: 1;
}
.ss-technology-box-main {
  padding: 45px 30px;
  border: 1px solid #d0d0d0;
  border-radius: 8px;
  margin-left: 2px;
  display: flex;
  flex-direction: column;
  width: 100%;
}
.technology-we-used .owl-item,
.testimonials-box-main .slick-list,
.testimonials-box-main .slick-slider .slick-track {
  display: flex !important;
}
.ss-technology-content h4 {
  color: #222b24;
  font-weight: 500;
}
.technology-tag {
  background: #eee;
  border-radius: 5px;
  padding: 6px 15px;
  display: inline-block;
  color: var(--color-secondary);
}
.journey-bg {
  height: 500px;
  width: 100%;
}
.journey-content {
  padding: 45px 40px;
  background-color: var(--color-white);
  margin-top: -150px;
}
.service-portfolio-img {
  height: 400px;
  overflow: hidden;
}
.service-portfolio-img img {
  height: 100%;
  object-fit: contain;
  width: 100%;
}
.service-portfolio-slider.testimonials-box-main .slick-slide img{
  height: inherit;
}
.calendly-inline-widget iframe {
  min-width: 100%;
  height: 650px;
  overflow: scroll;
}
.blog_point li::after,
.team-member-img img {
  width: 100%;
  height: 100%;
}
::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: var(--color-primary-yellow);
}
.Es8downQlxiASDyltHtn {
  line-height: unset !important;
}
.modal-common .btn-close {
  color: #111116;
  opacity: 1;
}
.modal-common .modal-header {
  padding: 20px 25px 0;
}
.main-functionality-accordion .nav-tabs,
.modal-header {
  border-bottom: unset;
}
.modal-common .modal-body {
  padding: 20px 25px 25px;
}
.innvoation_ideas_about .accordion-button:focus,
.main-functionality-accordion .accordion-button:focus,
.modal-common .btn-close:focus {
  box-shadow: unset;
}
::placeholder {
  color: var(--color-secondary) !important;
}
.main-functionality-accordion .accordion {
  --bs-accordion-bg: transparent;
  --bs-accordion-border-width: 0px;
  position: relative;
}
.main-functionality-accordion .accordion::after {
  position: absolute;
  content: "";
  background-color: #173570;
  height: 100%;
  width: 2px;
  left: -40px;
  top: 0;
}
.main-functionality-accordion .accordion-button {
  color: rgba(255, 255, 255, 0.5);
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  padding: 20px 0;
  position: relative;
}
.main-functionality-accordion .accordion-button:not(.collapsed) {
  color: var(--color-primary-yellow);
  background-color: transparent;
  font-weight: 600;
}
.main-functionality-accordion ul,
.our_culture_memories ul,
ul.social {
  margin: 0;
  padding: 0;
}
.main-functionality-content li h6 {
  font-size: 15px;
}
.main-functionality-content li p {
  font-size: 15px;
  color: var(--color-secondary-white);
  font-weight: 400;
}
.social li i,
.social li span {
  font-size: 18px;
}
.main-functionality-content li::marker {
  color: var(--color-white);
  font-size: 10px;
}
.main-functionality-accordion .accordion-item {
  border: unset;
  position: relative;
}
.main-functionality-accordion .accordion-body {
  padding: 0 0 0 15px;
}
.main-functionality-accordion .accordion-button:not(.collapsed)::after {
  background-image: url(../images/accoridion-arrow.svg);
  height: 11px;
  width: 11px;
  transform: unset;
  background-size: cover;
}
.main-functionality-accordion .accordion-button:not(.collapsed)::before {
  position: absolute;
  content: "";
  height: 100%;
  width: 2px;
  background-color: var(--color-primary-yellow);
  left: -40px;
  z-index: 99;
  top: 10px;
}
.main-functionality-accordion .accordion-button::after {
  background-image: unset;
}
.main-functionality-content li {
  padding: 4px 0;
}
.functionality-images,
.main-challenges-img {
  height: 600px;
  overflow: hidden;
  position: relative;
}
.main-challenges-img img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}
.functionality-images.functionality-img-web {
  height: unset;
}
.software-solution::after {
  position: absolute;
  content: "";
  background-color: #f4f5f9;
  right: 0;
}
.blog_wrapper_alldet ul {
  list-style-type: none;
  padding: 0;
}
.blog_wrapper_alldet h6 {
  font-weight: 500 !important;
}
.blog_point li {
  position: relative;
  padding-left: 30px;
}
.blog_point li::after {
  position: absolute;
  content: "";
  background-image: url(../images/blog-images/trueic.svg);
  left: 0;
  top: 2px;
  background-repeat: no-repeat;
}
.team-bg-img .web-build-box {
  padding: 18px;
  top: 95px;
  bottom: unset;
}
.team-bg-img .web-build-box.team-box2 {
  bottom: 95px;
  top: unset;
  left: unset;
  right: 50px;
}
.team-member-box-main {
  background-color: #ecf0ff;
  transition: 0.5s;
  flex-direction: column;
  height: 100%;
}
.team-member-box-main:hover {
  transform: translate(0, -10px);
  transition: 0.5s;
}
.team-leaders-info .col-lg-4.col-md-6:last-child .team-member-img,
.team-member-info-main,
.viewers_counters {
  padding: 15px;
}
.team-member-info-main .member-name p {
  font-weight: 500;
  color: var(--color-secondary);
  font-size: 14px;
}
.team-member-info-main .member-name h6 {
  font-size: 14px;
  font-weight: 400;
  color: rgba(0, 27, 80, 0.8);
}
.social-profiles {
  column-gap: 5px;
}
.social-profiles .profiles-link {
  height: 25px;
  width: 25px;
}
.team-member-box-main:hover .team-member-img {
  background-color: var(--color-primary);
  transition: 0.5s;
}
.team-member-img {
  position: relative;
  background-color: transparent;
}
.team-leaders-info .col-lg-4.col-md-6:last-child .team-member-img img {
  width: auto;
  height: auto;
}
.face-goes-text {
  font-size: 25px;
  color: #fff;
  margin-top: 15px;
  line-height: 34px;
}
.team-leaders-info
  .col-lg-3.col-md-6:last-child
  .team-member-box-main:hover
  .team-member-img
  .face-goes-text {
  display: block;
  color: var(--color-white);
  transition: 0.5s;
}
.face-goes-text h3 {
  font-size: 24px;
  line-height: 30px;
  font-weight: 400;
  color: var(--color-white);
}
.we-are-sensussoft-section {
  background: linear-gradient(#001b50 60%, #f9d14b 40%);
  position: relative;
  overflow: hidden;
}
.certification-title::before,
.get-in-touch,
.learningbox-main {
  background-repeat: no-repeat;
  background-size: cover;
}
.learningbox-main {
  height: 100%;
  width: 100%;
  padding: 50px 35px;
}
.learningbox-main.learningbox1 {
  background-image: url(../images/culture/learning-box-bg.png);
  background-position: right;
}
.learningbox-main.learningbox2 {
  background-image: url(../images/culture/learning-box-bg2.png);
  background-position: left;
}
.build-box-main {
  background-position: bottom right;
  padding: 30px;
  background-color: var(--color-secondary);
  display: flex;
  flex-direction: column;
  background-repeat: no-repeat;
  height: 100%;
  justify-content: space-between;
}
.build-box-main:hover,
.diff_diff_tecnology li:hover {
  transition: 0.5s;
}
.build-section {
  overflow: hidden;
  padding-bottom: 200px !important;
}
.buildtogether-slider.testimonials-box-main .owl-carousel .owl-nav {
  top: unset;
  bottom: -120px;
}
.buildtogether-slider.testimonials-box-main .owl-nav [class*="owl-"]:hover {
  border-color: var(--color-secondary) !important;
  background-color: var(--color-secondary);
}
.buildtogether-slider.testimonials-box-main
  .owl-theme
  .owl-nav
  [class*="owl-"]:hover {
  border-color: var(--color-secondary) !important;
}
.one-team-main .slick-track .slick-slide:nth-child(3n + 3) {
  padding-top: 25px !important;
}
.one-team-main .slick-track .slick-slide:nth-child(3n + 2) {
  padding-top: 120px !important;
}
.one-team-main .slick-track .slick-slide:nth-child(3n + 4) {
  padding-top: 60px !important;
}
.talents-img:hover img {
  scale: 1.04;
  filter: grayscale(0%);
}
.talents-img.talent-logo img {
  filter: grayscale(0%);
}
.talents-img img {
  width: auto;
  height: 100%;
  display: block;
  transition: 1s linear;
  filter: grayscale(100%);
}
.certification-slider .slick-list,
.one-team-main .slick-list {
  margin: 0 -10px;
}
.call:hover,
.custom-carousel .item:last-child,
.protfolio-previous .btn-wrapper.btn-wrapper-blue img {
  margin-right: 0;
}
.award-box {
  padding: 25px 15px 35px;
  border: 0.774981px solid rgba(255, 255, 255, 0.2);
  height: 100%;
  display: flex;
  flex-direction: column;
}
.award-img {
  height: 186px;
}
.award-name p {
  color: var(--color-white);
  font-weight: 500;
  margin-left: 8px;
}
.award-text {
  color: rgba(255, 255, 255, 0.78);
}
.award-box:hover {
  transition: 0.5s;
  border: 1px solid var(--color-white);
}
.certification-title {
  padding: 20px;
  border: 2px solid var(--color-secondary);
  background-color: var(--color-primary-yellow);
  width: max-content;
  position: relative;
}
.certification2021-box {
  position: relative;
  padding-top: 20px;
}
.certification-title::after {
  position: absolute;
  content: "";
  left: 0;
  background-color: var(--color-secondary);
  height: 100%;
  width: 102%;
  top: 6px;
  z-index: -99;
}
.certification-title::before {
  position: absolute;
  content: "";
  right: -41px;
  background-image: url(../images/culture/sparkle.svg);
  height: 100%;
  width: 61px;
  top: -41px;
  overflow: unset;
}
.certification-slider .slick-slide > div {
  margin: 0 20px;
}
.workshops #card_1 {
  --index: 1;
}
.workshops #card_2 {
  --index: 2;
}
.workshops #card_3 {
  --index: 3;
}
.workshops #card_4 {
  --index: 4;
}
.workshops .card {
  position: sticky;
  top: 150px;
  padding-top: calc(var(--index) * var(--card-top-offset));
  --bs-card-bg: transparent;
  --bs-card-border-width: 0px;
}
@supports (animation-timeline: works) {
  @scroll-timeline cards-element-scrolls-in-body {
    source: selector(body);
    scroll-offsets: selector(#cards) start 1, selector(#cards) start 0;
    start: selector(#cards) start 1;
    end: selector(#cards) start 0;
    time-range: 4s;
  }
  .workshops .card {
    --index0: calc(var(--index) - 1);
    --reverse-index: calc(var(--numcards) - var(--index0));
    --reverse-index0: calc(var(--reverse-index) - 1);
  }
  .workshops .card__content {
    transform-origin: 50% 0%;
    will-change: transform;
    --duration: calc(var(--reverse-index0) * 1s);
    --delay: calc(var(--index0) * 1s);
    animation: var(--duration) linear scale var(--delay) forwards;
    animation-timeline: cards-element-scrolls-in-body;
  }
  @keyframes scale {
    to {
      transform: scale(calc(1.1 - calc(0.1 * var(--reverse-index))));
    }
  }
}
.workshops #cards {
  list-style: none;
  outline: calc(var(--outline-width) * 10) solid blue;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(var(--numcards), var(--card-height));
  gap: var(--card-margin);
  padding-left: 0;
}
.workshops .card__content {
  padding: 50px;
  z-index: -999;
  position: relative;
  border-radius: 45px;
  height: 500px;
  width: 100%;
  display: table;
}
.workshops .workshops-cards {
  display: table-cell;
  vertical-align: middle;
  width: 100%;
}
.workshops ul {
  padding-left: 0 !important;
  margin: 0;
}
.workshops-title h3 {
  font-size: 42px;
  color: var(--color-white);
  font-weight: 400;
  line-height: 42px;
}
.workshops-point-box {
  background-color: rgba(255, 255, 255, 0.1);
  padding: 38px 18px;
  border-radius: 18px;
}
.workshopimg-main .build-text h5 {
  font-size: 26px;
  font-weight: 600;
}
.workshopimg-main .build-text h5 span {
  font-size: 15px;
  line-height: 35px;
}
.workshopimg-main .web-build-box {
  padding: 12px 23px;
  bottom: 20px;
  left: 0;
}
.get-in-touch {
  background-image: url(../images/contact-us-bg.jpg);
  padding-bottom: 280px;
  background-attachment: fixed;
}
.contact-us-form-box {
  padding: 45px 60px;
  border-radius: 20px;
  background: var(--secondary-color-ffffff, #fff);
  box-shadow: 0 3px 37px 2px rgba(0, 0, 0, 0.05);
}
.attach_file_btn .file-drop-area .file-msg {
  font-size: 14px;
}
.look-forward-box {
  background-image: url(../images/map-bg.png);
  background-repeat: no-repeat;
}
.look-forward-box h3{
  color: var(--color-secondary);
  font-size: 18px;
  line-height: 28px;
  font-weight:400;
}
.look-forward-box h4 {
  color: var(--color-secondary-gray);
  padding-top: 2px;
  font-weight: 500;
  font-size: 16px;
    line-height: 24px;
}
.look-forward-box p {
  color: var(--color-secondary-gray);
  font-weight: 500;
}
.look-forward-box.us-map {
  background-image: url(../images/us-map.png);
}
.file-drop-area {
  position: relative;
  text-align: center;
  align-items: center;
  padding: 12px;
  transition: 0.2s;
  border-radius: 8px;
  min-width: 240px;
  border: 1px dashed #cdcdcd;
  background: var(--primary-purple-200, #fbfafe);
}
.bubble-img img,
.child,
.childComponent {
  border-radius: 50%;
  width: 100%;
}
.file-msg {
  text-overflow: ellipsis;
  font-weight: 500;
  font-size: 20px;
  line-height: 22px;
  text-align: center;
  color: var(--color-primary);
  margin: 0 auto;
}
.sticky-span,
.testimonials-page-box .client-info p {
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
}
.file-input:focus {
  outline: 0;
}
.file_img {
  padding-right: 6px;
}
.attach_file_btn .attach-file-text {
  font-size: 12px;
  color: var(--color-title);
  font-weight: 400;
}
.contact-us-form-box {
  margin-top: -250px;
}
.myBubbleUI {
  width: 100%;
  height: 550px;
  background-color: #fff;
}
.childComponent {
  height: 100%;
  display: flex;
  user-select: none;
  align-items: center;
  justify-content: center;
}
.childComponent-main {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.bubble-img {
  width: 180px;
  height: 180px;
}
._2gu6r {
  margin-left: 10px;
  margin-right: 10px;
}
.bubble-ui-main .myBubbleUI {
  background-color: var(--color-primary-yellow);
  background-image: url(../images/home-images/dot.png);
}
.fridayevent1-slider .slick-slide > div,
.fridayevent2-slider .slick-slide > div {
  margin: 0 5px;
}
.features {
  width: 100%;
  grid-column-gap: 0.3rem;
  grid-row-gap: 0.3rem;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, 1fr);
  position: relative;
  margin-bottom: 20px;
  background: rgba(255, 255, 255, 0.05);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 11px;
  display: table;
}
.feature {
  --x-px: calc(var(--x) * 1px);
  --y-px: calc(var(--y) * 1px);
  --border: 2px;
  height: 122px;
  text-align: center;
  overflow: hidden;
  display: table-cell;
  vertical-align: middle;
}
.feature:after,
.feature:before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  overflow: hidden;
  inset: 0px;
  border-radius: inherit;
  background: radial-gradient(
    280px circle at var(--x-px) var(--y-px),
    rgba(50, 67, 99, 0.2),
    rgba(0, 27, 80, 0.1),
    transparent 95%
  );
}
.feature:before,
.our_talent_and_dream .ss-heading .bg-hover h2,
.ss_hover_sub .column {
  z-index: 1;
}
.feature:after {
  opacity: 0;
  z-index: 2;
  transition: opacity 0.4s;
}
.call,
.call:hover {
  transition: 0.5s ease-in-out;
}
button.scrollToUp {
  position: fixed;
  bottom: 20px;
  z-index: 999;
  border: none;
  padding: 7px 5px;
  left: 20px;
  border-radius: 20px;
  transition: 0.8s;
}
button.scrollToUp path {
  fill: #fff;
}
button.scrollToUp svg {
  height: 20px !important;
  width: 20px !important;
}
.creativity-main::before {
  position: absolute;
  content: "";
  background-color: #001b50;
  height: 100%;
  width: 100%;
  top: 0;
}
.sticky-span {
  background-color: var(--color-secondary);
  position: sticky;
  top: 17%;
  left: 50%;
  padding: 7px 25px;
  border-radius: 100px;
  z-index: 99;
  color: var(--color-secondary-white);
  display: inline-block;
  transform: translate(-50%, 5%) !important;
}
.blog-loader-main,
body #magicMouseCursor,
ul.social li {
  z-index: 9999;
  position: fixed;
}
.out-story.testimonials {
  overflow: unset;
}
.abousus-timeline.testimonials {
  overflow: unset;
  margin-top: -95px;
  padding-top: 116px;
  background-position: top center;
}
.testimonials-page-slider .owl-nav {
  position: absolute;
  right: 0;
  top: -10%;
  transform: translate(-50%, -50%);
}
.testimonials-page-slider .owl-nav .owl-next i,
.testimonials-page-slider .owl-nav .owl-prev i {
  color: #fff !important;
  font-size: 35px;
}
.testimonials-page-box .testimonials-box-s1 {
  border-radius: 25px;
}
.title-testimonials h2 {
  font-size: 200px;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #fff;
  font-family: Poppins, sans-serif;
  color: transparent;
  line-height: 80px;
  font-weight: 600;
  opacity: 0.5;
}
.testimonials-page-slider .owl-carousel .owl-stage {
  display: flex;
  align-items: flex-end;
}
.company-event-tab.our_talent_and_dream,
.testimonials-page-slider .owl-theme .owl-nav [class*="owl-"]:hover {
  background: 0 0;
}
.technology-next-main::after,
.technology-next-main::before {
  background-repeat: no-repeat;
  width: 400px;
  opacity: 0.2;
  bottom: 0;
  content: "";
  position: absolute;
}
.plyr__control--overlaid {
  background: var(--color-primary);
}
.plyr--video .plyr__control:focus-visible,
.plyr--video .plyr__control:hover,
.plyr--video .plyr__control[aria-expanded="true"] {
  background: var(--color-primary) !important;
}
.form-control.is-invalid,
.was-validated .form-control:invalid {
  background-image: unset;
  border-color: #e3e3e3;
}
.form-control.is-invalid:focus,
.was-validated .form-control:invalid:focus {
  box-shadow: unset;
  border-color: #e3e3e3;
}
.form-control.is-valid,
.was-validated .form-control:valid {
  border-color: #e3e3e3;
  background-image: unset;
}
.form-control.is-valid:focus,
.was-validated .form-control:valid:focus {
  border-color: #e3e3e3;
  box-shadow: unset;
}
.sucess-modal-main .modal-body {
  padding: 30px !important;
}
.sucess-img img {
  height: 120px;
}
.slick-dots li,
.slick-dots li button,
body #magicPointer {
  height: 5px;
  width: 5px;
}
.technology-next-main::after {
  background-image: url(../images/technology-bg1.png);
  left: 0;
  height: 100%;
  background-position: bottom left;
}
.technology-next-main::before {
  background-image: url(../images/technology-bg2.png);
  right: 0;
  height: 100%;
  background-position: bottom right;
  background-size: cover;
}
.we-are-img::after {
  position: absolute;
  height: 15%;
  width: 100%;
  content: "";
  left: 0;
  transform: translate(0, 1%);
  bottom: 0;
  background-color: var(--color-primary-yellow);
  border: 1px solid var(--color-primary-yellow);
}
.slick-dots li button:before {
  color: #d6d6d6;
  opacity: 0.8;
  height: 5px;
  width: 5px;
}
.slick-dots li button {
  font-size: 0;
  color: #d6d6d6;
  opacity: 0.8;
}
.slick-dots {
  bottom: 0;
}
.slick-dots li.slick-active button:before {
  color: var(--color-primary);
  opacity: 1;
}
.workshops-point-box.workshops-point-black {
  background-color: rgba(136, 111, 27, 0.1);
}
.error_page_notfound h2,
.error_page_notfound h4,
.inquires_of_pricing h5,
.metting_yellow_card .blog_point li,
.workshops-point-box.workshops-point-black h5 {
  color: #001b50;
}
.protfolio-previous .btn-wrapper:hover {
  background-color: transparent;
  border: 1px solid var(--color-secondary);
}
.blog-previous.protfolio-previous .btn-wrapper:hover {
  background-color: transparent;
  border: 1px solid var(--color-secondary-white);
}
.logo-sm {
  height: 33px !important;
}
.logo-sm.phn-img img {
  /* object-fit: unset; */
}
.footer-social-mobile.footer-social .phn-img {
  text-align: center;
  margin: 0 auto;
}
.footer-social-mobile.footer-social .phn-img-main {
  border-left: 1px solid var(--color-secondary2);
  padding: 13px 0;
}
.text_tecnology_hd h2 {
  font-size: 16px;
  line-height: 24px;
  padding-top: 5px;
  color: var(--color-black);
  font-weight: 600;
}
.nurture-creativity-title .our-vision-heading p {
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  color: var(--color-black);
}
.glimpses-box .maintanance-box {
  min-height: 155px !important;
}
.glimpses-box .maintance-img {
  height: 155px;
}
.glimpses-box .maintanance-box .maintance-text {
  padding: 12px;
}
.company-event-box-img {
  position: absolute;
  content: "";
  display: flex;
  top: 3px;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.joinee-box {
  border-radius: 10px;
  background: var(--white, #fff);
  box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.1);
}
.joinee-img {
  height: 160px;
  width: 160px;
  overflow: hidden;
  margin: 0 auto;
}
.joinee-img img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 100px;
}
.joinee-box-part1 {
  padding: 40px 25px 15px 18px;
}
.joinee-name h5 {
  color: var(--color-black);
  font-weight: 500;
  text-align: center;
}
.joinee-line {
  border-top: 1px solid rgba(123, 123, 123, 0.2);
}
.joinee-position h5 {
  color: var(--color-primary);
  font-size: 16px;
  font-weight: 400;
}
.our-hiring-text-dese_s1 h3 {
  color: var(--color-secondary);
  font-weight: 500;
  margin-bottom: 10px;
  font-size: 24px;
  line-height: 30px;
}
.ss-technology-content h3 {
  font-size: 24px;
  line-height: 30px;
  color: #222b24;
}
.celebration-img {
  height: 230px;
  width: 100%;
  overflow: hidden;
  margin-bottom: 15px;
}
.celebration-img img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 10px;
}
.arrow-btn-gray .circle,
button.learn-more.arrow-btn-gray .circle {
  border: 1.3px solid var(--color-secondary-gray);
}
button.learn-more.arrow-btn-gray .circle {
  height: 32px;
  width: 32px;
}
button.learn-more.arrow-btn-gray {
  width: 70px !important;
}
.ai-service-list .services-in-action-main:after {
  background-image: url(../images/ai-bg.png) !important;
  right: -100px;
}
.services-in-action .ss-heading h2 {
  color: var(--color-secondary);
  font-weight: 400;
  font-size: 30px;
  line-height: 40px;
}
.enhance-content h3 {
  color: var(--color-title);
  font-weight: 500;
  font-size: 24px;
  line-height: 30px;
}
.ai-technology-main.technology-box-main {
  border-right: unset !important;
}
.empowerment-text h5 {
  color: var(--color-title);
  font-weight: 500;
  border-bottom: 1px solid var(--color-title);
  display: inline-block;
}
.enpowerment-img img,
.newsletter-monthimg img {
  border-radius: 15px;
  width: 100%;
}
.enpowerment-box {
  position: absolute;
  box-shadow: 0 4px 17px 0 rgba(0, 0, 0, 0.05);
  background: var(--color-secondary-white);
  padding: 15px 20px;
  text-align: center;
  top: 22%;
  left: 0;
  right: 0;
  display: inline-table;
  border-radius: 10px;
  margin: 0 auto;
}
.enpowerment-box p {
  font-weight: 400;
  color: var(--color-title);
}
ul.social li {
  right: -11px;
  top: 150px;
  list-style: none;
}
.call {
  background-color: #001b50;
  padding: 5px 15px;
  font-size: 25px;
  color: #fff;
  border-radius: 5px 0 0 5px;
  margin-right: -125px;
  margin-bottom: 15px;
}
.call:hover {
  box-shadow: 0 15px 35px rgba(58, 179, 231, 0.4);
}
.joinee-box {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
}
.company-event-tab .sans-culture-mem {
  height: 160px;
  width: 100%;
}
.joinee-img {
  background-color: #f4f5f9;
  border-radius: 100px;
}
.blog-loader-main {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}
.blog-loader {
  padding: 20px;
  border-radius: 5px;
}
#outline {
  stroke-dasharray: 2.42777px, 242.77666px;
  stroke-dashoffset: 0;
  -webkit-animation: 1.6s linear infinite anim;
  animation: 1.6s linear infinite anim;
}
@-webkit-keyframes anim {
  12.5% {
    stroke-dasharray: 33.98873px, 242.77666px;
    stroke-dashoffset: -26.70543px;
  }
  43.75% {
    stroke-dasharray: 84.97183px, 242.77666px;
    stroke-dashoffset: -84.97183px;
  }
  100% {
    stroke-dasharray: 2.42777px, 242.77666px;
    stroke-dashoffset: -240.34889px;
  }
}
@keyframes anim {
  12.5% {
    stroke-dasharray: 33.98873px, 242.77666px;
    stroke-dashoffset: -26.70543px;
  }
  43.75% {
    stroke-dasharray: 84.97183px, 242.77666px;
    stroke-dashoffset: -84.97183px;
  }
  100% {
    stroke-dasharray: 2.42777px, 242.77666px;
    stroke-dashoffset: -240.34889px;
  }
}
.check-out {
  position: absolute;
  bottom: 0.5rem;
  left: 50%;
  transform: translateX(-50%);
  font-size: 2rem;
  color: #001b50;
}
.portfolio-grid-img {
  height: 650px;
}
.newsletter-monthimg {
  border-radius: 10px;
  position: relative;
}
.newsletter-imgtext {
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 30px;
  display: inline-block;
  background-color: var(--color-white);
  border-radius: 15px 0;
}
.custom-carousel .item.active .item-desc {
  transform: none;
  -webkit-transform: none;
}
.custom-carousel {
  display: flex;
  justify-content: center;
}
.custom-carousel .item {
  margin: 0 8px;
  width: 80px;
  height: 500px;
  display: flex;
  display: -webkit-flex;
  align-items: flex-end;
  -webkit-align-items: flex-end;
  background: center center/cover no-repeat;
  border-radius: 15px;
  overflow: hidden;
  position: relative;
  transition: 0.4s ease-in-out;
  -webkit-transition: 0.4s ease-in-out;
  cursor: default;
}
.custom-carousel .item:after {
  content: "";
  display: block;
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  background: linear-gradient(
    180deg,
    rgba(0, 27, 80, 0.1) 0,
    rgba(0, 27, 80, 0.9) 100%
  );
}
.custom-carousel .item::before {
  content: attr(data-step);
  display: block;
  position: absolute;
  height: 25px;
  width: 25px;
  top: 17px;
  left: 0;
  right: 0;
  border-radius: 50px;
  margin: 0 auto;
  text-align: center;
  color: #fff;
  background-color: #001b50;
  line-height: 25px;
}
.custom-carousel .item.active::before {
  margin: 0 0 0 24px;
}
.custom-carousel .item-desc {
  padding: 0 24px 12px;
  color: #fff;
  position: relative;
  z-index: 1;
  overflow: hidden;
  transform: translateY(calc(100% - 54px));
  -webkit-transform: translateY(calc(100% - 54px));
  transition: 0.4s ease-in-out;
  -webkit-transition: 0.4s ease-in-out;
}
.custom-carousel .item h4 {
  transform: rotate(-90deg);
  position: absolute;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: var(--color-white);
  z-index: 9;
  left: 0;
  right: 0;
  bottom: 44px;
  white-space: nowrap;
  display: block;
  transition: 0.4s ease-in-out 0.2s;
  -webkit-transition: 0.4s ease-in-out 0.2s;
}
.custom-carousel .item-desc p {
  opacity: 0;
  -webkit-transform: translateY(32px);
  transform: translateY(32px);
  transition: 0.4s ease-in-out 0.2s;
  -webkit-transition: 0.4s ease-in-out 0.2s;
  font-weight: 400;
  color: var(--color-secondary-white);
}
.custom-carousel .item-desc h3 {
  opacity: 0;
  -webkit-transform: translateY(32px);
  transform: translateY(32px);
  transition: 0.4s ease-in-out 0.2s;
  -webkit-transition: 0.4s ease-in-out 0.2s;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  color: var(--color-white);
  margin-bottom: 10px;
}
.choose-comp-dese h3,
.why-choose-us-blog .our-chosse-blog h2 {
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
}
.choose-comp-dese h3,
.choose-comp-dese h5,
.choose-comp-dese h6,
.choose-comp-dese p,
.why-choose-us-blog .our-chosse-blog h2 {
  color: var(--color-secondary-white);
}
.custom-carousel .item.active {
  width: 720px !important;
  box-shadow: 12px 40px 40px rgba(0, 0, 0, 0.25);
  -webkit-box-shadow: 12px 40px 40px rgba(0, 0, 0, 0.25);
}
.btn-close:focus,
.flip_card_about_goal:first-child {
  box-shadow: none;
}
.custom-carousel .item.active .item-desc h3,
.custom-carousel .item.active .item-desc p {
  opacity: 1;
  -webkit-transform: translateY(0);
  transform: translateY(0);
}
.custom-carousel .item:hover::after {
  background-image: linear-gradient(
    0deg,
    rgba(0, 27, 80, 0.6),
    rgba(0, 27, 80, 0.6)
  );
}
.custom-carousel .item:hover::before,
.enhance-content-main {
  z-index: 9;
}
.custom-carousel .item .culture-title,
.custom-carousel .item .cuture-heading h2,
.custom-carousel .item .process-hide,
.newsletter_events_slider .owl-theme .owl-nav .disabled,
.parent_active_tab.nav-link.active::after {
  opacity: 0;
}
.custom-carousel .item:first-child {
  margin-left: 0;
}
.line-croess {
  position: relative;
  padding: 20px 0;
}
.line-croess::after {
  content: "";
  position: absolute;
  width: 30%;
  height: 1px;
  background-color: #fff;
  border-radius: 10px;
}
.delivering-box.border::after {
  position: absolute;
  top: 50%;
  left: -1px;
  width: 2px;
  height: 24px;
  background-color: var(--color-secondary);
  content: "";
  transform: translate(-50%, -50%);
  opacity: 0;
}
.delivering-box:hover.border::after {
  opacity: 1;
}
.our-com-hover-image {
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  transition: 0.5s;
}
.our-com-hover-image::before,
.ss_hover_sub .column.active .bg::after {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  background: rgba(0, 58, 220, 0.6);
  top: 0;
}
.why-choose-us-blog {
  border: 1px solid rgba(255, 255, 255, 0.2);
  display: flex;
  flex-direction: column;
  padding: 30px;
  height: 300px;
  transition: 0.9s;
  position: relative;
  overflow: hidden;
}
.why-choose-us-blog:hover .subdetails-of-com {
  height: 100%;
  background-color: rgba(0, 58, 220, 0.85);
  padding-top: 40px;
}
.why-choose-us-blog:hover .our-chosse-blog h5 {
  opacity: 0;
  transition: 0.9s;
}
.why-choose-us-blog:hover .our-chosse-blog h2{
  display: none;
}
.why-choose-us-blog .our-chosse-blog h5 {
  font-weight: 500;
  color: var(--color-secondary-white);
}
.choose-comp-dese h3 {
  margin-bottom: 15px;
  width: 100%;
}
.choose-comp-dese p {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}
.subdetails-of-com {
  position: absolute;
  bottom: 0;
  height: 0;
  width: 100%;
  left: 0;
  right: 0;
  transition: 0.9s;
  padding: 0 30px;
}
.col-lg-4:nth-of-type(2) .why-choose-us-blog,
.col-lg-4:nth-of-type(5) .why-choose-us-blog {
  border-left: unset;
  border-right: unset;
}
.col-lg-4:nth-child(-n + 3) .why-choose-us-blog {
  border-bottom: none;
  border-top: none;
}
.col-lg-4:nth-last-child(-n + 3) .why-choose-us-blog {
  border-bottom: none;
}
.choose-comp-dese h5 {
  font-weight: 500;
  width: 100%;
  margin-bottom: 15px;
}
.choose-comp-dese h6 {
  font-weight: 400;
}
.choose-comp-dese {
  display: flex;
  height: 300px;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
.ss_hover_sub .column .content {
  position: relative;
  height: 100%;
  z-index: 9;
}
.ss_hover_sub .column .bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}
/* .ss_hover_sub .column.active .bg.bg1 {
  background: url(../images/choosemain.jpg) center center/cover no-repeat;
  transition: 0.5s;
}
.ss_hover_sub .column.active .bg.bg2 {
  background: url(../images/choose2.jfif) center center/cover no-repeat;
  transition: 0.5s;
}
.ss_hover_sub .column.active .bg.bg3 {
  background: url(../images/choose3.jpg) center center/cover no-repeat;
  transition: 0.5s;
}
.ss_hover_sub .column.active .bg.bg4 {
  background: url(../images/choose4.jpg) center center/cover no-repeat;
  transition: 0.5s;
}
.ss_hover_sub .column.active .bg.bg5 {
  background: url(../images/choose5.jpg) center center/cover no-repeat;
  transition: 0.5s;
}
.ss_hover_sub .column.active .bg.bg6 {
  background: url(../images/choose6.jpg) center center/cover no-repeat;
  transition: 0.5s;
} */
.our_talent_and_dream {
  background: linear-gradient(180deg, #fff 0, rgba(255, 255, 255, 0) 35.94%);
}
.our_talent_and_dream .container {
  background-image: url(../images/career_bg.png);
  background-repeat: no-repeat;
  background-position: bottom center;
}
.careere-main-images {
  background: #fff;
  box-shadow: 0 2px 20px 3px rgba(38, 38, 38, 0.05);
  padding-bottom: 20px;
  transition: 0.5s linear;
}
.department_of_job,
.job_description_com {
  background-color: rgba(255, 255, 255, 0.7);
  border-radius: 30px;
  height: 100%;
}
.careere-main-images:hover {
  background: var(--color-primary);
  scale: 1.03;
}
.sans-culture-mem img {
  width: 100%;
  padding: 10px 12px 20px;
}
.careere-main-images p {
  text-align: center;
  font-weight: 400;
  color: var(--color-secondary2);
  font-size: 14px;
}
.ss_sens_culture_main .careere-main-images:nth-child(2) {
  translate: 0px -102px;
  transform: rotate(-11deg);
}
.ss_sens_culture_main .careere-main-images:nth-child(3) {
  translate: 0px 40px;
  transform: rotate(10deg);
}
.ss_sens_culture_main .careere-main-images:nth-child(4) {
  translate: 0px -55px;
  transform: rotate(-3deg);
}
.ss_sens_culture_main .careere-main-images:nth-child(5) {
  transform: rotate(6deg);
}
.our_cluture_video_head h2 {
  color: var(--color-secondary);
  text-align: center;
  font-size: 18px;
  line-height: 28px;
  font-weight: 500;
}
.our_cluture_video_head p {
  font-weight: 400;
  color: var(--color-secondary2);
  text-align: center;
}
.our_cluture_video_img img {
  width: 100%;
  border-radius: 20px;
}
.job_opninig_ourcom {
  background-image: url(../images/bg-multi.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
}
.department_of_job .nav {
  padding: 20px 15px;
  row-gap: 15px;
}
.department_of_job .nav-pills .nav-link {
  border-radius: 20px;
  color: var(--color-secondary2);
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  padding: 14px 0 14px 14px;
}
.department_of_job .nav-pills .nav-link span {
  font-weight: 600;
  font-size: 14px;
  line-height: 29px;
  height: 27px;
  width: 27px;
  background: #f7f9fc;
  border-radius: 100%;
  display: inline-block;
  text-align: center;
  margin-left: 10px;
  color: var(--color-secondary2);
}
.department_of_job .nav-pills .nav-link.active span {
  background: var(--color-primary-yellow);
}
.department_of_job .nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  background-color: var(--color-secondary);
  color: var(--color-white);
}
.job_description_com {
  padding: 18px;
  overflow-y: hidden;
}
.sub_details_of_job {
  padding: 20px;
  border-bottom: 0.5px solid rgba(123, 123, 123, 0.2);
}
.sub_details_of_job h3 {
  font-size: 18px;
  line-height: 28px;
  color: var(--color-secondary2);
  font-weight: 500;
}
.details_of_software h2,
.loogin_design1 h2,
.loogin_design1 p {
  font-weight: 400;
  color: var(--color-white);
}
.details_of_software p{
  color: var(--color-white);
}
.details_of_software .yellow_text{
  color: var(--color-primary-yellow);
}
.details_of_software h1{
  color: var(--color-white);
  font-weight: 400;
}
.form-title p {
  color: var(--color-secondary);
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
}
.sub_details_of_job h5 {
  color: var(--color-secondary2);
}
.content_job_style p:nth-child(2) {
  font-weight: 500;
  color: var(--color-secondary);
  margin-bottom: 0;
}
.main_job_description,
.related_job_opninig > h5,
.related_job_opninig_s2 {
  border-bottom: 0.5px solid var(--color-secondary-border);
}
/* .main_job_description h2{
    font-size: 28px;
    line-height: 38px;
    color: var(--color-secondary);

} */
 
.our-hiring-text-main {
  background: #e5ebfe;
  border-radius: 90px;
  padding: 0 20px;
}
.our-hiring-img {
  height: 143px;
  width: 210px;
  background: #fff;
  border-radius: 90px;
  text-align: center;
  line-height: 143px;
  margin-right: 25px;
  translate: 0px -23px;
}
.our-hiring-text-dese p {
  padding-inline-end: 150px;
}
.core-value-section .slick-slide img {
  display: unset;
}
.core-value-section .items {
  margin: 25px 0;
  position: relative;
}
.core-value-section .slick-slide .items {
  transform: scale(0.8);
  /* opacity: 0.3; */
  transition: 0.5s;
}
.core-value-section .slick-slide.slick-current .items,
.core-value-section .slick-slide.slick-target .items {
  transform: scale(1);
  opacity: 1;
  transition: 0.5s;
}
.looking_all_design {
  background: var(--color-secondary);
  border-radius: 20px;
  padding: 40px 30px;
}
.loogin_design1 h2 {
  font-size: 35px;
  line-height: 50px;
}
.loogin_design1 h2 span {
  font-weight: 600;
  color: var(--color-white);
}
.loogin_design1 p {
  padding-top: 10px;
}
.our_looking_process {
  margin-top: -105px;
}
.com_culture_memories img {
  transition: 1s linear;
  border-radius: 10px;
}
.our_culture_memories li:hover img {
  filter: grayscale(100%);
  scale: 1.15;
  border-radius: 10px;
}
.our_culture_memories {
  margin: 0 15px 0 0;
}
.cloud {
  animation: 60s linear infinite drift;
  position: relative;
  overflow: hidden;
}
.sens_details_of_subcarrer {
  background-color: var(--color-primary);
  padding-bottom: 300px;
  background-repeat: no-repeat;
  background-position: right 20% top -20%;
  position: relative;
}
.details_of_software h2 {
  padding: 8px 0 12px;
}
.ss_job_details_about {
  background: #fff;
  border-radius: 10px;
  position: relative;
  margin-top: -200px;
}
.related_job_opninig {
  border-left: 0.5px solid var(--color-secondary-border);
  height: 100%;
}
.about_sub_job_desc .col-lg-8 {
  padding-right: 0;
}
.Sub_points_blogs li,
.about_sub_job_desc .col-lg-4 {
  padding-left: 0;
}
.main_job_description h2,
.related_job_opninig > h5 {
  padding: 20px 30px;
  color: var(--color-secondary);
}
.main_job_description h2{
  font-weight: 400;
  font-size: 28px;
  line-height: 38px;
}
.related_job_opninig h3{
  color: var(--color-secondary);
  padding: 20px 30px;
  line-height: 38px;
    font-weight: 400;
  border-bottom: .5px solid var(--color-secondary-border);
  font-size: 18px;
}
.related_job_opninig_s2 h4{
  font-size: 18px;
  line-height: 28px;
  font-weight: 500;
  color: var(--color-secondary);
}
.ss_sens_about_comdese span{
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: var(--color-secondary);
  margin-bottom: 10px;
}
.related_job_opninig > h5 {
  line-height: 30px;
}
.role_of_software {
  padding: 20px 0;
}
.role_of_software p.sm-text-semibold {
  color: var(--color-secondary);
  padding: 5px 15px;
  background: var(--color-white);
  border: 0.9375px solid var(--color-light-gray);
  border-radius: 29.0625px;
  line-height: unset;
  display: inline-block;
  margin: 5px 0;
}
.main_job_description_part2 {
  padding: 0 30px;
}
.ss_sens_about_comdese h6 {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: var(--color-secondary);
  margin-bottom: 10px;
}
.ss_sens_about_comdese p {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: var(--color-secondary-gray);
}
.related_job_opninig_s2 {
  padding: 20px 10px 20px 30px;
  transition: 0.9s;
}
.info_of_ceo h6,
.related_job_opninig_s2 h5 {
  font-weight: 500;
  color: var(--color-secondary);
}
.all_heading_wise_bolg_change,
.blog_wrapper_alldet p,
.main_casestudy_after .portfolio-text h6,
.related_job_opninig_s2 p {
  font-weight: 400;
  color: var(--color-secondary);
}
.portfolio-text h3 {
  line-height: 24px;
  color: var(--color-white);
}
.related_job_opninig_s2 p span {
  color: var(--color-secondary-gray);
  font-size: 12px;
  line-height: 18px;
}
.apply_dese_btn button.learn-more .circle {
  border: 1.3px solid var(--color-primary);
}
.related_job_opninig_s2:hover {
  background: linear-gradient(90deg, #f4f5f9 0, rgba(244, 245, 249, 0) 64.45%);
}
.hiring_description_s1 {
  display: flex;
  padding: 25px;
}
.our-hiring-img_s1 {
  height: 40px;
  width: 40px;
}
.our-hiring-text-dese_s1 h3 {
  font-weight: 500;
  font-size: 24px;
    line-height: 30px;
  color: var(--color-secondary);
  margin-bottom: 10px;
}
.ss_benififts_section .col-lg-4:nth-child(3n-1) {
  border-left: 0.5px solid var(--color-secondary-border);
  border-right: 0.5px solid var(--color-secondary-border);
}
.ss_benififts_section .col-lg-4:nth-of-type(n + 4):nth-of-type(-n + 6) {
  border-top: 0.5px solid var(--color-secondary-border);
  border-bottom: 0.5px solid var(--color-secondary-border);
}
.ss_benififts_section .col-lg-4:hover {
  background: linear-gradient(
    180deg,
    rgba(0, 58, 220, 0) 0,
    rgba(0, 58, 220, 0.1) 75.47%
  );
}
.Blog_heading_section h6::after,
.offcanvas,
.ss-link.nav-link.active::after,
.testimonials .testimonials-box-s1:hover {
  background-color: var(--color-white);
}
.social_apply_icon i {
  height: 30px;
  width: 30px;
  display: inline-block;
  border: 0.9375px solid var(--color-light-gray);
  color: var(--color-light-gray);
  text-align: center;
  line-height: 30px;
  background: #fff;
  border-radius: 50px;
  transition: 0.2s;
  font-size: 12px;
}
.social_apply_icon i:hover {
  color: var(--color-secondary);
  border: 0.9375px solid var(--color-secondary);
}
.social_apply_icon span {
  font-weight: 400;
  color: var(--color-secondary-gray);
  margin-right: 10px;
}
.testimonials-box-s1 {
  background: rgba(255, 255, 255, 0.2);
  border: 1px solid var(--border-color);
  backdrop-filter: blur(25px);
  border-radius: 20px;
  height: 100%;
  transition: 0.2s;
}
.blog-text h5 {
  height: 56px;
  overflow: hidden;
}
.blog_menegment_section img {
  border-radius: 500px;
}
.blog_section_s1main {
  background: #fff;
  box-shadow: 0 13.9377px 23.2295px rgba(0, 0, 0, 0.06);
  border-radius: 200px;
  display: flex;
  width: 190px;
  height: 75px;
  padding-left: 20px;
  position: absolute;
  top: 40px;
  left: 40px;
}
.camara_section_of_img {
  height: 165px;
  width: 165px;
  border-radius: 100%;
  background: #c4c4c4;
  border: 0.742665px solid #c4c4c4;
  position: absolute;
  right: 15%;
  translate: -15% -80%;
}
.round_after::after {
  content: "";
  position: absolute;
  height: 240px;
  width: 240px;
  border: 0.742665px solid #c4c4c4;
  border-radius: 100%;
  left: 50%;
  top: 50%;
  right: 50%;
  translate: -50% -50%;
}
.camara_section_of_img img,
.ceo_of_image img {
  height: 100%;
  width: 100%;
  border-radius: 100%;
  object-fit: cover;
}
.blog_section_s2 p {
  color: #5a7184;
  font-weight: 400;
}
.blog_section_s2 h5 {
  color: #183b56;
  font-family: 500;
  text-align: start;
}
.parent_slidepanal_mainblog,
.sub_detaiils_blog_sidepanal {
  position: sticky;
  top: 100px;
}
.blog_sub_mainhead .social_apply_icon i,
.innvoation_ideas_about .accordion-header:hover button h5,
.innvoation_ideas_about .accordion-header:hover button h6,
.sub_details_blog_sidepanel_search li.active {
  color: #001b50;
}
.delivering-box:hover .delivering-ic img {
  transform: unset;
}
.delivering-box {
  padding: 25px 25px 40px;
}
.testimonials-box-s1 {
  padding: 25px 25px 60px;
}
.testimonials-box-main .owl-nav .owl-next,
.testimonials-box-main .owl-nav .owl-prev {
  font-size: 28px !important;
  border: 1px solid #888 !important;
}
.our_case_study_logo {
  position: absolute;
  right: 32px;
  top: 35px;
  -webkit-animation-name: rotate;
  animation-name: rotate;
  animation-duration: 9s;
  -o-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}
.main_casestudy_after .portfolio-box-main .d-flex.flex-column {
  row-gap: 25px;
}
.blog-slider::after {
  z-index: -9;
}
.core-value-img {
  height: unset;
  width: unset;
}
.maintanance-hover h6 {
  font-weight: 400;
  margin-top: 10px;
}
.ss_main_vison_box.vision-label1 {
  right: 21px;
}
.testimonials-box-main .owl-theme .owl-nav [class*="owl-"]:hover {
  border-color: var(--color-primary) !important;
}
.footer-links li a,
body .magic-hover {
  transition: 0.2s;
}
::-webkit-scrollbar {
  width: 3px;
  height: 7px;
}
::-webkit-scrollbar-thumb {
  background: var(--color-secondary);
}
::placeholder {
  color: var(--color-secondary-gray) !important;
}
.apply_job_modal .modal-footer {
  border-top: unset;
}
.apply_job_modal .modal-content {
  padding: 10px 10px 30px;
}
.Apply-job-form {
  background: #f4f5f9;
}
.apply_job_modal .custom-select .css-13cymwt-control {
  border: 1px solid #e3e3e3;
}
.timeline ul li:nth-child(2n) span,
.timeline ul li:nth-child(odd) span {
  transform: translate(10px, -50%);
  background: #000;
  color: #fff;
  padding: 3px 13px;
  line-height: 25px;
  border-radius: 14px;
  position: absolute;
  top: 50%;
}
.Apply-job-form .file-upload {
  text-align: center !important;
  color: var(--color-secondary-gray) !important;
  font-weight: 400;
}
#content,
.Apply-job-form .custom-file p,
.process-name,
.process-round,
.requiremnt-img {
  text-align: center;
}
.Apply-job-form .custom-file,
.Apply-job-form .doc-text {
  text-align: center;
  color: var(--color-secondary-gray) !important;
  font-weight: 400;
}
.Apply-job-form .custom-input {
  padding: 20px;
  margin-bottom: 0;
}
.blog-section .ss-blog-box-main:hover {
  background-color: #eff1f5 !important;
}
.timeline ul li:nth-child(2n) span {
  left: -40px;
}
.timeline ul li:nth-child(odd) span {
  right: -20px;
}
.signature-img img {
  height: 60px;
}
.Blog_heading_section h6 {
  font-weight: 400;
  color: var(--color-white);
  position: relative;
  line-height: 30px;
  padding-left: 15px;
}
.Blog_heading_section h6::after {
  content: "";
  position: absolute;
  height: 100%;
  width: 3px;
  left: 0;
  border-radius: 5px;
}
.Blog_heading_section h6 span {
  margin: 0 15px;
  color: #92aaed;
}
.main_subblog_details .ss-blog-author {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: var(--color-white);
}
.main_subblog_details .ss-blog-date {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: var(--color-white);
}
.Blog_heading_section h2 {
  padding: 25px 0;
}
.navbar-toggler-icon {
  background-image: url(../images/toggeler.svg);
}
.offcanvas-header .btn-close {
  background-image: url(../images/close.svg);
  opacity: 1;
}
.blog_wise_add_images img {
  width: 100%;
  margin-bottom: 20px;
}
.blog_wrapper_alldet h6 {
  font-size: 16px;
  line-height: 24px;
  color: var(--color-secondary);
  margin-bottom: 20px;
}
.blog_wrapper_alldet ul li {
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: var(--color-secondary);
  margin-bottom: 5px;
  padding-left: 30px;
}
.blog_wrapper_alldet ul {
  margin-bottom: 20px !important;
}
.diff_diff_tecnology ul,
.why_us_points .blog_point {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.blog_wrapper_alldet p span {
  font-weight: 500;
  color: var(--color-primary);
}
.comman_form ::placeholder {
  color: rgba(0, 0, 0, 0.8) !important;
}
.custom-input.comman_form .form-control {
  padding-left: 38px;
  height: 45px;
  border-radius: 20px;
}
.arrow_of_footer {
  position: absolute;
  border: transparent;
  top: 50%;
  transform: translate(0, -50%);
  background: 0 0;
  left: 10px;
}
.active_of_table h6 {
  font-weight: 600;
  font-size: 15px;
  line-height: 22px;
  color: var(--color-secondary);
}
.diff_diff_tecnology ul {
  column-gap: 5px;
  row-gap: 8px;
  display: flex;
  flex-wrap: wrap;
}
.diff_diff_tecnology li {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: var(--color-secondary);
  background: #fff;
  border: 0.9375px solid #c9c9c9;
  border-radius: 29.0625px;
  display: inline-block;
  padding: 10px 20px;
}
.blog_social_icon .social_apply_icon i:hover,
.diff_diff_tecnology li:hover {
  color: var(--color-primary);
  border: 0.9375px solid var(--color-primary);
}
.blog_social_icon .social_apply_icon i {
  height: 48px;
  width: 48px;
  line-height: 48px;
  font-size: 20px;
}
.points_of_description p {
  position: relative;
  margin-left: 20px;
}
.points_of_description p::before {
  content: "\f105";
  position: absolute;
  left: -20px;
  top: 0;
  color: #4a4a4a;
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  font-family: FontAwesome;
  font-size: 20px;
}
.blog_table {
  padding: 15px 0;
}
.blog_table table td {
  font-weight: 400 !important;
  color: var(--color-secondary-gray) !important;
  font-size: 14px !important;
}
.blog_wrapper_alldet table th {
  font-weight: 600;
  color: var(--color-secondary-gray);
  font-size: 14px;
}
.software-solution::after {
  top: 50%;
  transform: translate(0, -50%);
  z-index: -9;
  background: linear-gradient(
    270deg,
    rgba(0, 58, 220, 0.1) 0,
    rgba(0, 58, 220, 0.015625) 97%,
    rgba(0, 58, 220, 0) 98.54%
  );
  width: 27%;
  height: 100%;
}
.partner-text-main .wrapper .float-image {
  margin: 10px 10px 10px 30px;
}
.partner-text-main .wrapper .float-image.right {
  float: right;
  shape-outside: inset(-9% round 50%);
}
.partner-text-main .wrapper p {
  font-weight: 400;
  font-size: 14px;
  line-height: 27px;
  color: var(--color-secondary-gray);
  text-align: justify;
}
.about-social-img {
  display: flex;
  column-gap: 15px;
}
.ss-certified .about-social-img a:hover img {
  filter: brightness(0.8) contrast(13.2);
}
.meeting_land_why {
  background-color: #001b50;
  border-radius: 50px 50px 0 0;
}
.why_us_points .blog_point li::after {
  background-image: url(../images/white-tick.svg);
}
.why_us_points li {
  color: var(--color-white);
  font-size: 14px;
  line-height: 22px;
  margin-top: 15px;
  font-weight: 400;
}
.head_of_title_why h2,
.metting_yellow_card .head_of_title_why h2 {
  font-size: 70px;
  font-family: Poppins, sans-serif;
  line-height: 80px;
  font-weight: 800;
}
.head_of_title_why h2 {
  -webkit-text-stroke-width: 0.5px;
  -webkit-text-stroke-color: #fff;
  color: #0c2961;
  margin-bottom: 20px;
}
.after_add_com_logo:after {
  position: absolute;
  content: "";
  background-image: url(../images/metting-logo.svg);
  height: 100%;
  width: 100px;
  background-repeat: no-repeat;
  top: -75px;
  display: none;
}
.core-value-box .core-value-img,
.online_links li a,
body #magicPointer.pointer-blur {
  height: 50px;
  width: 50px;
}
.our_metting_memories .web-build-box2.maitanance-box2 {
  bottom: 20px;
}
.our_metting_memories .web-build-box2 {
  right: unset;
}
.metting_yellow_card {
  background-color: var(--color-primary-yellow);
  border-radius: 50px 50px 0 0;
}
.metting_yellow_card .head_of_title_why h2 {
  color: #fff;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #001b50;
  color: var(--color-primary-yellow);
}
.chlid_tab_box .nav-link,
.parent_active_tab.nav-link {
  font-size: 14px;
  line-height: 22px;
  font-weight: 400;
  color: var(--color-secondary2);
}
.metting_yellow_card .after_add_com_logo:after {
  background-image: url(../images/metting-logo2.svg);
}
.metting_yellow_card .why_us_points .blog_point li::after {
  background-image: url(../images/black-tick.svg);
}
.tecnology_hirededicate h3 {
  color: var(--color-secondary);
  font-weight: 400;
  margin: 15px 0 5px;
  font-size: 24px;
  line-height: 30px;
}
.our-facility-inner-div p,
.pricing-list-detail p {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}
.head_of_main_metting h1,
.head_of_main_metting h2 {
  font-size: 107px;
  color:rgba(255,255,255,0.2);
  line-height: 135px;
  padding-bottom: 40px;
  font-weight: 500;
  text-align: center;
}
.innvoation_ideas_about .accordion-button::after {
  content: unset;
}
.innvoation_ideas_about .accordion-button:not(.collapsed) {
  background-color: transparent;
  border: 1px solid var(--color-primary);
  border-radius: unset;
}
.innvoation_ideas_about .accodion_section_2 .accordion-body,
.innvoation_ideas_about .accodion_section_3 .accordion-body,
.innvoation_ideas_about .accordion_section_1 .accordion-body {
  background-color: #001b50;
  background-repeat: no-repeat;
  background-size: cover;
  padding-bottom: 30px;
}
.innvoation_ideas_about .accordion-button {
  justify-content: space-between;
  min-height: 90px;
}
.innvoation_ideas_about .accordion-item {
  border-radius: 0 !important;
  border-color: #c6c6c6 !important;
}
.innvoation_ideas_about .accordion_section_1 .accordion-body {
  background-image: url(../images/team-metting1.png);
}
.innvoation_ideas_about .accodion_section_2 .accordion-body {
  background-image: url(../images/team-metting2.png);
}
.innvoation_ideas_about .accodion_section_3 .accordion-body {
  background-image: url(../images/team-metting3.png);
}
.Sub_points_blogs li::after,
.logo_design_portfolio .portfolio-box-main::after {
  display: none !important;
}
#el {
  height: 620px;
  overflow-y: scroll;
}
#el::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 4px;
  background-color: #f0f0f0 !important;
}
#el::-webkit-scrollbar-thumb {
  background-color: #d3defc;
}
.ss-technology-box {
  flex-wrap: wrap;
}
.pagination_change_btn button.learn-more.btn-large {
  width: 250px !important;
}
.pagination_change_btn button.learn-more {
  width: 130px !important;
}
.blog-section .testimonials-box-main .owl-carousel .owl-nav {
  top: -72px;
}
.about_ceo_box {
  background: #fff;
  box-shadow: 0 4px 40px rgba(11, 153, 246, 0.06);
  border-radius: 5px;
  padding: 25px;
}
.comment_box .ceo_of_image {
  height: 60px;
  width: 60px;
}
.ceo_of_image {
  height: 89px;
  width: 89px;
  position: relative;
  overflow: hidden;
  margin-right: 15px;
}
.info_of_ceo h6 span {
  color: #6a7585;
  font-size: 12px !important;
  line-height: 24px;
  margin-left: 5px;
}
.comment_box {
  margin: 8px 0;
}
.number-box {
  position: sticky;
  bottom: 0;
  left: 50%;
  top: 50%;
  transform: translateX(-50%);
  font-size: 13px;
  z-index: 9;
  display: inline-block;
  background: #000;
  color: #fff;
  padding: 5px 13px;
  line-height: 19px;
  border-radius: 14px;
}
.full-image,
.full-image2 {
  left: 0;
  position: absolute;
}
.flip_card_about_goal,
.flip_card_about_goal:first-child {
  background-image: url(../images/services-bg.png);
  background-size: cover;
  background-repeat: no-repeat;
}
.flip-card {
  width: auto;
  height: 100%;
  perspective: 1000px;
}
.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  transform-style: preserve-3d;
  animation: 7s infinite flip;
}
.flip-card-back,
.flip-card-front {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
}
.flip-card-front {
  animation: 1s 2s infinite upDown;
}
.flip-card-back {
  transform: rotateY(180deg);
}
@keyframes flip {
  0% {
    transform: rotateY(0);
  }
  50% {
    transform: rotateY(180deg);
  }
  100% {
    transform: rotateY(360deg);
  }
}
@keyframes upDown {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
}
.flip_card_about_goal .vision-label1 h5,
.flip_card_about_goal .vision-label2 h5,
.flip_card_about_goal .vision-label3 h5 {
  font-size: 14px;
  line-height: unset;
}
.flip_card_about_goal .focused-text h5 {
  font-size: 15px;
  line-height: 22px;
}
.flip_card_about_goal .vision-box {
  padding: 10px 20px;
}
.change_vision_img1 img {
  border-radius: 18px;
}
.flip_card_about_goal {
  box-shadow: 0 -16px 45px rgb(0 58 220 / 15%);
  position: sticky;
  top: 80.5px;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.full-image,
.full-image2,
.shape-image {
  height: 100%;
  object-fit: cover;
  width: 100%;
}
.css-13cymwt-control {
  min-height: 55px !important;
}
.btn-sewrvice-get .nav-link {
  padding: 0 !important;
  font-weight: 700;
}
.animate-charcter {
  background-image: linear-gradient(
    -225deg,
    #f9d14b 0,
    #ff9a00 10%,
    #d0de21 20%,
    #4fdc4a 30%,
    #3fdad8 40%,
    #2fc9e2 50%,
    #4b9efd 60%,
    #ae85ff 70%,
    #d05efa 80%,
    #ff54e8 90%,
    #f9d14b 100%
  );
  background-size: 200% auto;
  color: #fff;
  background-clip: text;
  text-fill-color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: 2s linear infinite textclip;
  display: inline-block;
  font-size: 190px;
}
.meeting_land_why,
.metting_yellow_card {
  min-height: 100vh;
  display: flex;
  top: 80.5px;
  background-size: cover;
  background-repeat: no-repeat;
}
@keyframes textclip {
  to {
    background-position: 200% center;
  }
}
svg.tawk-min-chat-icon path {
  fill: #001b50 !important;
}
.meeting_land_why {
  align-items: flex-start;
  justify-content: center;
  position: -webkit-sticky;
  position: sticky;
  background-image: url(../images/bg_metting.png);
}
.metting_yellow_card {
  align-items: flex-start;
  justify-content: center;
  position: -webkit-sticky;
  position: sticky;
  background-image: url(../images/bg_metting_y.png);
}
.meeting_section_of_main {
  position: relative;
  background: #001b50;
}
input::-webkit-inner-spin-button,
input::-webkit-outer-spin-button {
  -webkit-appearance: none !important;
  margin: 0;
}
.blog_comments_query h5 {
  text-transform: capitalize;
  margin-bottom: 20px;
}
.build-text h2,
.build-text span {
  font-size: 18px;
  line-height: 28px;
}
.head_of_main_metting.head_of_main_metting_yellow h2 {
  /* color: #d8ba52; */
  color: rgba(0, 0, 0, 0.2);
}
.btn.disabled,
.btn:disabled,
fieldset:disabled .btn {
  color: var(--color-secondary);
  pointer-events: none;
  background-color: var(--color-primary-yellow);
  border-color: var(--color-primary-yellow);
  opacity: 0.6;
}
.delivering-section .col-lg-4:nth-child(7) .delivering-box.border,
.delivering-section .col-lg-4:nth-child(8) .delivering-box.border {
  border-right: 0 !important;
  border-top: 0 !important;
}
.delivering-section .col-lg-4:nth-child(9) .delivering-box.border {
  border-top: 0 !important;
}
.commna_font_style p {
  color: var(--color-secondary-gray);
  font-weight: 400;
  margin-top: 10px;
}
.main_details_of_newsletter {
  background-color: #eff0f4;
  border-radius: 15px;
  padding: 15px;
}
.first_section_photos img {
  border-radius: 10px;
  width: 100%;
}
.full-image {
  top: 0;
  mask-image: url("../images/newsletter/Union.svg");
  mask-size: cover;
}
.full-image2 {
  top: 0;
  mask-image: url("../images/newsletter/Union2.svg");
  mask-size: cover;
}
.pdf-iframe {
  width: 100%;
  height: 500px;
  border: none;
}
.content_of_vision_mission {
  position: absolute;
  max-width: 78%;
  display: -webkit-box !important;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.newsletter_months_dese {
  background-image: url(../images/newsletter/circle-after.svg);
  background-repeat: no-repeat;
  background-position-x: center;
  background-position-y: top -20px;
  position: relative;
}
.newsletter_months_dese .shape-container::after {
  position: absolute;
  content: "";
  background-color: #001b50;
  height: 65px;
  width: 65px;
  border-radius: 100%;
  left: 30%;
  top: -20px;
}
.newsletter_our_website {
  background-color: #f4f5f9;
}
.comman_idea_style h6 {
  border-radius: 18px;
  background: rgba(255, 255, 255, 0.8);
  box-shadow: 0 31.073728561401367px 80.50920104980469px -14.124421119689941px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(75px);
  position: absolute;
  padding: 10px 20px;
  text-transform: capitalize;
  min-width: 180px;
  width: 180px;
  text-align: center;
}
.current_busi_com h4,
.experience_of_work h4 {
  text-transform: uppercase;
  line-height: 34px;
}
.parent_active_tab.nav-link.active {
  background-color: transparent;
  color: var(--color-secondary2);
  font-weight: 500;
}
.parent_active_tab.nav-link {
  display: flex;
  padding: 15px 1rem;
  position: relative;
}
.chlid_tab_box {
  border-radius: 8px;
  background: #fff;
  box-shadow: 0 4px 14px 0 rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}
.chlid_tab_box .nav-link.active {
  background-color: #f4f5f9 !important;
  color: var(--color-secondary2) !important;
  border-radius: 0;
  font-weight: 500;
}
.build-text h2,
.build-text span {
  font-weight: 700;
}
.chlid_tab_box .nav-link {
  padding-top: 10px;
  padding-bottom: 10px;
}
.team-images-main .our-vision-text h2 {
  color: var(--color-secondary);
  font-size: 18px;
  line-height: 28px;
  text-transform: capitalize;
}
.arrow-image {
  width: 12px;
  height: 12px;
  transition: transform 0.3s;
  position: absolute;
  right: 0;
  align-self: center;
}
.blog-next-btn .learn-more.arrow-btn,
.parent_active_tab.dropdown-active.nav-link.active img,
.rotated {
  transform: rotate(180deg);
}
.parent_active_tab.nav-link::after {
  position: absolute;
  background-color: #e4e4e4;
  height: 1px;
  width: 100%;
  content: "";
  left: 0;
  bottom: 0;
}
.experience_of_work {
  position: absolute;
  bottom: 0;
  right: 20px;
}
.newsletter_months_dese .shape-container::before {
  position: absolute;
  content: "";
  background-color: var(--color-primary-yellow);
  height: 38px;
  width: 38px;
  right: 20%;
  bottom: 50px;
  transform: translate(0, -50%);
  border-radius: 50px;
  z-index: 9;
}
.idea_section1 h6:first-child {
  top: 0;
}
.idea_section1 h6:nth-child(2) {
  top: 0;
  right: 0;
}
.idea_section1 h6:nth-child(3) {
  bottom: 0;
  right: 0;
}
.recommanded_newsletter {
  border-radius: 8px;
  background: #fff;
  padding: 10px 10px 0;
  margin-top: 25px;
}
.recommanded_newsletter h6 {
  padding: 15px 0;
  color: #001b50;
  font-weight: 400;
}
.recommdade_img img {
  width: 100%;
  border-radius: 8px;
}
.current_busi_com {
  position: absolute;
  bottom: 0;
}
.parent_slidepanal_mainblog::after {
  content: "";
  display: block;
  width: 1px;
  height: 100%;
  background-color: #001b50;
}
.innovation-img img {
  border-radius: 10px;
}
.innnovation-section-main .careere-main-images {
  padding-bottom: 0;
}
body #magicMouseCursor {
  width: 35px;
  height: 35px;
  border: 1px solid #608aff;
  border-radius: 50%;
  left: 0;
  top: 0;
  transition: transform 70ms, width 0.3s, height 0.3s;
  pointer-events: none;
}
body #magicMouseCursor.cursor-square {
  border-radius: 0;
}
body #magicPointer {
  top: 0;
  left: 0;
  position: fixed;
  background: #608aff;
  border-radius: 50%;
  pointer-events: none;
  transition: background 0.2s, width 0.2s, height 0.2s, box-shadow 0.2s;
}
body #magicPointer.pointer-blur {
  background: 0 0;
  border: 1px solid #608aff;
  box-shadow: 0 0 15px -5px #608aff;
}
body #magicPointer.pointer-overlay {
  height: 50px;
  width: 50px;
  mix-blend-mode: difference;
  box-shadow: 0 0 15px -5px #608aff;
}
body #magicPointer.is-hover {
  background: red;
}
body .magic-hover:hover {
  cursor: none;
}
.protfolio-detail-main .cms-needed .right-text p::before {
  background-image: url("../images/check.svg");
  height: 25px;
  width: 25px;
  content: "";
  position: absolute;
  left: 0;
  top: 1px;
  background-repeat: no-repeat;
}
.protfolio-detail-main .right-text p {
  padding-left: 40px;
  position: relative;
}
.protfolio-detail-main .portfolio-text p::before {
  content: "";
  position: absolute;
  background-image: url("../images/portfolio-images/check-ic.svg");
  height: 25px;
  width: 25px;
  background-repeat: no-repeat;
  left: 0;
  top: 1px;
}
.protfolio-detail-main .portfolio-text p {
  padding-left: 35px;
  position: relative;
}
.joinee-name h5 {
  height: 55px;
  overflow: hidden;
  font-size: 15px;
  line-height: 22px;
}
.joinee-box-part2 {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  height: 100%;
  padding: 20px 16px;
}
.home-casestudy-wrepper .link-icon-1 img,
.online_links li a,
.pagination-container a,
.requiremnt-img > img {
  display: inline-block;
}
.inquiry-btn,
.link-icon-1 {
  display: inline-block;
  text-align: center;
}
.main_box_of_casestudy {
  border-radius: 10px;
  background: #fff;
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.05);
  padding: 20px;
  position: relative;
  margin-top: -60px;
}
.images_of_casestudy_logo {
  height: 80px;
  width: 80px;
  background-color: #001b50;
  text-align: center;
  line-height: 76px;
  border-radius: 100%;
  border: 1px solid;
}
.case_study_c1_wrapper h4 {
  color: #001b50;
  font-size: 24px;
  font-weight: 500;
}
.case_study_c1_wrapper p,
.case_study_c2_wrapper h6 {
  color: var(--color-black);
  font-size: 14px;
  font-weight: 500;
}
.case_study_c2_wrapper h6 {
  font-style: normal;
  text-transform: uppercase;
}
.case_study_c2_wrapper h6 span {
  color: #7b7b7b;
  font-size: 24px;
  font-weight: 600;
  text-transform: uppercase;
}
.inquiry-btn {
  border-radius: 119.999px;
  background: #001b50;
  color: #fff;
  font-size: 12px;
  font-weight: 500;
  line-height: 22px;
  text-transform: uppercase;
  min-width: 150px;
}
.inquiry-now-btn .btn:hover {
  color: #fff;
  background-color: #001b50;
}
.inquiry_now_btn .btn-unset {
  width: 40px;
}
.btn.active,
.btn.show,
.btn:first-child:active,
.inquiry-btn .btn-check:checked + .btn,
:not(.btn-check) + .btn:active {
  border-color: transparent !important;
}
.link-icon-1 {
  height: 40px;
  width: 40px;
  border-radius: 50px;
  background: rgba(0, 27, 80, 0.2);
  line-height: 38px;
}
.play_link_icon1 .link-icon-1 img {
  margin-left: 6px;
}
.casestudy_portfolio_description .portfolio-box-main::after {
  position: absolute;
  background: linear-gradient(
    181deg,
    rgba(0, 27, 80, 0.2),
    rgba(0, 29, 80, 0) 53.08%
  );
  content: "";
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  z-index: 9;
}
.casestudy_portfolio_description .portfolio-text {
  justify-content: flex-start;
  display: flex;
  flex-direction: column;
}
.casestudy_portfolio_description .portfolio-text h6 {
  color: #fff !important;
  font-size: 13px !important;
}
.viwers_eye {
  width: 20%;
}
.box_of_tecnology:hover .text_tecnology_hd h6,
.box_of_tecnology:hover .text_tecnology_hd p,
.box_of_tecnology:hover h2,
.viwers_eye h6,
input[type="checkbox"].toggle + label .off,
input[type="checkbox"].toggle + label .on {
  color: #fff;
}
.ss_link_casestudy_main {
  right: 20px;
  top: 20px;
  z-index: 999;
}
@media (min-width: 320px) and (max-width: 768px) {
  .portfolio-box-main .portfolio-img {
    margin-top: 80px !important;
  }
}
@media (min-width: 320px) and (max-width: 1024px) {
  .portfolio-box-main .portfolio-img {
    height: 240px !important;
  }
}
.position_absolute_header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1024;
  padding: 15px;
}
.portfolio_main_box {
  border-radius: 10px;
  position: relative;
  overflow: hidden;
}
.portfolio-box-main a {
  z-index: 10;
}
.link-icon-1 {
  transition: 0.5s;
  border: 1px solid transparent;
}
.link-icon-1:hover {
  border: 1px solid var(--color-white);
}
.error_page_notfound {
  text-align: center;
  padding: 100px;
}
.error_page_notfound h1 {
  -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color: #012383;
  color: #001b50;
  font-family: Aldrich, sans-serif;
  font-size: 100px;
  font-weight: 500;
  line-height: 165px;
  margin-bottom: -35px;
}
.navbar {
  position: unset;
  z-index: 9999999;
  --bs-navbar-padding-y: 0px !important;
}
.header_dropdown ul li:hover::after {
  transition: 0.5s;
  opacity: 1;
}
.header_dropdown ul li {
  list-style-type: none;
  position: relative;
  transition: 0.5s;
}
.nav_mega_menu .nav-item.active .header_dropdown .sub-service-menu {
  opacity: 1;
  visibility: visible;
  transform: scale(1);
}
.header_dropdown li:hover::after {
  opacity: 1;
  transition: 0.5s;
}
.mega_menu_links .mega_ic {
  width: 45px;
  height: 45px;
  border-radius: 50px;
  line-height: 45px;
  text-align: center;
  background-color: #f7f7f7;
}
.mega_menu_links ul {
  margin-bottom: 0;
  list-style: none;
  padding-left: 0;
}
.mega_menu_links ul li {
  padding: 10px 0;
  width: 100%;
}
.inquires_of_pricing,
.mega_menu_links ul li a {
  position: relative;
  margin-left: 30px;
}
.mega_menu_links ul li:not(:first-child):hover {
  background-color: #eff1f5;
}
.mega_menu_links ul li:not(:first-child) a::before {
  position: absolute;
  background-image: url(../images//hire-devloper/mega-menu-bullets.svg);
  background-repeat: no-repeat;
  content: "";
  top: 4px;
  left: -25px;
  width: 15px;
  height: 15px;
}
.mega_border_left {
  border-left: 1px solid var(--border-color);
}
.nav_mega_menu .nav-link {
  font-weight: 400;
  font-size: 13px;
  color: #f4f5f9;
  cursor: pointer;
}
.nav_mega_menu .nav-item.active .nav-link {
  color: #608aff;
}
.nav_mega_menu .nav-link:focus {
  color: #f4f5f9;
}
@media (min-width: 320px) and (max-width: 991px) {
  .nav_mega_menu .ss-link-main {
    padding-left: 25px !important;
  }
  .mega_border_left {
    border-left: 0;
    border-top: 1px solid var(--border-color);
    padding-top: 20px;
  }
  .nav_mega_menu .nav-item.active .header_dropdown .sub-service-menu {
    position: relative;
  }
  .nav_mega_menu .nav-link {
    font-size: 16px;
    color: #a8bfff;
  }
  .mega-menu.sub-service-menu .container .col-lg-3.col-md-6:nth-child(2) {
    border: none;
    margin-bottom: 20px;
  }
}
@media (min-width: 1023px) and (max-width: 1200px) {
  .mega_menu_links ul li a {
    font-size: 14px;
  }
}
.pricing-list-center-title {
  position: relative;
  font-size: 10px !important;
  color: #001b50;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 1px;
  text-transform: uppercase;
  margin-bottom: 0.5rem;
}
.pricing-list-center-title span {
  background: #fff;
  z-index: 999;
  position: relative;
  padding: 10px;
}
.pricing-list-center-title::after {
  position: absolute;
  content: "";
  width: 282px;
  height: 2px;
  top: 50%;
  background: linear-gradient(to right, #d9612200, #001b50, #d9612200);
  left: 0;
  right: 0;
  margin: 0 auto;
  transform: translate(0, -50%);
}
.pricing-availability-box {
  background: #fff;
  display: flex;
  box-shadow: 0 1px 1px 0 rgba(204, 204, 204, 0.1),
    0 2px 2px 0 rgba(204, 204, 204, 0.09), 0 5px 3px 0 rgba(204, 204, 204, 0.05),
    0 9px 3px 0 rgba(204, 204, 204, 0.01), 0 14px 4px 0 rgba(204, 204, 204, 0);
  align-items: center;
  margin-bottom: 10px;
  padding: 12px 20px;
}
.pricing-list-main .pricing-card {
  background: #fff;
  border: 2px solid #a8a8a8;
  border-radius: 8px;
  padding: 25px 43px 25px 48px;
}
.pricing-list-main .price-number p {
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.6px;
  margin-bottom: 9px;
}
.pricing-list-main .price-number h2 {
  color: #001b50;
  font-size: 24px;
  margin-bottom: 0;
  line-height: 28px;
}
.our-facility-inner-div li {
  list-style: none;
  position: relative;
  padding-left: 26px;
  margin-bottom: 18px;
}
.hirecontact .contact-us-title h2 {
  font-size: 24px;
  line-height: 30px;
  color: var(--color-title);
  font-weight: 500;
}
.our-facility-inner-div li::before {
  position: absolute;
  content: "";
  background: url("../images/hire-devloper/pricing-right.svg") no-repeat;
  top: 4px;
  left: -12px;
  width: 25px;
  height: 25px;
  display: block;
  filter: invert(70%) sepia(8%) saturate(14%) hue-rotate(5deg) brightness(98%)
    contrast(84%);
}
.pricing-card.grey-card h2 {
  color: #111116;
}
.schedule-btn {
  margin-top: 30px;
  background-color: #001b50;
  border-radius: 10px;
  padding: 0 22px;
}
@media (min-width: 320px) and (max-width: 480px) {
  .pricing-list-main .pricing-card {
    padding: 25px;
    height: unset;
  }
  .our-facility-inner-div li {
    margin-bottom: 12px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .pricing-list-center-title::before {
    left: 26%;
  }
  .pricing-availability-box {
    padding: 15px 8px;
  }
  .pricing-list-main .pricing-card {
    padding: 25px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .pricing-list-center-title::before {
    left: 23%;
  }
  .pricing-list-main .pricing-card {
    padding: 30px;
  }
  .pricing-availability-box {
    padding: 15px 8px;
  }
}
@media (min-width: 1200px) and (max-width: 1400px) {
  .pricing-list-center-title::before {
    left: 28%;
  }
}
.hire_main_faq_resource .faq-main,
.hiring_dedication_section .faq-main {
  min-height: unset;
  border-radius: 0;
  padding-bottom: 100px !important;
}
.hiring_dedication_section .idea-and-enhance .ss-heading h3,
.tecnology_main_exclusive .ss-portfolio-text h2 {
  color: var(--color-secondary);
  font-weight: 400;
}
.btn-wrapper-get,
.logo_design_portfolio .portfolio-text h5,
.logo_design_portfolio .portfolio-text h6 {
  color: #001b50 !important;
}
.tecnology_hirededicate {
  background: #fff;
  box-shadow: 0 10px 40px rgba(0, 0, 128, 0.05);
  border-radius: 10px;
  padding: 24px;
  margin: 5px 0;
  display: flex;
  flex-direction: column;
}
.tecnology_plus_slider .owl-carousel .owl-item img {
  width: unset;
  display: inline-block;
}
.tecnology_plus_slider .technology-circle {
  margin: 0;
}
.tecnology_hirededicate .images_section_of_tec {
  column-gap: 8px;
}
.tecnology_hirededicate h4 {
  color: var(--color-secondary);
  font-weight: 400;
  margin: 15px 0 5px;
}
.tecnology_plus_slider .ss-testimonial {
  width: unset;
}
.dedicated_resources li {
  color: var(--color-secondary);
  font-weight: 400;
  position: relative;
  list-style-type: none;
  padding: 5px 0 5px 35px;
}
ul.dedicated_resources {
  padding: 0;
  margin: 10px 0 0;
}
.dedicated_resources li::after {
  background-image: url(../../assets/images/check.svg);
  height: 25px;
  width: 25px;
  content: "";
  position: absolute;
  left: 0;
  top: 6px;
  background-repeat: no-repeat;
}
.conditions-main .lg-text-semibold {
  font-weight: 500 !important;
  font-size: 22px;
}
.conditions-main h2,
.privacy-policy-main h2 {
  font-size: 22px;
  line-height: 32px;
  font-weight: 500;
}
.privacy-policy-main h4 span {
  font-weight: 500;
  font-size: 16px;
}
.contact_sales_btn .btn-wrapper-get {
  background-color: #001b50;
  color: #fff !important;
}
.pakages_of_price h5 {
  text-transform: capitalize;
  padding-bottom: 6px;
  font-weight: 600;
  color: #001b50;
}
.middle input[type="radio"]:checked + .box .pricing-card {
  border: 2px solid #001b50;
}
.middle
  input[type="radio"]:checked
  + .box
  .pricing-card
  .our-facility-inner-div
  li::before {
  background: url("../images/hire-devloper/pricing-right.svg") no-repeat;
  filter: unset;
}
.middle .box {
  transition: 250ms;
  will-change: transition;
  cursor: pointer;
  height: 100%;
  position: relative;
}
.middle label,
.signtue_of_ceo img {
  height: 100%;
  width: 100%;
}
.text_tecnology_hd {
  border-radius: 16px;
  background: #fff;
  box-shadow: 0 4px 14px 0 rgba(0, 0, 0, 0.05);
  text-align: center;
  position: relative;
  padding: 20px;
}
.icons_of_dedication_tec {
  background: #f4f5f9;
  height: 42px;
  width: 42px;
  border-radius: 100%;
  line-height: 42px;
  margin: -40px auto 0;
}
.text_tecnology_hd h6 {
  padding-top: 5px;
}
.tecnology_main_exclusive .enhance-content-main {
  padding: 10px;
  border-radius: 8px !important;
}
.application-title h4 {
  font-size: 16px;
  color: var(--color-secondary);
  line-height: 24px;
}
.contect_modal_hire .contact-us-form-main.ptb-80 {
  padding: 0 !important;
  background-color: unset;
}
.contect_modal_hire .contact-us-form-box {
  padding: 10px;
  background: unset;
}
.class_of_brochers .learn-more.btn-large {
  width: 320px !important;
}
.box_of_tecnology:hover .text_tecnology_hd {
  background: #001b50;
}
.box_of_tecnology {
  transition: 0.4s ease-in-out 0.2s;
  position: relative;
  overflow: hidden;
  padding: 35px 2px 2px;
}
.inquires_of_pricing::after {
  position: absolute;
  background-image: url(../images//hire-devloper/mega-menu-bullets.svg);
  background-repeat: no-repeat;
  content: "";
  top: 7px;
  left: -25px;
  width: 15px;
  height: 15px;
}
.arrow_icon_of_click {
  position: absolute;
  background: #001b50;
  height: 40px;
  width: 40px;
  bottom: -20px;
  right: -20px;
  text-align: center;
  line-height: 40px;
  border-radius: 50% 0 14px;
  opacity: 0;
  transition: 0.6s;
}
.box_of_tecnology:hover .arrow_icon_of_click {
  background-color: #fff;
  opacity: 1;
  bottom: 0;
  right: 0;
}
.arrow_icon_of_click img {
  width: 17px;
}
.logo_design_portfolio .portfolio-box-main {
  padding: 0 !important;
  box-shadow: 0 4.75262px 11.88154px 0 rgba(0, 0, 0, 0.05);
}
.logo_design_portfolio .portfolio-box-main .portfolio-img {
  height: unset !important;
  margin-top: 0 !important;
}
.logo_design_portfolio .link-icon-1 {
  background: #001b50;
}
.logo_design_portfolio .position_absolute_header {
  position: unset;
  align-items: center;
}
.logo_design_portfolio.main_portfolio_cell {
  padding-bottom: 5px;
}
.logo_design_portfolio .logo-design-category {
  justify-content: unset !important;
}
.ss-header,
header {
  /* border-bottom: 1px solid #295ae2; */
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}
.process-3,
.process-6 {
  right: 8%;
}
.nav-link.ss-link:focus,
.nav-link.ss-link:hover {
  color: var(--color-white) !important;
}
.fixed-header {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 999;
  background-color: var(--color-secondary);
}
.ss-header {
  padding: 12px 20px;
}
.ss-link.shedule-text {
  font-weight: 600;
  text-transform: capitalize;
  margin-right: 10px;
}
.offcanvas-header .ss-logo {
  width: 90%;
}
.ss-link.nav-link {
  padding-left: 0 !important;
  padding-right: 0 !important;
  padding: 30px 0;
  opacity: 0.9;
}
.ss-link-main {
  padding: 0 10px;
}
.main_panal_component {
  display: table;
  width: 100%;
  height: calc(100vh - 81px);
  padding: 0 5%;
}
.navbar-brand {
  padding: 0 !important;
}
.btn-wrapper-get,
.btn-wrapper-schedule {
  border-radius: 119.999px;
  min-height: 33px;
  font-size: 11.9999px;
  padding: 0 20px;
  text-align: center;
  display: inline-block;
}
.ss-link.nav-link.active::after {
  content: "";
  position: absolute;
  height: 2px;
  width: 100%;
  left: 0;
  bottom: 2px;
  border-radius: 567.997px;
}
.ss-link-main a {
  font-weight: 400;
  font-size: 13px;
  color: #f4f5f9;
}
.btn-wrapper-get,
.btn-wrapper-schedule,
.ss-link-main-part a {
  font-weight: 500;
  text-transform: uppercase;
}
.pagination-container .pagination li a:hover,
.pagination-container .pagination li.active a {
  color: #fff;
  background-color: var(--color-primary);
}
.nev-section2 {
  flex-grow: 1;
}
.btn-wrapper-get {
  background: #f9d14b;
  line-height: 35px;
}
.btn-wrapper-schedule {
  background: var(--color-primary);
  line-height: 33px;
  color: #fff !important;
  border: 1px solid var(--color-white);
}
.ss-link-main-part a {
  font-size: 14px;
  line-height: 20px;
  align-items: center;
  color: #fff;
}
.about_after_line {
  /* border-left: 1px solid #295ae2; */
  border-left: 1px solid rgba(255, 255, 255, 0.2);
  height: 100%;
}
.heading_medium_text h3 {
  font-weight: 400;
  text-transform: uppercase;
}
.heading_medium_text h3 span {
  font-weight: 400;
  text-decoration: underline;
}
.our_mainbanar_of_part .custom-carousel .item.active {
  flex-grow: 1;
  width: -webkit-fill-available !important;
}
.our_mainbanar_of_part .custom-carousel .item:hover::after {
  background-image: none;
}
.our_mainbanar_of_part .custom-carousel .item:first-child::before {
  border: 1px solid rgba(249, 249, 249, 0.2) !important;
}
.our_mainbanar_of_part .custom-carousel .item:last-child::before {
  border: 1px solid #af9b4d !important;
}
.our_mainbanar_of_part .custom-carousel .item:first-child {
  border: 1px solid rgba(249, 249, 249, 0.2);
  flex-direction: column;
  padding: 40px 0;
}
.our_mainbanar_of_part .custom-carousel .item::before {
  border: 1px solid #af9b4d;
}
.our_mainbanar_of_part .custom-carousel .item {
  height: calc(100vh - 150px);
}
.our_mainbanar_of_part .custom-carousel .item:last-child {
  flex-direction: column;
  padding: 40px 0;
  justify-content: space-around;
}
.requirement-process {
  padding-left: 30px;
  padding-right: 30px;
}
.process-1,
.process-2,
.process-3 {
  position: absolute;
  top: -5%;
  transform: translate(0, -50%);
}
.process-name h5 {
  color: var(--color-white);
  font-weight: 400;
}
.process-round {
  height: 60px;
  width: 60px;
  line-height: 60px;
  background-color: var(--color-primary);
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 100px;
  margin: 5px auto 0;
}
.process-7,
h5.not_open_position {
  position: absolute;
  margin: 0 auto;
}
.process-round p {
  line-height: 60px;
  color: var(--color-white);
  font-weight: 600;
}
.process-1 {
  left: 15%;
}
.process-2,
.process-5 {
  left: 50%;
}
.process-4,
.process-5,
.process-6 {
  top: 50%;
  transform: translate(0, -72%);
  position: absolute;
}
.process-4 {
  left: 21%;
}
.process-7 {
  transform: translate(0, 4%);
  bottom: -27px;
  left: 0;
  right: 0;
}
.cuture-heading.ss-heading .bg-hover h2::before {
  animation: 2s infinite roll-out;
}
.cuture-heading h3 {
  color: var(--color-secondary) !important;
}
.custom-carousel .item.active .culture-title,
.custom-carousel .item.active .cuture-heading h2,
.custom-carousel .item.active .process-hide {
  opacity: 1;
}
.process-mobile {
  display: none;
  position: relative;
  z-index: 99;
}
.process-mobile .process-round {
  margin: 20px 20px 20px 0;
}
.process-mobile::after {
  position: absolute;
  top: 0;
  height: 100%;
  border-left: 1px dashed var(--color-white);
  content: "";
  left: 30px;
  z-index: -99;
}
.requiremnt-img > img {
  width: auto;
  height: 100%;
}
.creativity-content-main .slick-slider .slick-list,
.our_mainbanar_of_part .custom-carousel .item.active .learn-more,
.slick-slider .slick-track {
  display: block;
}
.our_mainbanar_of_part .learn-more {
  float: right;
}
.pagination-container .pagination {
  display: flex;
  justify-content: center;
  list-style: none;
  padding: 0;
  margin: 50px 0 0;
}
.modalbtn,
.policy-nav.nav {
  display: inline-block !important;
}
.pagination-container .pagination li {
  margin: 0 5px;
  cursor: pointer;
}
.pagination-container .pagination li.active a {
  border-color: var(--color-primary);
}
.pagination-container .pagination li a {
  text-decoration: none;
  color: var(--color-primary);
  border: 1px solid var(--color-primary);
  padding: 8px 16px;
  border-radius: 4px;
}
.pagination-container .pagination li.disabled a {
  pointer-events: none;
  color: #6c757d;
  background-color: #fff;
  border-color: #dee2e6;
}
.pagination-container .pagination li.break {
  cursor: default;
  user-select: none;
}
.pagination-container .pagination li.break::before {
  content: "...";
}
@media (max-width: 423px) {
  .pagination-container .pagination li a {
    padding: 8px 13px;
    font-size: 12px;
  }
  .pagination-container .pagination {
    margin-top: 20px;
  }
}
.workshopimg-main img {
  border-radius: 35px;
}
.newsletter_events_slider .owl-nav .owl-next,
.newsletter_events_slider .owl-nav .owl-prev {
  position: absolute;
  top: 50%;
  height: 38px;
  width: 38px;
  font-size: 25px !important;
  margin: 0 !important;
  background: var(--color-white) !important;
  border: unset !important;
}
.newsletter_events_slider .owl-nav .owl-next {
  right: 0;
  transform: translate(50%, -50%);
}
.newsletter_events_slider .owl-nav .owl-prev {
  left: 0;
  transform: translate(-50%, -50%);
}
.newsletter_events_slider .portfolio-slider .owl-carousel .owl-stage-outer {
  margin-bottom: 0 !important;
}
.newsletter_events_slider .testimonials-box-main .owl-carousel .owl-nav {
  position: unset !important;
}
.newsletter_events_slider .owl-theme .owl-nav [class*="owl-"]:hover {
  background: var(--color-primary) !important;
}
.newsletter_slider {
  border-radius: 10px;
  width: 100%;
  height: 320px;
  position: relative;
  overflow: hidden;
}
.newsletter_slider img {
  border-radius: 10px;
  width: 100% !important;
  height: 100%;
  object-fit: cover;
  object-position: top;
  position: relative;
  z-index: 99;
}
.company_goal_metting_wrapper.main_goal .col-lg-6.col-md-6 {
  order: 2;
}
.hire_dedication_blog .web-build-box2 {
  top: unset;
  bottom: 34px;
}
h5.not_open_position {
  top: 50%;
  width: 100%;
}
.react-tel-input .form-control {
  width: -webkit-fill-available !important;
}
.signtue_of_ceo {
  height: 100%;
  width: 200px;
}
.react-tel-input .selected-flag.open:before,
.react-tel-input .selected-flag:focus:before {
  box-shadow: none;
  border: none !important;
}
.react-tel-input .form-control:focus {
  box-shadow: none;
  border: 1px solid var(--color-secondary);
}
.react-tel-input .country-list .search {
  padding: 10px;
}
.react-tel-input .country-list {
  width: min-content;
}
.react-tel-input .country-list .search-box {
  width: -webkit-fill-available;
}
.offcanvas.show:not(.hiding),
.offcanvas.showing {
  z-index: 9999;
}
.HR_contact {
  padding: 10px 20px;
  margin: 15px 0;
  background: #fff;
  border-radius: 30px;
}
.HR_contact p {
  font-size: 16px;
  font-weight: 600;
  color: var(--color-secondary);
}
.header--online {
  background-color: var(--color-primary) !important;
}
.hire_dedication_blog .idea-and-enhance .enhance-content h3 {
  font-size: 16px !important;
  font-weight: 500;
}
.declaration-text ul li p,
.policy-intro-text p,
.privacy-policy-main-text p {
  font-size: 14px;
  line-height: 24px;
  color: var(--color-black);
}
.cookie-modal h2,
.cookie-modal h3,
.cookie-modal p {
  color: var(--color-secondary);
}
.declaration-text ul {
  margin-bottom: 0;
  padding: 0 0 0 20px;
}
.policy-personal-info .enhance-content h3 span {
  font-size: 16px !important;
  line-height: 24px !important;
  color: var(--color-secondary) !important;
  font-weight: 500 !important;
}
.policy-nav.nav li.active {
  color: var(--color-secondary2);
  background-color: #f4f5f9 !important;
  font-weight: 500;
}
.policy-nav.nav {
  position: sticky;
  top: 150px;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 14px 0 #0000001a;
  margin: 20px 0;
  width: 100%;
}
.custom-arrow:hover,
.modalbtn {
  background-color: var(--color-secondary);
}
.policy-nav.nav li {
  cursor: pointer;
  padding: 10px 25px;
  font-size: 16px;
  font-weight: 400;
}
.policy-nav.nav li:first-child {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}
.cookie-modal h2 {
  font-size: 30px;
  font-weight: 600;
  line-height: 38px;
  text-align: start;
  margin-bottom: 15px;
}
.cookie-modal p {
  font-size: 15px;
  font-weight: 400;
  line-height: 20px;
}
.modal-content .cookie-modal-main {
  padding: 0 30px 30px;
  border-radius: 100px;
}
.modalbtn {
  padding: 0.375rem 0.75rem;
  border-radius: 0.375rem;
  justify-content: center !important;
  font-size: 1rem;
  border: 1px solid transparent;
  outline: unset;
  color: #fff;
  font-weight: 400;
}
.cookiemodal-main .modal-footer {
  justify-content: center;
  border: unset;
}
.modal-content {
  border-radius: 20px;
}
.cookie-modal h3 {
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  margin-bottom: 0;
}
input[type="checkbox"].toggle {
  opacity: 0;
  position: absolute;
  left: -99999px;
}
input[type="checkbox"].toggle + label {
  height: 30px;
  width: 75px;
  line-height: 30px;
  background-color: #ccc;
  border-radius: 100px;
  display: inline-block;
  position: relative;
  cursor: pointer;
  transition: 0.25s ease-in;
}
input[type="checkbox"].toggle + label:before,
input[type="checkbox"].toggle + label:hover:before {
  content: " ";
  position: absolute;
  top: 2px;
  left: 2px;
  width: 25px;
  height: 25px;
  background: #fff;
  z-index: 2;
  -moz-transition: 0.25s ease-in;
  -o-transition: 0.25s ease-in;
  -webkit-transition: 0.25s ease-in;
  transition: 0.25s ease-in;
  -moz-border-radius: 14px;
  -webkit-border-radius: 14px;
  border-radius: 14px;
}
input[type="checkbox"].toggle + label .off {
  justify-content: flex-end;
  display: flex;
  padding-right: 10px;
}
input[type="checkbox"].toggle:checked + label .on {
  display: inline-block;
  font-size: 14px;
  padding-left: 10px;
}
input[type="checkbox"].toggle:checked + label:before,
input[type="checkbox"].toggle:checked + label:hover:before,
input[type="checkbox"].toggle:focus:checked + label:before,
input[type="checkbox"].toggle:focus:checked + label:hover:before {
  background-position: 0 0;
  top: 2px;
  left: 100%;
  margin-left: -28px;
}
.cookie-consent-banner {
  background-color: var(--color-white);
  box-shadow: 1px 1px 10px rgb(64 67 150 / 10%);
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 9999;
  right: 0;
  padding: 25px 15px 20px;
}
.cookies-button {
  gap: 8px;
  display: flex;
}
.cookiemodal-main .btn-close {
  height: auto !important;
  width: auto !important;
}
.close-icon {
  position: absolute;
  right: 7px;
  top: -7px;
}
.close-icon img {
  height: 10px;
}
.cookie-consent-banner-main {
  gap: 40px;
  padding-right: 20%;
}
.cookie-setting-modal .modal-body {
  padding: 0;
}
.cookie-info h2 {
  font-size: 24px;
  line-height: 35px;
}
.privacy-policy-link {
  font-size: 16px;
  line-height: 25px;
  color: var(--color-secondary);
  font-weight: 500;
}
/* .testimonials .testimonials-section .slick-slider {
  width: 100vw;
} */
.custom-arrow {
  border: 1px solid #888 !important;
  font-size: 18px !important;
  border-radius: 100px !important;
  height: 54px;
  width: 54px;
  text-align: center;
  line-height: 54px;
  cursor: pointer;
  transition: 0.5s;
  background-color: transparent;
  color: #888;
}
.custom-arrow:hover {
  color: var(--color-white);
  border: 1px solid transparent !important;
}
.custom-arrow.next {
  position: absolute;
  right: 0;
  top: -60px;
}
.custom-arrow.prev {
  position: absolute;
  right: 64px;
  top: -60px;
}
.testimonials-box-main .slick-slider {
  position: static;
}
.testimonials-box-main .slick-slide img {
  height: auto;
}
.blog-box-s1 {
  height: 100%;
  background-color: #eff1f5;
  transition: 0.5s all;
}
.blog-box-s1:hover{
  transform: translate(0, -10px);
}


.buildtogether-slider-main.d-flex{
  height: 100%;
}
 .slick-dots{
  margin-bottom: 20px;
  position: unset!important;
}
  .slick-dots li{
  height: 0;
}
.slick-dots li button:before{
  line-height: 0px;
}
.buildtogether-slider .custom-arrow.prev, .buildtogether-slider .custom-arrow.next{
  top: unset;
  bottom: -75px;
}
.newsletter_main_tabpanal .slick-prev,.newsletter_main_tabpanal .slick-next{
  background: var(--color-white) !important;
  border: initial !important;
  font-size: 25px !important;
  height: 38px;
  margin: 0 !important;
  position: absolute;
  top: 50%;
  border-radius: 50px;
  width: 38px;
  z-index: 99;
} 
.newsletter_main_tabpanal .slick-prev:hover,.newsletter_main_tabpanal .slick-next:hover{
  background: var(--color-primary) !important;
}
.newsletter_main_tabpanal .slick-prev:hover:before, .newsletter_main_tabpanal .slick-prev:focus:before,.newsletter_main_tabpanal .slick-next:hover:before,.newsletter_main_tabpanal .slick-next:focus:before{
  border-right: 2px solid #fff;
  border-top: 2px solid #fff;
}
.newsletter_main_tabpanal .slick-prev:hover.slick-prev:before ,.newsletter_main_tabpanal .slick-next:hover.slick-next:before{
  border-right: 2px solid #fff;
  border-top: 2px solid #fff;
}
.newsletter_main_tabpanal .slick-slider .slick-next:before {
  position: relative;
  content: "";
  display: inline-block;
  width: 0.4em;
  height: 0.4em;
  border-right: 2px solid black;
  border-top: 2px solid black;
  transform: rotate(45deg);
  margin-right:2px;
  }
  .newsletter_main_tabpanal .slick-slider .slick-prev:before {
    position: relative;
    content: "";
    display: inline-block;
    width: 0.4em;
    height: 0.4em;
    border-right: 2px solid black;
    border-top: 2px solid black;
    transform: rotate(220deg);
    margin-right:-2px;
  }
 
  .newsletter_main_tabpanal .slick-prev.slick-disabled, .newsletter_main_tabpanal .slick-next.slick-disabled{
    display: none!important;
  }
#tecnology_scroll .nav-item{
  width: auto!important;
}
#tecnology_scroll .slick-slide{
  margin: 0 10px;
}
#tecnology_scroll .custom-arrow{
  border: 1px solid var(--border-color) !important;
  border-radius: 100px !important;
  height: 42px;
  width: 42px;
  line-height: 42px;
  display: inline-block;
  /* position: absolute!important; */
  top: 0!important;
}
#tecnology_scroll .custom-arrow .svg-inline--fa{
  height: 15px;
}
#tecnology_scroll .custom-arrow.prev{
  right: 46px;
}
.service-portfolio-slider .slick-slide>div{
  display: flex;
}
.tecnology_plus_slider .slick-slide > div{
  display: flex;
}

.technology-we-used .slick-slide > div{
  display: flex;
}
.homeslide1_img_main{
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  background-position:  center;
  height: 580px;
  width: 100%;
  display: table;
}
.homeslide1_text_main{
    position: relative;
    z-index: 99;
    background-size: cover;

}
.homeslide-content{
  display: table-cell;
  vertical-align: middle;
}
.slider-container .slick-dots{
  margin-top:20px;
}
/* .homeslide1_img_main{
  padding: 150px 0;
} */
.homeslide1_text{
  color: var(--color-secondary);
  font-size:35px;
  line-height:45px;
  font-weight: 400;
  padding-bottom: 20px;
  text-transform: capitalize;
}
.home-text-box{
  padding: 35px;
  border-radius: 20px;
  background-color: var(--color-white);
}
.home-text-box p{
  font-size: 16px;
  font-weight: 400;
  color: var(--color-black);
}
.slider-container .slick-next{
  right: -115px!important;
  bottom: -8px !important;
}
.slider-container .slick-prev{
  left: -125px!important;
  z-index: 9;
  bottom: -9px !important;
}
.slider-container .slick-prev,.slider-container .slick-next{
  /* bottom: 0!important; */
  top: unset!important;
  left: 0;
  right:0;
  margin: 0 auto;
  transition: 0.8s all!important;
  cursor: pointer!important;
}
.slider-container .slick-slider .slick-prev:before{
  margin-right: -2px!important;
  transform: rotate(220deg)!important;
}
.slider-container .slick-slider .slick-next:before, .slider-container .slick-slider .slick-prev:before{
  position: relative;
  content: "";
  display: inline-block;
  width: 0.7em;
  height: 0.7em;
  border-right: 2px solid black;
  border-top: 2px solid black;
  transform: rotate(45deg);
  margin-right: 2px;
}

.slider-container .slick-prev:hover{
  padding-right: 5px;
  transition: 0.8s all!important;

}
.slider-container .slick-next:hover{
  padding-left:5px; 
  transition: 0.4s all!important;

}
/* .slider-container .slick-dots li button:before{
  color: transparent;
  opacity: 0.8;
  height: 5px;
  width: 5px;
  border: 1px solid #000;
  border-radius: 50px;
} */
.slider-container .slick-dots li.slick-active button:before {
  color: var(--color-secondary);
  opacity: 1;
}
.slider-container .slick-dots li button:before{
  height: 10px;
  width: 10px;
}
.slider-container .slick-dots li button:before{
  font-size: 10px;
}
.slider-container .slick-dots li, .slick-dots li button, body #magicPointer{
  height: 9px;
  width: 12px;
}
.brand_logo{
  border: 1px solid #fff;
  border-radius: 8px;
  padding: 30px;
  box-shadow: 0px 4px 14px 0px #0000000D;

}
/* .widget-t6-basic{
  max-width: unset!important;
} */
.creativity-main:after {
  background-color: #0006 !important;
  bottom: 0;
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  right: 0;
  width: 100%;
  z-index: 9;
}
button.service-previous-btn.learn-more  {
  transform: rotate(180deg);
}
.ss-heading.ss-white-heading h1{
 color: var(--color-white);
}
.contact-detail-text p{
  font-size: 18px;
  line-height: 28px;
  font-weight: 400;
}
.service-btn-mobile{
  position: relative;
    z-index: 99;
}
.home-text-box h1{
   text-align: start!important;
}
/* .home-slidersame{
  min-height: 470px;
  display: table;
  width: 100%;
} */

.certificate-text span{
  color: var(--color-secondary);
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}
.ceo-text span{
  color: var(--color-secondary);
}
.ss-technology-content p{
  font-weight: 500;
  color: #222b24;
  font-size: 24px;
  line-height: 30px;
}
.home-page-portfolio .testimonials-box-main .slick-dots{
 margin-bottom: 0;
}
.phn-img svg path{
  fill: #7c8fb4;
}
.ss-phn-img svg path{
  fill: #5481FF;
}
.ss-phn-img svg{
  font-size: 21px;
}
.phn-img i{
  color: #7c8fb4;
  font-size: 21px;
}
.experience-box{
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.experience-img{
  flex-grow: 1
  ;
}
.industry-box{
  display: flex;
  flex-direction: column;
  height: 100%;
  gap: 5px;
}