@media (min-width: 280px) and (max-width: 375px) {
  .pagination_change_btn {
    margin-bottom: 25px;
  }
  .pagination_change_btn button.learn-more .button-text {
    font-size: 12px;
  }
  .pagination_change_btn button.learn-more.btn-large {
    width: 185px !important;
  }
  .pagination_change_btn button.learn-more {
    width: 96px !important;
  }
  .pagination_change_btn button.learn-more .circle .icon.arrow img {
    height: 12px;
  }
  .pagination_change_btn button.learn-more .circle {
    width: 35px;
    height: 35px;
  }
  .submit_cancel_btn .next-btn .btn-wrapper {
    min-width: 100px !important;
  }
  .testimonials-box-main .owl-carousel .owl-nav {
    top: -70px;
  }
  .testimonials-box-main .owl-nav .owl-next,
  .testimonials-box-main .owl-nav .owl-prev {
    font-size: 15px !important;
    height: 30px;
    width: 30px;
    margin: 2px;
  }
  .blog-section .testimonials-box-main .owl-carousel .owl-nav {
    top: -46px;
  }
}
@media (min-width: 375px) and (max-width: 480px) {
  .testimonials-box-main .owl-carousel .owl-nav {
    top: -75px;
  }
  .testimonials-box-main .owl-nav .owl-next,
  .testimonials-box-main .owl-nav .owl-prev {
    font-size: 20px !important;
    height: 38px;
    width: 38px;
    margin: 2px;
  }
  .blog-section .testimonials-box-main .owl-carousel .owl-nav {
    top: -46px;
  }
}
@media (min-width: 320px) and (max-width: 767px) {
  .homeslide1_img_main{
    padding: 80px 0;
  }
  .home-text-box{
    padding: 20px;
  }
  .homeslide1_text{
    font-size: 25px;
    line-height: 30px;
    padding-bottom: 10px;
  }
  .our-hiring-text-dese h2 {
    font-size: 20px;
    line-height: 22px;
    margin-bottom: 5px;
  }
  .our-hiring-text-dese p {
    font-size: 12px;
    line-height: 19px;
  }
  .our-hiring-img {
    translate: 0px 0px;
    height: 90px;
    width: 90px;
    line-height: 90px;
    border-radius: 10px;
    margin-bottom: 15px;
  }
  .our-hiring-img img {
    width: 60px;
  }
  .core-value-section .items {
    margin: 0;
  }
  .core-value-section .slick-slide {
    display: flex;
    height: inherit;
    justify-content: center;
    align-items: center;
  }
  .core-value-section .slick-track {
    display: flex;
  }
  .ss_sens_culture_main.hstack {
    display: block !important;
  }
  .exception_com_culture .slick-slide > div {
    margin-right: 10px;
  }
  .exception_com_culture .slick-track .slick-slide:first-child > div {
    margin-left: 0;
  }
  .exception_com_culture .slick-track .slick-slide:last-child > div {
    margin-right: 0;
  }
  .timeline ul li:nth-child(odd) span {
    right: unset;
    left: -19px;
  }
  .timeline ul li:nth-child(2n) span {
    left: -19px;
  }
  header {
    padding: 20px 0;
  }
  .innvoation_ideas_about .accordion-header h3,
  .innvoation_ideas_about .accordion-header h4,
  .innvoation_ideas_about .accordion-header h5,
  .innvoation_ideas_about .accordion-header h6 {
    font-size: 14px;
  }
  .next-prev-btn .learn-more.arrow-btn {
    width: 68px !important;
  }
  .team-img2 {
    display: block;
  }
  .team-bg-img {
    display: none;
  }
  .class_of_brochers .learn-more.btn-large {
    width: 285px !important;
    margin-top: 10px;
  }
  .home-page-main .slider-container .slick-dots{
    margin-top: 10px;
    margin-bottom:10px;
  }
  .home-page-main .slider-container .slick-slider .slick-next:before,.home-page-main  .slider-container .slick-slider .slick-prev:before{
    width: 0.4em;
    height: 0.4em;
  }
  .slider-container .slick-dots li button:before {
    font-size: 6px;
}
.slider-container .slick-dots li button:before {
  height: 6px;
  width: 6px;
}
}
@media (min-width: 320px) and (max-width: 480px) {
  button.learn-more .circle {
    width: 100% !important;
}
.service-btn-mobile button.learn-more .circle .icon.arrow{
  left: 0;
  right: 0;
  margin: 0 auto !important;
  background: 0 0;
}
.service-btn-mobile button.learn-more .circle .icon.arrow img{
  margin-left: unset!important;
}

  .service-portfolio-slider.testimonials-box-main .slick-initialized .slick-slide{
    margin:0 0;
  }
  .apply_social_section.d-flex,
  .owl-carousel.ss_tecnology_blog .owl-dots.disabled,
  .owl-carousel.ss_tecnology_blog .owl-nav.disabled,
  .partner-text-main .ss-certified.d-flex {
    display: block !important;
  }
  .cookies-button button {
    margin-right: 5px;
    margin-bottom: 10px;
  }
  .cookies-button {
    display: inline-flex !important;
    width: 100%;
    margin-top: 5px;
  }
  .cookie-consent-banner {
    padding-bottom: 60px;
  }
  button.learn-more.btn-large .circle .icon.arrow img {
    height: 13px;
  }
  h1,
  h2 {
    font-size: 23px;
  }
  h1 {
    line-height: 38px;
  }
  h2 {
    line-height: 34px;
  }
  .MuiImageList-masonry {
    column-gap: 5px !important;
    column-count: 3 !important;
  }
  .job_description_com {
    padding: 15px 10px;
    margin-top: 10px;
  }
  .innvoation_ideas_about .accodion_section_2 .accordion-body,
  .innvoation_ideas_about .accordion-button:not(.collapsed),
  .sub_details_of_job {
    padding: 10px;
  }
  .meeting_land_why.pt-80,
  .our_talent_and_dream .pt-150 {
    padding-top: 40px;
  }
  .our_talent_and_dream .pb-120 {
    padding-bottom: 50px;
  }
  .ss-header {
    padding: 12px 20px;
  }
  .process-mobile .process-round {
    margin: 10px 20px 10px 12px !important;
    height: 40px;
    width: 40px;
  }
  .process-round p {
    line-height: 40px !important;
  }
  .requirement-process.pt-50 {
    padding-top: 0;
  }
  .main_work_culture_panal .custom-carousel {
    margin-top: 30px;
  }
  .ss_sens_culture_main.gap-5 {
    gap: 10px !important;
  }
  .our-hiring-text-dese p {
    padding-inline-end: 0px;
  }
  .our-hiring-text-main {
    border-radius: 0;
    padding: 20px;
  }
  .sens_details_of_subcarrer {
    padding-bottom: 235px;
  }
  .main_job_description_part2 {
    padding: 0 10px;
  }
  .about_sub_job_desc .col-lg-8 {
    padding-right: calc(var(--bs-gutter-x) * 0.5);
  }
  .main_job_description_part2 .sm-text-semibold.mx-2 {
    margin-left: 0;
    margin-right: 0;
  }
  .related_job_opninig_s2 {
    padding: 20px 10px;
  }
  .main_job_description h4,
  .testimonials-box-s1 {
    padding: 20px;
  }
  .loogin_design1,
  .social_apply_icon {
    margin-top: 15px;
  }
  .hiring_description_s1 {
    border-bottom: 0.5px solid var(--color-secondary-border);
    padding: 15px;
  }
  .about_sub_job_desc .col-lg-4 {
    padding-left: calc(var(--bs-gutter-x) * 0.5);
  }
  .related_job_opninig {
    border-left: unset;
  }
  .ss_benififts_section .col-lg-4:nth-of-type(n + 4):nth-of-type(-n + 6) {
    border-top: unset;
    border-bottom: unset;
  }
  .ss_benififts_section .col-lg-4:nth-child(3n-1) {
    border-left: unset;
    border-right: unset;
  }
  .offcanvas {
    width: 80% !important;
  }
  .ss-link.shedule-text {
    color: var(--color-secondary) !important;
  }
  .get-touch-btn .btn-wrapper {
    color: var(--color-secondary);
    border: 1.3px solid var(--color-secondary);
  }
  .Blog_heading_section h6::after,
  .blog-slider::after,
  .flip_card_about_goal .flip-card,
  .letter-effect,
  .newsletter_events_slider
    .portfolio-slider.testimonials-box-main
    .owl-carousel
    .owl-nav.disabled,
  .ss-heading .bg-hover h2:before,
  .vision-img {
    display: none;
  }
  .apply_modal_sens_btn,
  .first_section_photos img,
  .partner-text-main {
    margin-top: 10px;
  }
  .partner-text-main .wrapper .float-image.right {
    float: unset;
  }
  .partner-text-main .wrapper {
    text-align: center;
  }
  .partner-text-main .wrapper .float-image {
    margin: 0;
    shape-outside: unset;
  }
  .partner-text-main .ceo-text {
    text-align: start;
  }
  .about-social-img {
    margin-top: 5px;
  }
  .our_mainbanar_of_part .custom-carousel .item:first-child {
    row-gap: 0 !important;
  }
  .cuture-heading.ss-heading h2 {
    font-size: 18px !important;
    line-height: 30px !important;
  }
  .work-title.pb-50 {
    padding-bottom: 0;
  }
  .cuture-heading h3 {
    font-size: 20px;
    line-height: 32px;
  }
  .our_mainbanar_of_part .learn-more {
    float: unset !important;
  }
  .our_case_study_logo {
    right: 0;
  }
  .flip_card_about_goal.our-Mission .col-lg-6:first-child {
    order: 2;
  }
  .flip_card_about_goal {
    padding: 50px 0;
  }
  .change_vision_img {
    margin-top: 20px;
    display: block;
  }
  .Blog_heading_section h2 {
    padding: 12px 0;
  }
  .Blog_heading_section h6 {
    font-size: 10px;
    padding-left: 0;
  }
  .Blog_heading_section h6 span {
    margin: 0 4px;
  }
  .looking_all_design.hstack {
    display: block !important;
    padding: 20px;
  }
  .custom-carousel .item h4,
  .our_mainbanar_of_part .custom-carousel .item:last-child h4 {
    font-size: 13px !important;
    font-weight: 400;
  }
  .ss-portfolio-box-main {
    padding: 20px !important;
  }
  .attractive-main.ptb-120 {
    padding: 30px 0;
  }
  .main_panal_component {
    height: auto !important;
  }
  .main_work_culture_panal {
    padding-bottom: 30px;
  }
  .our_mainbanar_of_part button.learn-more.btn-large {
    width: 260px !important;
  }
  .process-mobile::after {
    opacity: 0.5;
  }
  .process-hide {
    margin-top: 8px;
  }
  .creativity-content.mb-80,
  .my-element-to-clip
    .row
    .col-lg-4:last-child
    .ss-contact-info
    .contact-info-main:last-child,
  .our-core-value
    .container
    .row:last-child
    .col-lg-6.col-md-6.mb-25:last-child {
    margin-bottom: 0;
  }
  .delivering-box {
    padding: 15px 15px 20px !important;
  }
  .our-case-study,
  .ss_hover_sub .column {
    position: relative;
  }
  .custom-carousel .item:hover::after {
    background: linear-gradient(
      180deg,
      rgba(0, 27, 80, 0.8) 0,
      rgba(0, 27, 80, 0.9) 100%
    );
  }
  .ss-blog-box-title {
    padding-bottom: 10px;
  }
  .owl-theme .owl-dots {
    margin-top: 10px !important;
  }
  .mb-20.col {
    margin-bottom: 10px;
  }
  .ss-contact-info.footer-location-border {
    border-bottom: 1px solid rgba(146, 170, 237, 0.4);
  }
  .my-element-to-clip .row .col-lg-4.col-lg-4:nth-child(n + 2) {
    margin-top: 20px;
  }
  .core-value-box {
    padding: 15px 8px;
  }
  .abousus-timeline.testimonials {
    margin-top: -40px;
    padding-top: 40px;
  }
  .portfolio-box-main {
    margin: 0 10px;
  }
 

  .ss-heading h1,
  .ss-heading h2 {
    font-weight: 300;
  }
  .ss-heading h1 span,
  .ss-heading h2 span {
    font-weight: 400;
  }
  .head_of_main_metting h1,
  .head_of_main_metting h2 {
    font-size: 24px;
    line-height: 20px;
    padding-bottom: 40px;
    color: #fff;
    text-align: start;
    font-weight: 300;
    opacity: 0.5;
  }
  .head_of_title_why h2 {
    font-size: 45px !important;
    line-height: 55px !important;
  }
  .metting_yellow_card.pt-100 {
    padding-top: 60px;
  }
  .metting_yellow_card {
    border-radius: 20px 20px 0 0;
  }
  button.learn-more .circle {
    width: 100% !important;
  }
  button.learn-more:hover .circle {
    width: 100%;
  }
  .learn-more.arrow-btn {
    width: 42px !important;
  }
  .related_job_opninig > h5 {
    padding: 10px 20px;
  }
  .details_of_software h2 {
    padding: 5px 0 8px;
  }
  button.learn-more .button-text {
    font-size: 13px;
    margin-left: 40px;
  }
  button.learn-more {
    width: 160px;
  }
  button.learn-more .circle .icon.arrow img {
    margin-left: 20px;
  }
  button.learn-more.btn-large .button-text,
  button.learn-more.btn-medium .button-text {
    margin-left: 24px !important;
  }
  button.learn-more.btn-large {
    width: 240px !important;
  }
  button.learn-more.btn-medium {
    width: 185px !important;
  }
  .sm-text-semibold.mx-2 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  .apply_dese_btn button.learn-more .circle .icon.arrow img,
  .comm-btn button.learn-more .circle .icon.arrow img {
    margin-left: 10px;
  }
  .portfolio-slider .owl-carousel .owl-stage-outer {
    margin-bottom: 20px;
  }
  .view-testimonials.pt-50 {
    padding-top: 50px !important;
  }
  .portfolio-slider.testimonials-box-main .owl-carousel .owl-nav.disabled {
    position: absolute !important;
    display: block;
    top: -11px !important;
    right: 0;
    left: unset;
    transform: translate(-10px, 0);
  }
  .portfolio-slider.testimonials-box-main {
    position: unset;
  }
  .buildtogether-slider.testimonials-box-main .owl-carousel .owl-nav {
    bottom: unset;
    top: -75px;
  }
  .build-section h2 {
    padding-inline-end: 25px;
  }
  .custom-carousel .item:after {
    background: linear-gradient(
      180deg,
      rgba(0, 27, 80, 0.7) 0,
      rgba(0, 27, 80, 0.9) 100%
    );
  }
  .owl-theme .owl-dots .owl-dot span {
    width: 5px;
    height: 5px;
    margin: 3px 4px;
  }
  .delivering-section .col-lg-4:nth-child(7) .delivering-box.border,
  .delivering-section .col-lg-4:nth-child(8) .delivering-box.border {
    border-right: 1px solid var(--border-color) !important;
  }
  .chlid_tab_box .nav-link {
    padding: 10px 8px;
    font-size: 13px;
  }
  .commna_font_style p {
    margin-top: 0;
  }
  .full-image {
    -webkit-mask-image: none;
    margin-top: 10px;
  }
  .shape-image {
    visibility: hidden;
  }
  #el {
    height: 415px;
    overflow-y: scroll;
  }
  .logo_design_portfolio .portfolio-box-main .portfolio-img,
  .portfolio-box-main .portfolio-img,
  .portfolio-box-main .portfolio-img img {
    height: 100% !important;
    width: 100%;
  }
  .delivering-info h2,
  .delivering-info h3 {
    font-size: 14px;
    line-height: 25px;
  }
  .choose-comp-dese h3,
  .choose-comp-dese p,
  .client-name h2,
  .portfolio-text h3,
  .why-choose-us-blog .our-chosse-blog h2 {
    font-size: 14px;
  }
  .brand_logo{
    margin-bottom: 15px;
  }
}
@media (min-width: 481px) and (max-width: 768px) {
  .brand_logo{
    margin-bottom: 15px;
  }
  .cookies-button button {
    margin-bottom: 0 !important;
  }
  .ss-title,
  h1,
  h2 {
    line-height: 50px;
  }
  h1,
  h2 {
    font-size: 38px;
  }
  .ss-title {
    font-size: 35px;
  }
  h3 {
    font-size: 20px;
    line-height: 24px;
  }
  h4,
  h5 {
    font-size: 18px;
  }
  h4 {
    line-height: 20px;
  }
  h5 {
    line-height: 28px;
  }
  h6 {
    font-size: 16px;
    line-height: 24px;
  }
  .ss-heading.proof-text h2 {
    font-size: 23px;
    line-height: 38px;
  }
  .ss_sens_culture_main.gap-5 {
    gap: 10px !important;
  }
  .our-hiring-text-dese p {
    padding-inline-end: 10px;
  }
  .our-hiring-text-dese {
    padding: 20px 20px 20px 0;
  }
  .attractive-box .hstack,
  .ss_sens_culture_main.hstack {
    display: block !important;
  }
  .department_of_job .nav-pills .nav-link {
    font-size: 12px;
    line-height: 18px;
    padding: 8px 0 8px 14px;
  }
  .department_of_job .nav {
    row-gap: 5px;
  }
  .job_description_com {
    padding: 18px 12px;
    overflow-y: hidden;
    border-radius: 10px;
  }
  .sub_details_of_job {
    padding: 12px;
  }
  #el {
    height: 415px;
    overflow-y: scroll;
  }
  .sub_details_of_job h5 {
    font-size: 15px;
    line-height: 19px;
  }
  .department_of_job {
    border-radius: 10px;
  }
  .ss-header .offcanvas {
    width: 80%;
  }
  .ss-link.shedule-text {
    color: var(--color-secondary) !important;
  }
  .get-touch-btn .btn-wrapper {
    color: var(--color-secondary);
    border: 1.3px solid var(--color-secondary);
  }
  .my-element-to-clip .row .col-lg-4.col-lg-4:nth-child(n + 2),
  .partner-text-main {
    margin-top: 20px;
  }
  .process-mobile .process-round {
    margin: 12px 20px 12px 12px !important;
    height: 40px;
    width: 40px;
  }
  .process-round p {
    line-height: 40px !important;
  }
  .requirement-process.pt-50 {
    padding-top: 0;
  }
  .work-title.pb-50 {
    padding-bottom: 0 !important;
  }
  .process-mobile::after {
    top: 24px !important;
    height: 83% !important;
  }
  .main_panal_component {
    width: fit-content;
  }
  .main_panal_component .custom-carousel .item h4 {
    bottom: 45px;
  }
  .our_mainbanar_of_part .custom-carousel .item:first-child {
    row-gap: 0 !important;
  }
  .our_mainbanar_of_part .learn-more {
    float: unset !important;
  }
  .our_case_study_logo {
    right: 60px;
  }
  .head_of_main_metting h2 {
    font-size: 36px;
    line-height: 50px;
    padding-bottom: 20px;
  }
  .meeting_land_why.pt-80 {
    padding-top: 50px;
  }
  .head_of_title_why h2 {
    font-size: 45px;
    line-height: 55px;
  }
  .ss-contact-info.footer-location-border {
    border-bottom: 1px solid rgba(146, 170, 237, 0.4);
  }
  .my-element-to-clip
    .row
    .col-lg-4:last-child
    .ss-contact-info
    .contact-info-main:last-child {
    margin-bottom: 0;
  }
  .delivering-section .col-lg-4:nth-child(8) .delivering-box.border {
    border-right: 1px solid var(--border-color) !important;
  }
  .first_section_photos img {
    margin-top: 10px;
  }
  .experience_of_work h4 {
    font-size: 15px;
    line-height: 27px;
  }
  .have_clear_vision_mission .shape-container::after {
    display: none;
  }
  .department_of_job .nav-pills .nav-link span {
    font-size: 11px;
    line-height: 24px;
    height: 24px;
    width: 24px;
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  h1,
  h2 {
    font-size: 38px;
    line-height: 50px;
  }
  .main_job_description h4,
  .related_job_opninig > h5 {
    padding: 20px 15px;
  }
  .related_job_opninig_s2 {
    padding: 20px 10px 20px 15px;
  }
  .main_job_description_part2 {
    padding: 0 15px;
  }
  .our-hiring-text-dese_s1 h4 {
    font-size: 18px;
    margin-bottom: 6px;
  }
  .our-hiring-igsub_s1.mr-15 {
    margin-right: 8px;
  }
  .hiring_description_s1 {
    padding: 10px;
  }
  .our-hiring-text-dese h2 {
    font-size: 20px;
    line-height: 22px;
    margin-bottom: 5px;
  }
  .our-hiring-text-dese p {
    font-size: 12px;
    line-height: 19px;
  }
  .core-value-section .slick-list {
    padding-top: 20px;
  }
  .ss-portfolio-box-main {
    padding: 70px 50px;
  }
  .flip-card {
    min-height: 500px;
    margin-top: 20px;
  }
  .flip_card_about_goal {
    padding: 50px 0;
  }
}
@media (min-width: 768px) and (max-width: 1023px) {
  .vision-img {
    margin-top: 50px;
  }
  .ss-vision-img::before {
    left: 120px;
  }
  .ss-vision-img.ss-mission-img::before {
    right: 117px;
  }
  .flip_card_about_goal.our-Mission .col-lg-6:first-child {
    order: 2;
  }
  .our_company_benifits .ss_benififts_section .col-lg-4:nth-child(3n-1) {
    border-left: none;
    border-right: none;
  }
  .our_company_benifits .ss_benififts_section .col-lg-4:nth-child(2n-1) {
    border-right: 0.5px solid var(--color-secondary-border);
  }
  .our_company_benifits
    .ss_benififts_section
    .col-lg-4:nth-of-type(n + 4):nth-of-type(-n + 6) {
    border-top: none;
    border-bottom: none;
  }
  .our_company_benifits .ss_benififts_section .items {
    background-color: var(--color-secondary-white);
    border-radius: 8px;
    height: 100%;
  }
  .our_company_benifits .ss_benififts_section .col-lg-4 {
    margin-bottom: 15px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .slider-container .slick-slide > div{
    margin:  0 0!important;
  }
  .custom-carousel .item.active {
    width: 400px;
  }
  .custom-carousel .item-desc {
    transform: translateY(calc(100% - 42px));
    -webkit-transform: translateY(calc(100% - 42px));
  }
  .col-lg-4:first-child .why-choose-us-blog {
    border-bottom: 1px solid var(--color-white) !important;
  }
  .custom-carousel .item {
    margin: 0 3px;
    border-radius: 8px;
  }
  .custom-carousel .item h4 {
    font-size: 15px;
    bottom: 30px;
  }
  .slick-slide > div {
    margin: 0 8px;
  }
  header {
    padding: 20px 0;
  }
  .our_com_casestudy .website-development-img {
    margin-top: 20px;
  }
  .head_of_main_metting h2 {
    font-size: 58px;
    line-height: 50px;
    padding-bottom: 20px;
  }
  .meeting_land_why.pt-80 {
    padding-top: 50px;
  }
  .head_of_title_why h2 {
    font-size: 53px;
    line-height: 68px;
  }
  .testimonials-box-main.service-portfolio-slider  .slick-initialized .slick-slide{
    margin: 0 0 ;
  }
  .testimonials-box-main.service-portfolio-slider .slick-slide > div{
    margin: 0 4px;
  }
}
@media (max-width: 767px) {
  .custom-carousel .item.active::before,
  .custom-carousel .item::before {
    margin: 0 10px 0 auto !important;
  }
  .custom-carousel .item.active {
    width: 100% !important;
    height: 100%;
    box-shadow: 6px 10px 10px rgba(0, 0, 0, 0.25);
    -webkit-box-shadow: 6px 10px 10px rgba(0, 0, 0, 0.25);
  }
  .custom-carousel .item-desc {
    padding: 15px 10px 5px;
    width: 100%;
    transform: translateY(calc(100% - 42px));
    -webkit-transform: translateY(calc(100% - 42px));
  }
  .col-lg-4:nth-child(-n + 3) .why-choose-us-blog {
    border-bottom: 1px solid var(--color-white) !important;
  }
  .custom-carousel {
    flex-direction: column;
    row-gap: 10px;
  }
  .custom-carousel .item {
    margin: 0;
    height: 55px;
    width: 100%;
  }
  .custom-carousel .item h4 {
    top: 50%;
    transform: translate(0, -50%);
    white-space: pre-wrap;
    bottom: 0;
    font-size: 14px;
    padding: 0 10px;
  }
  .custom-carousel .item::before {
    z-index: 9;
  }
  .comman_idea_style h6,
  .content_of_vision_mission p,
  .current_busi_com,
  .experience_of_work,
  .have_clear_vision_mission .shape-container::after,
  .newsletter_months_dese .shape-container,
  .newsletter_months_dese .shape-container::after,
  .newsletter_months_dese .shape-container::before {
    display: none;
  }
  .commna_font_style.have_clear_vision_mission > p {
    display: block;
  }
  .add_responsive_img {
    display: block !important;
  }
}
@media (min-width: 768px) {

  .ss_sens_culture_main.hstack {
    display: flex !important;
  }
  .exception_com_culture .slick-list {
    overflow: unset;
  }
  .exception_com_culture
    .slick-track
    .slick-slide:nth-child(2)
    .careere-main-images {
    translate: 0px -102px;
    transform: rotate(-11deg);
  }
  .exception_com_culture
    .slick-track
    .slick-slide:nth-child(3)
    .careere-main-images {
    translate: 0px 40px;
    transform: rotate(10deg);
  }
  .exception_com_culture
    .slick-track
    .slick-slide:nth-child(4)
    .careere-main-images {
    translate: 0px -55px;
    transform: rotate(-3deg);
  }
  .exception_com_culture
    .slick-track
    .slick-slide:nth-child(5)
    .careere-main-images {
    transform: rotate(6deg);
  }
  .exception_com_culture .slick-slide > div {
    margin: 0 15px;
  }
  .exception_com_culture .slick-track .slick-slide:first-child > div {
    margin-left: 0;
  }
  .exception_com_culture .slick-track .slick-slide:last-child > div {
    margin-right: 0;
  }
  .specific_blog_page .col-lg-4:nth-last-child(-n + 3) .blog-box-main {
    margin-bottom: 0;
  }
}
@media (min-width: 1024px) {
  .ss-case-study .nav-pills .nav-link:hover {
    border: 1px solid var(--color-primary);
    color: var(--color-primary);
  }
}
@media (min-width: 1440px) {
  .ss-case-study .nav-pills .nav-link:hover {
    border: 1px solid var(--color-primary);
    color: var(--color-primary);
  }
}
@media (min-width: 320px) and (max-width: 1024px) {
  #tecnology_scroll_secound,
  .ss_casestudu-setab {
    display: none;
  }
  #tecnology_scroll {
    display: block;
  }
  .ss_tecnology_blog {
    padding-inline-end: 110px;
  }
  .ss_tecnology_blog .owl-stage {
    display: -webkit-box !important;
  }
  .nav-pills#tecnology_scroll .nav-link {
    margin-bottom: 0;
    margin-right: 0;
  }
  .ss_tecnology_blog .owl-nav {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translate(0, -50%);
    margin-top: 0;
  }
  .ss_tecnology_blog .owl-nav .owl-next,
  .ss_tecnology_blog .owl-nav .owl-prev {
    height: 42px;
    width: 42px;
    border: 1px solid var(--border-color) !important;
    border-radius: 100px !important;
  }
  .owl-theme .owl-nav .disabled {
    opacity: 1;
  }
  .ss_tecnology_blog .owl-nav [class*="owl-"]:hover {
    background-color: var(--color-primary) !important;
    border-radius: 50px !important;
    color: #fff !important;
    border-color: var(--color-primary) !important;
  }
  .owl-carousel .owl-item,
  .ss_tecnology_blog .owl-carousel {
    position: unset;
  }
  .ss_tecnology_blog .owl-nav [class*="owl-"]:hover i {
    color: #fff !important;
  }
  .ss_tecnology_blog .owl-nav i {
    font-size: 22px;
    line-height: 40px;
  }
  .ss_tecnology_blog .owl-nav [class*="owl-"] {
    margin: 0 0 0 5px;
  }
  .services-in-action-main::after {
    opacity: 0.2;
  }
}
@media (min-width: 320px) and (max-width: 991px) {
  .technology-we-used .testimonials-box-main{
    width: 100%;
  }
  .newsletter_slider img{
    margin-top: 10px;
  }
  .award-section .mb-md-20{
    margin-bottom: 20px!important;
  }
  .testimonials-section .testimonials-box-main {
    padding-bottom: 25px;
  }
  .ss-blog-box-img {
    height: unset !important;
  }
  .testimonials-section .slick-slider {
    width: 100% !important;
  }
  .ss-link-main a,
  .ss-link-main-part a {
    font-size: 16px !important;
  }
  .btns_of_comman .ss-link-main-part,
  .nav-section1 {
    width: 100%;
    border-top: 0.5px solid #a8bfff;
  }
  .btns_of_comman .ss-link-main-part,
  .header_sub_main_wapper .nav-section1 .ss-link-main-part:nth-of-type(2),
  .nav-section1 {
    border-bottom: 0.5px solid #a8bfff;
  }
  .after_add_com_logo {
    margin-bottom: 15px;
  }
  .offcanvas {
    background-color: #001B50 !important;
  }
  .offcanvas-header {
    padding: 25px;
  }
  .offcanvas-header .btn-close {
    filter: brightness(0) invert(1);
  }
  .nav-section1 {
    justify-content: start;
    padding: 20px 0 20px 25px;
    column-gap: 0;
  }
  .ss-link.nav-link.active {
    color: #fff !important;
  }
  .about_after_line,
  .ss-link.nav-link.active::after {
    display: none;
  }
  .nev-section2.ps-2 {
    padding-left: 25px !important;
  }
  .nev-section2 {
    height: 100%;
    width: 100%;
  }
  .ss-link-main a {
    color: #a8bfff !important;
    font-weight: 400 !important;
    line-height: 24px !important;
  }
  .ss-link.nav-link {
    padding: 26px 0 0 !important;
  }
  .ss-link-main {
    padding: 0 !important;
  }
  .offcanvas-body {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0;
  }
  .btns_of_comman.navbar-nav {
    row-gap: 20px;
    margin-bottom: 70px;
    margin-top: 20px;
  }
  .d-flex.align-items-center.btns_of_comman.navbar-nav {
    align-items: unset !important;
  }
  .btns_of_comman .ss-link-main-part {
    padding: 20px 0 20px 25px;
  }
  .get-touch-btn,
  .schedule_btn {
    padding-left: 25px;
  }
  .ss-link-main.btn-sewrvice-get {
    align-self: unset !important;
  }
  .btn-sewrvice-get .nav-link {
    padding-top: 30px !important;
    font-weight: 600 !important;
  }
  .header_sub_main_wapper .btns_of_comman {
    border-bottom: 0.5px solid #a8bfff;
    row-gap: 15px;
    padding-bottom: 15px;
  }
  .header_sub_main_wapper .nav-section1 {
    display: block !important;
    padding: 0 !important;
  }
  .header_sub_main_wapper .nav-section1 .ss-link-main-part {
    padding: 20px 25px;
  }
  .navbar-toggler {
    padding: 0;
    border: unset !important;
  }
  .navbar-toggler:focus {
    box-shadow: none;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .ss-link-main {
    padding: 0 !important;
  }
  .navbar-expand-lg .navbar-nav {
    column-gap: 15px;
  }
  .ss-header .offcanvas {
    width: unset;
  }
  .d-flex.align-items-center.navbar-nav {
    column-gap: 5px;
  }
  .ss-link.shedule-text {
    margin-right: 0;
  }
  .ss-link {
    font-size: 13px;
  }
  .ss-logo img {
    height: 35px;
  }
  .custom-carousel .item.active {
    width: 400px;
  }
  .custom-carousel .item-desc {
    transform: translateY(calc(100% - 46px));
    -webkit-transform: translateY(calc(100% - 46px));
  }
  .head_of_main_metting h2 {
    font-size: 73px !important;
    line-height: 65px !important;
  }
}
@media (min-width: 992px) and (max-width: 1399px) {
  .btn-wrapper-get,
  .btn-wrapper-schedule,
  .ss-link-main a,
  .ss-link-main-part a {
    font-size: 10px !important;
  }
  .btn-wrapper-schedule {
    padding: 0 9px !important;
  }
  .btn-wrapper-get {
    padding: 0 10px;
  }
  .head_of_main_metting h2 {
    font-size: 90px;
    line-height: 100px;
  }
}
@media (min-width: 320px) and (max-width: 480px) {
  .company_goal_metting_wrapper.main_goal .col-lg-6.col-md-6 {
    order: unset;
  }
  h1,
  h2 {
    font-size: 30px;
    line-height: 45px;
  }
  h3 {
    font-size: 25px;
    line-height: 35px;
  }
  h4 {
    font-size: 20px;
    line-height: 30px;
  }
  .footer-links a,
  h5,
  h6 {
    font-size: 14px;
    line-height: 25px;
  }
  .mt-sm-20 {
    margin-top: 20px;
  }
  .mb-sm-20 {
    margin-bottom: 20px;
  }
  .d-mobile-block,
  .d-sm-md-block {
    display: block !important;
    width: 100%;
  }
  .footer-custom.container-fluid {
    padding: 0 15px;
  }
  .social-link.d-flex {
    width: 100% !important;
    justify-content: center;
    border: 1px solid var(--color-secondary2);
  }
  .ss-footer-logo {
    padding: 15px;
  }
  .footer-location-border {
    border: none;
  }
  .my-element-to-clip {
    clip-path: unset;
    padding: 15px;
  }
  .ss-link.shedule-text {
    color: var(--color-secondary);
    padding: 15px;
  }
  .delivering-box.border {
    border: 1px solid var(--border-color) !important;
    position: relative;
  }
  .delivering-section .col-lg-4:first-child .delivering-box.border,
  .delivering-section .col-lg-4:nth-child(2) .delivering-box.border,
  .delivering-section .col-lg-4:nth-child(4) .delivering-box.border,
  .delivering-section .col-lg-4:nth-child(5) .delivering-box.border {
    border-right: 1px solid var(--border-color) !important;
    border-bottom: 0 !important;
  }
  .delivering-section .col-lg-4:nth-child(3) .delivering-box.border {
    border-bottom: 0 !important;
  }
  .main_class1 li h2 {
    font-size: 50px;
  }
  .blog-img-main,
  .blog-img-main::before,
  .ideation-services {
    height: 100%;
  }
  .our_mainbanar_of_part .custom-carousel .item.active,
  .portfolio-grid-img {
    height: auto !important;
  }
  .blog-text,
  .creativity-main:after {
    bottom: 0;
  }
  .ft-slick__dots--custom,
  .slick-dots .slick-active .ft-slick__dots--custom {
    width: auto;
  }
  .blog-slider::before,
  .countless-title-main .ss-heading h2 img,
  .creativity-content-main .slick-dots li::after,
  .creativity-content-main .slick-dots li::before,
  .footer-social,
  .hiring_dedication_section .tecnology_plus_slider .owl-nav,
  .look-forward .ss-portfolio-text h2 sup img,
  .portfolio-tab .nav-link.active::after,
  .services-in-action-main::after,
  .social_apply_icon span,
  .ss-vision-img::before,
  .technology-next-main::after,
  .technology-next-main::before,
  .website-development-img-main {
    display: none;
  }
  .creativy-video {
    height: 500px;
    transform: scale(10);
  }
  .ss-heading.proof-text h2 {
    font-size: 30px;
    line-height: 40px;
  }
  .bg-service::after,
  .experience-box,
  .ss-testimonial {
    width: 100%;
  }
  .owl-theme .owl-dots .owl-dot.active span,
  .owl-theme .owl-dots .owl-dot:hover span {
    background: var(--color-primary) !important;
  }
  .timeline ul li:nth-child(odd) {
    padding: 20px 0 20px 60px !important;
  }
  .timeline_box_main .timeline_box2 {
    top: -3%;
    left: -5%;
    height: 105%;
  }
  .ss-story span {
    font-size: 60px !important;
  }
  .ss-story span:nth-last-child(-n + 3) {
    padding-right: 0;
  }
  .website-development.bg-logo::after {
    left: 0;
    display: none;
  }
  .website-development-img img {
    width: 80%;
  }
  .web-build-box {
    padding: 15px;
    bottom: 20px;
    left: 0;
  }
  .web-build-box2 {
    width: 50%;
    top: 15px;
  }
  .web-build-box2 .line.mt-15 {
    margin-top: 10px;
  }
  .faq-main {
    border-radius: 0;
    padding-bottom: 350px !important;
  }
  .dream-project-box,
  .enhance-content-main {
    padding: 20px;
  }
  .dream-project-form,
  .service-portfolio-text.w-50 {
    width: 100% !important;
  }
  .ss-portfolio-box-main {
    padding: 20px 0 20px 20px;
  }
  .elevating-software {
    background-image: none;
    padding-top: 0 !important;
  }
  .bg-service::before {
    width: 100%;
    background-size: 80%;
    z-index: -9;
  }
  .enhance-content-main.ptb-200 {
    padding: 50px 0 !important;
  }
  .services-in-action-main::before {
    background-position: right top 0;
    background-size: 50%;
  }
  .technology-box {
    border: unset;
  }
  .cutting-img .ss-story span {
    font-size: unset !important;
  }
  .portfolio-grid {
    height: 100%;
    padding: 25px;
  }
  .journey-content {
    padding: 25px 15px;
  }
  .faq-accordion .accordion-body {
    padding-right: 10px;
  }
  .faq-accordion .accordion-button {
    padding-right: 20px !important;
    font-size: 14px;
  }
  .portfolio-content .portfolio-text h6,
  .sticky-span {
    font-size: 12px;
  }
  .our_mainbanar_of_part .custom-carousel .item {
    height: 50px !important;
  }
  .main_panal_component {
    width: fit-content;
  }
  .process-mobile::after {
    top: 24px !important;
    height: 83% !important;
  }
  .footer-social-mobile.footer-social,
  .website-development-img-mb {
    display: block;
  }
  .main-challenges-img {
    height: auto;
  }
  .learningbox-main {
    padding: 25px;
  }
  .build-box-content {
    padding-bottom: 30px;
  }
  .ss-case-study .nav-pills .nav-link {
    margin-bottom: 10px;
    padding: 8px 18px;
    border-radius: 50px;
    margin-right: 7px;
  }
  .sticky-span {
    left: 11%;
    padding: 5px 16px;
    top: 20%;
    transform: translate(-52%, 5%) !important;
  }
  .title-testimonials h2 {
    font-size: 40px;
  }
  .owl-theme .owl-dots .owl-dot:hover span,
  .testimonials-page-slider .owl-theme .owl-dots .owl-dot.active span {
    background: #fff !important;
  }
  .title-testimonials.pt-100 {
    padding-top: 20px !important;
  }
  .testimonials-page-slider .owl-theme .owl-dots {
    margin-top: 10px !important;
  }
  .plyr__control--overlaid {
    padding: 10px !important;
  }
  .award-text {
    font-size: 13px;
  }
  .our_talent_and_dream .container {
    background-image: unset;
  }
  .ss-process-tab .nav-pills .nav-link {
    padding: 10px 12px;
  }
  .we-are-text-main .bg-hover span img {
    width: 30px;
  }
  .learningbox-main.learningbox1 {
    background-image: url(../images/culture/learning-box-bg2.png);
    background-position: left;
  }
  .one-team-main .slick-slide > div {
    margin: 0 5px;
  }
  .portfolio-tab .nav {
    flex-wrap: unset;
  }
  .portfolio-tab .flex-column {
    flex-direction: row !important;
  }
  .portfolio-tab .nav-link.active::before {
    position: absolute;
    content: "";
    height: 1px;
    width: 100%;
    background-color: var(--color-primary-yellow);
    bottom: 0;
    opacity: 1;
    left: 0;
  }
  .portfolio-tab .nav-link {
    font-size: 12px;
    line-height: 22px;
    text-align: center;
    padding: 0 0 5px !important;
    border-radius: unset;
  }
  .portfolio-tab .nav-item {
    padding: 0 10px;
  }
  .ss-process-tab .nav-pills .nav-link span {
    padding-left: 5px;
  }
  .main-challenges .container .col-lg-6:first-child {
    order: 2 !important;
  }
  .portfolio-content .right-img {
    margin-right: 8px;
  }
  .phn-img-main {
    padding: 0;
  }
  .copyright-text {
    padding: 15px 6px;
  }
  .social-link.d-flex {
    border-top: unset;
  }
  .footer-part2.pt-50 {
    padding-top: 0;
  }
  .company-event-tab {
    padding-top: 0 !important;
  }
  .newsletter-imgtext {
    padding: 10px;
  }
  .flip_card_about_goal {
    height: auto;
    position: unset;
  }
  .core-value-box.d-flex {
    display: block !important;
  }
  .core-value-text {
    margin-left: 0;
  }
  .meeting_land_why {
    min-height: auto;
    position: unset;
  }
  .head_of_main_metting.head_of_main_metting_yellow h2 {
    color: #fff;
  }
}
@media (min-width: 481px) and (max-width: 768px) {
  .d-sm-md-block {
    display: block !important;
    width: 100%;
  }
  .copyright-text,
  .ss-footer-logo {
    padding: 15px;
  }
  .footer-location-border {
    border: none;
  }
  .my-element-to-clip {
    clip-path: unset;
    padding: 30px;
  }
  .social-link.d-flex {
    width: 100% !important;
    justify-content: center;
    border: 1px solid var(--color-secondary2);
  }
  .ss-link.shedule-text {
    color: var(--color-secondary);
    padding: 15px;
  }
  .delivering-section .col-lg-4:nth-child(2) .delivering-box.border,
  .delivering-section .col-lg-4:nth-child(4) .delivering-box.border {
    border-right: 1px solid var(--border-color) !important;
    border-bottom: 0 !important;
  }
  .delivering-section .col-lg-4:nth-child(3) .delivering-box.border {
    border-right: 0px !important;
    border-bottom: 0 !important;
  }
  .main_class1 li h2 {
    font-size: 120px;
  }
  .blog-img-main,
  .blog-img-main::before,
  .portfolio-grid img {
    height: 100%;
  }
  .blog-text {
    bottom: 0;
  }
  .ss-heading.proof-text h2 {
    font-size: 30px;
    line-height: 40px;
  }
  .ft-slick__dots--custom,
  .slick-dots .slick-active .ft-slick__dots--custom {
    width: auto;
  }
  .experince-text {
    right: 250px;
  }
  .ss-story span {
    font-size: 60px !important;
  }
  .faq-main {
    border-radius: 0 0 40px 40px;
  }
  .dream-project-box {
    padding: 25px;
  }
  .dream-project-border {
    margin: 0 25px;
  }
  .service-portfolio-slider .owl-carousel .owl-nav {
    bottom: -20px;
    display: none;
  }
  .mt-md-15,
  .service-portfolio-slider .owl-theme .owl-nav.disabled + .owl-dots {
    margin-top: 15px;
  }
  .elevating-software {
    background-size: 60%;
    padding-top: 0 !important;
  }
  .bg-service::after,
  .bg-service::before {
    width: 100%;
  }
  .bg-service::before {
    width: 100%;
    background-size: 50%;
  }
  .services-in-action-main::before {
    background-position: right top 0;
    background-size: 50%;
  }
  .mt-md-20 {
    margin-top: 20px;
  }
  .creativity-content.mb-80,
  .mb-md-20 {
    margin-bottom: 0 !important;
  }
  .experience-box {
    width: 25%;
  }
  .experience-box:last-of-type {
    flex: unset !important;
  }
  .cutting-img .ss-story span {
    font-size: unset !important;
  }
  .faq-accordion .accordion::after {
    left: -20px;
  }
  .faq-accordion .accordion-button:not(.collapsed)::before {
    left: -19px;
  }
  .title-testimonials h2 {
    font-size: 70px;
  }
  .technology-next-main::after,
  .technology-next-main::before {
    background-size: 60%;
  }
  .footer-social-mobile.footer-social {
    display: block;
  }
  .footer-social {
    display: none;
  }
  .footer-social-mobile.footer-social .phn-img-main {
    padding: 20px 0;
  }
  .copyright-text h5 {
    font-size: 16px;
  }
  .footer-links-main .footer-contact.mb-30:nth-last-child() {
    margin-bottom: 0;
  }
  .flip_card_about_goal {
    height: auto;
    position: unset;
  }
  .website-development.bg-logo::after {
    left: 0;
    display: none;
  }
  .core-value-box.d-flex {
    display: block !important;
  }
  .core-value-text {
    margin-left: 0;
  }
  .creativity-content-main .slick-dots {
    margin-bottom: 35px;
  }
}
@media (min-width: 320px) and (max-width: 991px) {
  .cookie-consent-banner-main,
  .cookies-button,
  .cookies-buttons-main {
    display: inline-block !important;
    width: 100%;
  }
  .cookie-consent-banner {
    padding-top: 10px !important;
  }
  .cookie-consent-banner-main .modalbtn {
    width: auto;
    margin-right: 8px;
  }
  .cookies-button button {
    margin-right: 5px;
  }
  .modal-content .cookie-modal-main {
    padding: 0 15px 15px;
  }
  .cookies-buttons-main {
    padding-top: 0 !important;
  }
  .cookie-modal h2 {
    font-size: 22px;
    font-weight: 700;
  }
  .modal-header {
    padding-bottom: 0;
  }
  .cookie-info h3 {
    font-size: 18px;
  }
  .cookie-info p {
    margin-bottom: 10px;
  }
  .services-in-action.ptb-200 {
    padding: 50px 0 0;
  }
  .enhance-content-main.ptb-200 {
    padding: 50px 0;
  }
  .btns_of_comman.navbar-nav .ss-link-main {
    padding-left: 25px !important;
  }
  .meeting-img-mobile {
    display: block;
    text-align: center;
  }
  .cmp-event-img1,
  .services-in-action-main::after {
    display: none;
  }
  .ai-service-list .services-in-action-main:after {
    right: 0;
  }
  .ideation-services {
    height: 100% !important;
  }
  .portfolio-grid-img {
    height: auto !important;
  }
}
@media (min-width: 769px) and (max-width: 991px) {
  .title-testimonials h2 {
    font-size: 90px;
  }
  .copyright-text h5,
  .footer-text h6 {
    color: var(--color-flink);
    font-size: 14px;
    line-height: 24px;
  }
  .footer-copyright .phn-img-main {
    display: flex;
    align-items: center;
  }
  .footer-part2.pt-50.pb-60 {
    padding-top: 25px;
    padding-bottom: 25px;
  }
  .phn-img-main {
    padding: 15px;
  }
  .search-box-main {
    margin-top: 20px;
  }
  .core-value-box.d-flex {
    display: block !important;
  }
  .flip_card_about_goal {
    height: auto;
    position: unset;
  }
  .portfolio-grid-img {
    height: auto;
  }
  .creativity-content-main .slick-dots {
    margin-bottom: 35px;
  }
}
@media (min-width: 1025) and (max-width: 1440) {
  .ft-slick__dots--custom,
  .slick-dots .slick-active .ft-slick__dots--custom {
    width: auto;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .cookie-consent-banner-main {
    padding-right: 25% !important;
    padding: 0;
  }
  .process-round {
    height: 45px !important;
    width: 45px !important;
  }
  .process-round p {
    line-height: 45px !important;
  }
  .certification-title h5,
  .process-name h5 {
    font-size: 14px;
  }
  .process-7 {
    bottom: -18px !important;
  }
  .our_mainbanar_of_part .custom-carousel .item:first-child {
    row-gap: 60px;
    justify-content: flex-start;
  }
  .certification-title {
    padding: 5px;
  }
}
@media (min-width: 992px) and (max-width: 1024px) {
  .title-testimonials h2 {
    font-size: 90px;
  }
  .enpowerment-box {
    left: 50%;
  }
  .ft-slick__dots--custom,
  .slick-dots .slick-active .ft-slick__dots--custom {
    width: auto;
  }
  .website-development-img img {
    width: 80%;
  }
  .web-build-box {
    padding: 15px;
    bottom: 20px;
    left: 0;
  }
  .faq-main {
    border-radius: 0 0 40px 40px;
  }
  .dream-project-box {
    padding: 30px;
  }
  .elevating-software {
    background-size: 60%;
    padding-top: 0 !important;
  }
  .experience-box {
    width: 25%;
  }
  .ss-link-main {
    padding: 0 5px;
  }
  .main_class1 li h2 {
    font-size: 120px;
  }
}
@media (min-width: 1025px) and (max-width: 1199px) {
  .main_class1 li h2 {
    font-size: 160px;
  }
  .main_casestudy_after .portfolio-box-main .d-flex.flex-column {
    row-gap: 0;
  }
}
@media (min-width: 991px) and (max-width: 1199px) {
  .main_casestudy_after .portfolio-box-main .d-flex.flex-column {
    row-gap: 0;
  }
}
@media (min-width: 320px) and (max-width: 768px) {
  .cookie-consent-banner-main {
    padding-right: 0;
  }
  .contact-line2,
  .creativity-content-main .slick-dots li::before,
  .main_casestudy_after .col-lg-4:nth-child(3) .portfolio-box-main:after,
  .requiremnt-img {
    display: none;
  }
  .functionality-images {
    padding-top: 25px;
    height: auto;
  }
  .portfolio-grid-img {
    height: auto !important;
  }
  .tap-to-scan::after {
    width: 100%;
  }
  .service-portfolio-img img {
    object-fit: contain;
    margin: 0 auto;
  }
  .learning-text.pt-100 {
    padding-top: 40px;
  }
  .process-mobile {
    display: inline-block !important;
  }
  .our_mainbanar_of_part .custom-carousel .item {
    padding: 10px !important;
  }
  .contact-us-form-box,
  .workshops-point-box {
    padding: 15px;
  }
  .certification-title {
    padding: 5px;
  }
  .certification-title h5,
  .tecnology_hirededicate p,
  .workshops-point-box h5 {
    font-size: 14px;
  }
  .attractive-text h3,
  .workshops-title h3 {
    font-size: 24px;
    line-height: 35px;
  }
  .build-section,
  .certification .ss-case-study.pb-50 {
    padding-bottom: 0 !important;
  }
  .workshops .card__content {
    padding: 20px;
  }
  .workshops-title h3 {
    padding-bottom: 15px;
  }
  .certification .ss-case-study .nav-pills {
    padding-top: 20px !important;
  }
  .workshops-main .pt-50.row {
    padding-top: 0 !important;
  }
  .workshops .card {
    top: 100px;
  }
  .buildtogether-slider .owl-theme .owl-dots {
    margin: 40px 0;
  }
  .buildtogether-slider .owl-theme .owl-dots .owl-dot.active span,
  .owl-theme .owl-dots .owl-dot:hover span {
    background: #001b50 !important;
  }
  .faq-main {
    min-height: 100%;
  }
  .service-portfolio-slider .owl-carousel.owl-theme.pb-80,
  .services-in-action.pb-200 {
    padding-bottom: 0;
  }
  .testimonials-box-s1 {
    padding-bottom: 20px;
  }
  .team-member-hire .team-member-img {
    height: 300px;
  }
  .our-team-main.pt-80 {
    padding-top: 0;
  }
  .ss_benififts_section .col-lg-4:first-child {
    background: linear-gradient(
      180deg,
      rgba(0, 58, 220, 0) 0,
      rgba(0, 58, 220, 0.1) 75.47%
    );
  }
  .ss-process-tab .nav-link {
    font-size: 14px;
    padding-left: 5px;
    line-height: 22px;
  }
  .ceo-text h4,
  .certification-title h3,
  .contact-text h3,
  .copyright-text p,
  .declaration-text ul li p,
  .enhance-content p,
  .footer-text p,
  .inquires_of_pricing h3,
  .location-text p,
  .maintanance-hover h4 .our_cluture_video_head h2,
  .maintance-text h3,
  .our-facility-inner-div p,
  .our-hiring-text-dese_s1 h3,
  .pakages_of_price h3,
  .pakages_of_price h4,
  .portfolio-content h2,
  .pricing-availability-text p,
  .privacy-policy-main-text p,
  .quality-box h3,
  .quality-title p,
  .service-portfolio-text h3,
  .story-box h2,
  .team-images-main .our-vision-text h2,
  .testimonials-section .light-heading,
  .text_tecnology_hd h2,
  .workshops-point-box h4 {
    font-size: 14px;
    line-height: 24px;
  }
  .core-value-text h2,
  .enhance-content h3,
  .form-title h3,
  .tecnology_hirededicate h3 {
    font-size: 20px;
  }
  .services-in-action .ss-heading h2 {
    font-size: 25px;
    line-height: 35px;
  }
  .desciption_of_compnaygoal h2 {
    font-size: 25px;
  }
}
@media (min-width: 1024px) and (max-width: 1440px) {
  .ss-portfolio-box {
    display: block;
    width: 100%;
  }
  .service-portfolio-text {
    width: 100% !important;
  }
  .service-portfolio-img img {
    margin: 0 auto;
  }
  .title-testimonials h2 {
    font-size: 150px;
  }
}
@media (min-width: 300px) and (max-width: 767px) {
  .our_culture_memories li:hover img {
    filter: unset !important;
    scale: unset !important;
  }
}
@media (min-width: 300px) and (max-width: 991px) {
  .portfolio-grid-img {
    height: auto !important;
  }
  .portfolio-grid-img img {
    height: auto !important;
    width: 100%;
    object-fit: unset !important;
  }
  .creativity-content-main .slick-dots li::after {
    font-size: 12px;
    font-weight: 400;
    line-height: 15px;
  }
  .creativy-video {
    height: 100% !important;
    transform: unset !important;
  }
  .creativity-content h2,
  .creativity-main::after {
    display: none;
  }
  .creativity-content-main {
    position: relative;
    bottom: unset;
    padding-top: 15px;
  }
  .ss-heading.proof-text h2 {
    font-size: 18px;
    line-height: 25px;
  }
  .creativity-content-main .slick-dots {
    position: relative;
    bottom: 0;
    margin-top: 15px;
  }
  .creativity-main {
    background-color: var(--color-primary);
    padding-bottom: 20px;
  }
  .creativity-content.mb-80 {
    margin-bottom: 0;
  }
  .creativity-content h6 {
    display: block;
    color: var(--color-white);
    font-weight: 300;
  }
  .creativity-content h6 span {
    font-weight: 400;
  }
}
